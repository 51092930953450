import React from 'react';
import './progress_styles.css';
import {connect} from "react-redux";
import {  CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class KWPreload extends React.Component {
    constructor(props) {
        super(props);

	this.state = {
		num_files: (this.props.data.length * 2),
		count: 0
	}
    }


   updateCount(){
	console.log("UPDATE COUNT")

	this.setState(prevState => {
		console.log(prevState.count)
		console.log(prevState.num_files)
		if (prevState.count == prevState.num_files - 1){
			console.log("Done");
		}	

		return ({
			count: prevState.count + 1
		})
	
	})
   }

    componentDidMount(){
	console.log("componentDidMount")
	const images = this.props.images
	const audio = this.props.audio
	const audio_path = "/api/get.php?file=media/trees/audio/"
	const image_path = "/api/get.php?file=media/trees/images/"
	
	let data = this.props.data

	for (let i = 0; i < data.length; i++){

		if (data[i].image){
			fetch("/api/get.php?file=" + data[i].image)
			.then(resp => resp.blob())
			.then(blob => {
				let url = URL.createObjectURL(blob);
				let img = new Image();
				//img.onload = e => console.log('async', img.naturalWidth, img.complete);
				img.onload = e => this.updateCount();
				img.src = url;
			})

		}
		else {
			this.updateCount();
		}

		if (data[i].audio){
			fetch("/api/get.php?file=" + data[i].audio)
			.then(resp => resp.blob())
			.then(blob => {
				let url = URL.createObjectURL(blob);
				let audio = new Audio();
				audio.addEventListener('canplaythrough', this.updateCount(), false);
				audio.src = url;
			})
					
		}
		else {
			this.updateCount();
		}

	}
    }


/*
	for (let i = 0; i < images.length; i++){
		fetch(image_path + images[i])
		.then(resp => resp.blob())
		.then(blob => {
			let url = URL.createObjectURL(blob);
			let img = new Image();
			//img.onload = e => console.log('async', img.naturalWidth, img.complete);
			img.onload = e => this.updateCount();
			img.src = url;
		})
				
	}

	for (let i = 0; i < audio.length; i++){
		fetch(audio_path + audio[i])
		.then(resp => resp.blob())
		.then(blob => {
			let url = URL.createObjectURL(blob);
			let audio = new Audio();
			//img.onload = e => console.log('async', img.naturalWidth, img.complete);
			audio.addEventListener('canplaythrough', this.updateCount(), false);
			audio.src = url;
		})
				
	}
    }
*/

//<CircularProgressbar value={count} maxValue={num_files} text={`${ Math.ceil( count/num_files*100 )}%`} styles={buildStyles({pathColor: `rgba(255, 255, 120, ${percentage / 100})`, textColor: '#f88'})} />
//<CircularProgressbar value={count} maxValue={num_files} text={`${ Math.ceil( count/num_files*100 )}%`} styles={buildStyles({pathColor: '#f88', textColor: '#f88'})} />
  
  render() {
	let count = this.state.count;
	let num_files = this.state.num_files;

	if (count < num_files){
		let percentage = Math.ceil(count / num_files * 100)

		return(
			<div className="circularProgressbarWrapper">
			     <div className="circularProgressbardiv">
			       <p><b> Loading activity </b> </p>
				  <CircularProgressbar value={count} maxValue={num_files} text={`${ Math.ceil( count/num_files*100 )}%`} />
			    </div>
			</div>
		);
    	} else {
		return this.props.children
	}
    }
}
/*
const CATEGORY = "loading";
const mapStateToProps = (state, ownProps) => {
  let data = {};
  switch (ownProps.category) {
    case "cha":
      data = state.cha.scenesData[CATEGORY];
      break;
    default:
      data = state.ch[CATEGORY];
  }
  console.log("DATA DDD")
  console.log(state)
  return { data };
};
export default connect(mapStateToProps)(ProgressBar);
*/


export default KWPreload;
