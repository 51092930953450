import "babel-polyfill";
import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/common/HomePage";
import HomeActivities from "./components/common/HomeActivities";
import HomeAudio from "./components/common/HomeAudio";
import About from "./components/common/AboutPage";
import Song1 from "./components/common/Song1";
import Song2 from "./components/common/Song2";
import Song3 from "./components/common/Song3";
import Song4 from "./components/common/Song4";
import Song5 from "./components/common/Song5";
import Song6 from "./components/common/Song6";
import Song7 from "./components/common/Song7";
import Song8 from "./components/common/Song8";
import Song9 from "./components/common/Song9";
import Song10 from "./components/common/Song10";
import Song11 from "./components/common/Song11";
import Song12 from "./components/common/Song12";
import Song13 from "./components/common/Song13";
import Song14 from "./components/common/Song14";
import Song15 from "./components/common/Song15";
import Song16 from "./components/common/Song16";
import Song17 from "./components/common/Song17";
import ClickAndHear from "./components/activities/click_and_hear/ClickAndHearPage";
import AnimatedScene from "./components/activities/animated_scene/AnimatedScenePage";
import NewPage from "./components/activities/test_click/AnimatedScenePage";
import HouseholdItems from "./components/householdItems/HouseholdItems";
import ClassroomObjects from "./components/classroomObjects/ClassroomObjects";
import NorthernSaskatchewanPlaceNames from "./components/northernSaskatchewanPlaceNames/NorthernSaskatchewanPlaceNames";
import Bones from "./components/bones/Bones";
import KinshipTerms from "./components/kinshipTerms/KinshipTerms";
import SixSeasons from "./components/sixSeasons/SixSeasons";
import Trees from "./components/trees/Trees";
import YearlyCalendar from "./components/yearlyCalendar/YearlyCalendar";
import SyllabicMatchingGameType1 from "./components/syllabicMatchingGame/Type1/SyllabicMatchingGameType1";
import SyllabicMatchingGameType2 from "./components/syllabicMatchingGame/Type2/SyllabicMatchingGameType2";
import SyllabicChart from "./components/syllabicChart/SyllabicChart";
import Numbers from "./components/numbers/Numbers";
import CataloguePage from "./components/common/CataloguePage";
import Contact from "./components/contact/Contact";
import Knav from "./components/menu";
import configureStore from "./store/configureStore";

const store = configureStore();

render(
  <Provider store={store}>
    <Router>
      <div>
        <Knav />
        <Route exact path="/" component={Home} />
        <Route exact path="/activities" component={HomeActivities} />
        <Route exact path="/audio" component={HomeAudio} />
        <Route path="/song1" component={Song1} />
        <Route path="/song2" component={Song2} />
        <Route path="/song3" component={Song3} />
        <Route path="/song4" component={Song4} />
        <Route path="/song5" component={Song5} />
        <Route path="/song6" component={Song6} />
        <Route path="/song7" component={Song7} />
        <Route path="/song8" component={Song8} />
        <Route path="/song9" component={Song9} />
        <Route path="/song10" component={Song10} />
        <Route path="/song11" component={Song11} />
        <Route path="/song12" component={Song12} />
        <Route path="/song13" component={Song13} />
        <Route path="/song14" component={Song14} />
        <Route path="/song15" component={Song15} />
        <Route path="/song16" component={Song16} />
        <Route path="/song17" component={Song17} />
        <Route path="/about" component={About} />
        <Route path="/ch/:val" render={props => <ClickAndHear {...props} />} />
        <Route path="/cha/:val" render={props => <AnimatedScene {...props} />} />
        <Route path="/hs/:val" render={props => <NewPage {...props} />} />
        <Route path="/classroom-objects" render={props => <ClassroomObjects {...props} />} />
        <Route path="/household-items" render={props => <HouseholdItems {...props} />} />
        <Route path="/northern-saskatchewan-place-names" render={props => <NorthernSaskatchewanPlaceNames {...props} />} />
        <Route path="/bones" render={props => <Bones {...props} />} />
        <Route path="/kinship-terms" render={props => <KinshipTerms {...props} />} />
        <Route path="/six-seasons" render={props => <SixSeasons {...props} />} />
        <Route path="/trees" render={props => <Trees {...props} />} />
        <Route path="/yearly-calendar" render={props => <YearlyCalendar {...props} />} />
        <Route path="/syllabic-matching-game/type1/:val" render={props => <SyllabicMatchingGameType1 {...props} />} />
        <Route path="/syllabic-matching-game/type2/:val" render={props => <SyllabicMatchingGameType2 {...props} />} />
        <Route path="/syllabic-chart" render={props => <SyllabicChart {...props} />} />
        <Route path="/numbers" render={props => <Numbers {...props} />} />
        <Route path="/catalogue" render={props => <CataloguePage {...props} />} />
        <Route path="/contact" render={props => <Contact {...props} />} />
      </div>
    </Router>
  </Provider>,
  document.getElementById("app")
);
