// For handling array of bird

import * as actionTypes from '../actions/actionTypes'

export const birdsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_BIRDS_SUCCESS:
          return action.bird;
    default:
          return state;
  }
};

