import React from 'react';
import './HouseholdItems.css';
import BaseCmpWithHover from '../common/baseCmpWithHover/BaseCmpWithHover';
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
import ProgressBar from "../ProgressBar/ProgressBar";
import NewProgressBar from "../ProgressBar/newProgressBar";

class HouseholdItems extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      openedRoomId: '',
      loaded: false,
    };

    this.openRoom = this.openRoom.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
  }

  openRoom (roomId) {

    if (roomId === 'c-rooms-scene') {
      this.playAudio('/api/get.php?file=media/other/household_items/audio/2tawaw pihtoki.mp3');
    }

    const closeRoomId = this.state.openedRoomId || 'c-main-scene';
    const closeRoomEl = document.getElementById(closeRoomId);
    closeRoomEl.classList.add('c-hidden');

    roomId = roomId || 'c-rooms-scene';
    this.setState({
      openedRoomId: roomId
    });

    const openRoomId = roomId;
    const openRoomEl = document.getElementById(openRoomId);
    openRoomEl.classList.remove('c-hidden');
  }

  playAudio (path) {
    let audio = new Audio(path);
    audio.play();
  }

  handleImageLoaded() {
    this.setState({ loaded: true });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.data) {
      nextProps.fetchData();
    }

    if (nextProps.data !== this.props.data) {
      console.log('NOT EQUAL SO UPDATING');


    }
  }

  componentDidMount() {
    if (!this.props.data) {
      this.props.fetchData();
    }


  }

  render () {
    const data = this.props.data;
    if (data) {
    const playAudio = () => {
      if (props.audio) {
        (new Audio(props.audio)).play();
      }
    };

    const rooms = [
        {
          'id': 'c-bedroom-1',
          'roomListId': 'to-bedroom-1',
          'text': 'Bedroom 1',
        }, {
          'id': 'c-bathroom',
          'roomListId': 'to-bathroom',
          'text': 'Bathroom',
        }, {
          'id': 'c-bedroom-2',
          'roomListId': 'to-bedroom-2',
          'text': 'Bedroom 2',
        }, {
          'id': 'c-living-room',
          'roomListId': 'to-living-room',
          'text': 'Living Room',
        }, {
          'id': 'c-kitchen',
          'roomListId': 'to-kitchen',
          'text': 'Kitchen',
        }, {
          'id': 'c-laundry-room',
          'roomListId': 'to-laundry-room',
          'text': 'Laundry Room',
        },
    ];

    const showTranslation = () => {
      document.querySelectorAll('div[name="utencils"]').forEach(el => el.classList.add('utencils'));
    };

    const hideTranslation = () => {
      document.querySelectorAll('div[name="utencils"]').forEach(el => el.classList.remove('utencils'));
    };

    const showHint = (e) =>{
      const tooltips = document.querySelectorAll('.c-tooltip-container .c-tooltip');
      const x = (e.clientX + 20) + 'px';
      const y = (e.clientY + 20) + 'px';

      for (let i = 0, len = tooltips.length; i < len; i++) {
        tooltips[i].style.left = x;
        tooltips[i].style.top = y;
      }
    };

    const hintText = ( <span className='c-tooltip'>click to view<br/> up close</span> );

    const btns = rooms.map(room =>
        <button key={ room.id } onClick={ () => (this.openRoom(room.id)) } className='btn btn-primary'>{ room.text }</button>
      );

    const roomList = rooms.map( room =>
        <div key={ room.id } id={ room.roomListId } className='c-static-container'>
          <div className='c-tooltip-owner' onClick={ () => (this.openRoom(room.id)) }>
            <h3>{ room.text }</h3>
          </div>
        </div>
      );

    const translationBlock = (
        <div className='c-translation'>
          <div name='text'>English</div>
          <div>
            <div name='sro'>Standard Roman Orthography</div>
            <div name='syllabic'>Syllabics</div>
          </div>
        </div>
      );

    const backBtn = (
        <div id='c-back-to-rooms' onClick={ () => (this.openRoom('')) }>
          <div></div>
          <span className='c-tooltip'>Back</span>
        </div>
      );

    return (
      <div>
        <div>
          <div className='main-outer-wrapper'>
            <div className='main-inner-wrapper'>
              <h3 className='main-title-container'>Household Items</h3>

              <div className='с-household-wrapper'>

                {/* Main scene */}
                <div id='c-main-scene'>
                  <div className='c-outer-wrapper'>
                    <div className='loading' style={ this.state.loaded ? { display: 'none' } : { display: 'block' } }>Loading...</div>
                    <div className='c-inner-wrapper' style={ this.state.loaded ? {} : { display: 'none' } }>
                      <img
                        className='c-bg'
                        style={ this.state.loaded ? {} : { display: 'none' } }
                        src='api/get.php?file=media/other/household_items/images/house.png'
                        alt=''
                        onLoad={ (e) => this.handleImageLoaded(e) }
                      />

                      <div id='c-house-front' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <div id='c-house-title' className='c-static-container'>
                        <h1 className='c-tooltip-owner'>Household Items</h1>
                      </div>

                      <div id='signboard' className='c-tooltip-container'>
                        <div className='c-tooltip-owner'>
                          <div className='arc'></div>
                          <div className='text' onClick={() => (this.openRoom('c-rooms-scene'))}>Click to&nbsp;start</div>
                        </div>
                      </div>

                      <div id='window-lt' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <div id='window-rt' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <div id='window-lb' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <div id='window-rb' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>


                      <div id='door' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <div id='doorPanelLt' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <div id='doorPanelRt' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <div id='doorPanelLb' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <div id='doorPanelRb' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <div id='door-knob' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                    </div>
                  </div>
                </div>

                {/* Rooms */}
                <div id='c-rooms-scene' className='c-hidden'>
                  <div className='c-outer-wrapper'>
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/household_items/images/house.png' alt=''/>
                      {roomList}
                    </div>
                  </div>
                  <div className='c-btn-container'>
                    {btns}
                  </div>
                </div>

                {/* bedroom 1 */}
                <div id='c-bedroom-1' className='c-hidden'>
                  {translationBlock}
                  <div className='c-outer-wrapper'>
                    {backBtn}
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/household_items/images/bedroom1/bg.png'
                           alt=''/>

                      <BaseCmpWithHover
                        identifier='c-bedroom-1-light-bulb'
                        isTranslateForRooms={true}
                        hintText=''
                        text='Light bulb'
                        sro='wāsiskotīnikani-wīthkwās'
                        syllabic='ᐚᓯᐢᑯᑌᓂᑲᓂ ᐍᙿᒁᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_light_bulb.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-1-window'
                        isTranslateForRooms={true}
                        hintText=''
                        text='window'
                        sro='wāsīnamān'
                        syllabic='ᐚᓭᓇᒫᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_window.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-1-picture'
                        isTranslateForRooms={true}
                        hintText=''
                        text='picture (drawing)'
                        sro='tāpasinahikīwin'
                        syllabic='ᑖᐸᓯᓇᐦᐃᑫᐏᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_picture_drawing.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-1-crib'
                        isTranslateForRooms={true}
                        hintText=''
                        text='crib'
                        sro='awāsisinipīwinis'
                        syllabic='ᐊᐚᓯᓯᓂᐯᐏᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_crib.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-1-bed'
                        isTranslateForRooms={true}
                        hintText=''
                        text='bed'
                        sro='nipīwin'
                        syllabic='ᓂᐯᐏᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_bed.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-1-mobile-phone'
                        isTranslateForRooms={true}
                        hintText=''
                        text='mobile (cell) phone'
                        sro='ayamākanis'
                        syllabic='ᐊᔭᒫᑲᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_mobile_phone.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-1-mirror'
                        isTranslateForRooms={true}
                        hintText=''
                        text='mirror'
                        sro='wāpimon'
                        syllabic='ᐚᐱᒧᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_mirror.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-1-dresser'
                        isTranslateForRooms={true}
                        hintText=''
                        text='dresser'
                        sro='sākipicikanakocikan'
                        syllabic='ᓵᑭᐱᒋᑲᓇᑯᒋᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_dresser.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-1-radio'
                        isTranslateForRooms={true}
                        hintText=''
                        text='radio'
                        sro='kicohcikanis/pihcākosiwinis'
                        syllabic='ᑭᒍᐦᒋᑲᓂᐢ/ᐱᐦᒑᑯᓯᐏᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_radio_combo.mp3'
                      />

                    </div>
                  </div>
                  <div className='c-btn-container'>
                    {btns}
                  </div>
                </div>

                {/* bathroom */}
                <div id='c-bathroom' className='c-hidden'>
                  {translationBlock}
                  <div className='c-outer-wrapper'>
                    {backBtn}
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/household_items/images/bathroom/bg.png'
                           alt=''/>

                      <BaseCmpWithHover
                        identifier='c-bathroom-light-bulb'
                        isTranslateForRooms={true}
                        hintText=''
                        text='Light bulb'
                        sro='wāsiskotīnikani-wīthkwās'
                        syllabic='ᐚᓯᐢᑯᑌᓂᑲᓂ ᐍᙿᒁᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_light_bulb.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bathroom-mirror'
                        isTranslateForRooms={true}
                        hintText=''
                        text='mirror'
                        sro='wāpimon'
                        syllabic='ᐚᐱᒧᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_mirror.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bathroom-washtube'
                        isTranslateForRooms={true}
                        hintText=''
                        text='washtube'
                        sro='sīpīkinikīwi-māhkāhk'
                        syllabic='ᓭᐯᑭᓂᑫᐏ-ᒫᐦᑳᕽ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_washtub.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bathroom-toilet'
                        isTranslateForRooms={true}
                        hintText=''
                        text='toilet'
                        sro='wathawīstamāsowithākan'
                        syllabic='ᐘᖬᐍᐢᑕᒪᓱᐏᖭᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_toilet.mp3'
                      />

                      <div id='c-bathroom-washbowl-basin' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <BaseCmpWithHover
                        identifier='c-bathroom-washbowl-basin-2'
                        isTranslateForRooms={true}
                        hintText=''
                        text='washbowl basin'
                        sro='kāsihkwākanithākan'
                        syllabic='ᑳᓯᐦᒁᑲᓂᖭᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_wash-bowl_basin.mp3'
                      />

                    </div>
                  </div>
                  <div className='c-btn-container'>
                    {btns}
                  </div>
                </div>

                {/* bedroom 2 */}
                <div id='c-bedroom-2' className='c-hidden'>
                  {translationBlock}
                  <div className='c-outer-wrapper'>
                    {backBtn}
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/household_items/images/bedroom2/bg.png'
                           alt=''/>

                      <BaseCmpWithHover
                        identifier='c-bedroom-2-light-bulb'
                        isTranslateForRooms={true}
                        hintText=''
                        text='Light bulb'
                        sro='wāsiskotīnikani-wīthkwās'
                        syllabic='ᐚᓯᐢᑯᑌᓂᑲᓂ ᐍᙿᒁᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_light_bulb.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-2-window'
                        isTranslateForRooms={true}
                        hintText=''
                        text='window'
                        sro='wāsīnamān'
                        syllabic='ᐚᓭᓇᒫᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_window.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-2-wall-picture'
                        isTranslateForRooms={true}
                        hintText=''
                        text='wall picture'
                        sro='masinipīsinowin'
                        syllabic='ᒪᓯᓂᐯᓯᓄᐏᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_picture_photo.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-2-bed'
                        isTranslateForRooms={true}
                        hintText=''
                        text='small bed'
                        sro='apiscinipīwinis'
                        syllabic='ᐊᐱᐢᒋᓂᐯᐏᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_bed_small.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-2-telephone'
                        isTranslateForRooms={true}
                        hintText=''
                        text='telephone'
                        sro='ayamākan'
                        syllabic='ᐊᔭᒫᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_telephone.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-2-mirror'
                        isTranslateForRooms={true}
                        hintText=''
                        text='mirror'
                        sro='wāpimon'
                        syllabic='ᐚᐱᒧᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_mirror.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-2-dresser'
                        isTranslateForRooms={true}
                        hintText=''
                        text='dresser'
                        sro='sākipicikanakocikan'
                        syllabic='ᓵᑭᐱᒋᑲᓇᑯᒋᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_dresser.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-bedroom-2-radio'
                        isTranslateForRooms={true}
                        hintText=''
                        text='radio'
                        sro='kicohcikanis/pihcākosiwinis'
                        syllabic='ᑭᒍᐦᒋᑲᓂᐢ/ᐱᐦᒑᑯᓯᐏᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_radio_combo.mp3'
                      />

                    </div>
                  </div>
                  <div className='c-btn-container'>
                    {btns}
                  </div>
                </div>

                {/* living room */}
                <div id='c-living-room' className='c-hidden'>
                  {translationBlock}
                  <div className='c-outer-wrapper'>
                    {backBtn}
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/household_items/images/living_room/bg.png'
                           alt=''/>

                      <BaseCmpWithHover
                        identifier='c-living-room-window'
                        isTranslateForRooms={true}
                        hintText=''
                        text='window'
                        sro='wāsīnamān'
                        syllabic='ᐚᓭᓇᒫᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_window.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-living-room-arm-chair'
                        isTranslateForRooms={true}
                        hintText=''
                        text='sofa chair'
                        sro='kā-thōskāk-tihtapiwin'
                        syllabic='ᑳ-ᖫᐢᑳᐠ-ᑎᐦᑕᐱᐏᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_sofa_chair.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-living-room-sofa'
                        isTranslateForRooms={true}
                        hintText=''
                        text='sofa'
                        sro='kā-kinwāk-tihtapiwin'
                        syllabic='ᑳ-ᑭᓎᐠ-ᑎᐦᑕᐱᐏᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_sofa.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-living-room-table-lamp'
                        isTranslateForRooms={true}
                        hintText=''
                        text='table lamp'
                        sro='wāsiskotīnikan'
                        syllabic='ᐚᓯᐢᑯᑌᓂᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_table_lamp.mp3'
                      />

                      <div id='c-living-room-tv-dvd-table' className='c-static-container'>
                        <div className='c-tooltip-owner'></div>
                      </div>

                      <BaseCmpWithHover
                        identifier='c-living-room-dvd-player'
                        isTranslateForRooms={true}
                        hintText=''
                        text='DVD player'
                        sro='cikāscīpathihcikākanis'
                        syllabic='ᒋᑳᐢᒉᐸᖨᐦᒋᑳᑲᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_dvd_player.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-living-room-tv'
                        isTranslateForRooms={true}
                        hintText=''
                        text='television'
                        sro='cikāscīpathihcikanis'
                        syllabic='ᒋᑳᐢᒉᐸᖨᐦᒋᑲᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_tv.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-living-room-rug'
                        isTranslateForRooms={true}
                        hintText=''
                        text='rug'
                        sro='anāskān'
                        syllabic='ᐊᓈᐢᑳᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_rug.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-living-room-coffee-table'
                        isTranslateForRooms={true}
                        hintText=''
                        text='coffee table'
                        sro='aspascikīwi-mīcisowināhcikos'
                        syllabic='ᐊᐢᐸᐢᒋᑫᐏ-ᒣᒋᓱᐏᓈᐦᒋᑯᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_coffee_table.mp3'
                      />

                    </div>
                  </div>
                  <div className='c-btn-container'>
                    {btns}
                  </div>
                </div>

                {/* laundry room */}
                <div id='c-laundry-room' className='c-hidden'>
                  {translationBlock}
                  <div className='c-outer-wrapper'>
                    {backBtn}
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/household_items/images/laundry_room/bg.png'
                           alt=''/>

                      <BaseCmpWithHover
                        identifier='c-laundry-room-washer'
                        isTranslateForRooms={true}
                        hintText=''
                        text='washing machine'
                        sro='sīpīkinikākan'
                        syllabic='ᓭᐯᑭᓂᑳᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_washing-machine.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-laundry-room-dryer'
                        isTranslateForRooms={true}
                        hintText=''
                        text='dryer'
                        sro='pāsikākan'
                        syllabic='ᐹᓯᑳᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_dryer.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-laundry-room-broom'
                        isTranslateForRooms={true}
                        hintText=''
                        text='broom'
                        sro='wīpahikan'
                        syllabic='ᐍᐸᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_broom.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-laundry-room-dust-pan'
                        isTranslateForRooms={true}
                        hintText=''
                        text='dust pan'
                        sro='macikwanāsi-kwāpahikan'
                        syllabic='ᒪᒋᑿᓈᓯ-ᒁᐸᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_dust_pan.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-laundry-room-mop'
                        isTranslateForRooms={true}
                        hintText=''
                        text='mop'
                        sro='pāhkohtakahikan/kicistihtakahikan'
                        syllabic='ᐹᐦᑯᐦᑕᑲᐦᐃᑲᐣ/ᑭᒋᐢᑎᐦᑕᑲᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_mop_both.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-laundry-room-washboard'
                        isTranslateForRooms={true}
                        hintText=''
                        text='washboard'
                        sro='cihcīsihtakahikan/sīpihkinihtakwān'
                        syllabic='ᒋᐦᒉᓯᐦᑕᑲᐦᐃᑲᐣ/ᓭᐱᐦᑭᓂᐦᑕᒁᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_washboard_both.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-laundry-room-furnace'
                        isTranslateForRooms={true}
                        hintText=''
                        text='gas, oil furnace/stove'
                        sro='pimīwikotawānāpisk'
                        syllabic='ᐱᒣᐏᑯᑕᐚᓈᐱᐢᐠ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_furnace.mp3'
                      />

                    </div>
                  </div>
                  <div className='c-btn-container'>
                    {btns}
                  </div>
                </div>

                {/* kitchen */}
                <div id='c-kitchen' className='c-hidden'>
                  {translationBlock}
                  <div className='c-outer-wrapper'>
                    {backBtn}
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/household_items/images/kitchen/bg.png'
                           alt=''/>

                      <BaseCmpWithHover
                        identifier='c-kitchen-light-bulb'
                        isTranslateForRooms={true}
                        hintText=''
                        text='Light bulb'
                        sro='wāsiskotīnikani-wīthkwās'
                        syllabic='ᐚᓯᐢᑯᑌᓂᑲᓂ ᐍᙿᒁᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_light_bulb.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-cupboards'
                        isTranslateForRooms={true}
                        hintText=''
                        text='cupboards'
                        sro='akocikan'
                        syllabic='ᐊᑯᒋᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_cupboard.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-deep-freeze'
                        isTranslateForRooms={true}
                        hintText=''
                        text='deep freeze'
                        sro='āhkwatihcikan'
                        syllabic='ᐋᐦᑿᑎᐦᒋᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_deepfreeze.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-fridge'
                        isTranslateForRooms={true}
                        hintText=''
                        text='fridge'
                        sro='tahkascikan'
                        syllabic='ᑕᐦᑲᐢᒋᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_fridge.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-cook-stove'
                        isTranslateForRooms={true}
                        hintText=''
                        text='cook stove'
                        sro='piminawaswākan'
                        syllabic='ᐱᒥᓇᐘᔃᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_cook_stove.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-table'
                        isTranslateForRooms={true}
                        hintText=''
                        text='table'
                        sro='mīcisowināhtik'
                        syllabic='ᒣᒋᓱᐏᓈᐦᑎᐠ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_table.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-chair'
                        isTranslateForRooms={true}
                        hintText=''
                        text='chair'
                        sro='tihtapiwin'
                        syllabic='ᑎᐦᑕᐱᐏᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_chair.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-table-cloth'
                        isTranslateForRooms={true}
                        hintText=''
                        text='table cloth'
                        sro='aspatōspowinīkin'
                        syllabic='ᐊᐢᐸᑑᐢᐳᐏᓀᑭᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_table-cloth.mp3'
                      />

                      <div id='c-kitchen-utencils-1' className='c-tooltip-container' name='utencils'>
                        <div
                          className='c-tooltip-owner'
                          onMouseMove={showHint}
                          onMouseOver={showTranslation}
                          onMouseLeave={hideTranslation}
                          onClick={() => (this.openRoom('c-kitchen-utencils'))}
                        />
                        {hintText}
                      </div>

                      <div id='c-kitchen-utencils-2' className='c-tooltip-container' name='utencils'>
                        <div
                          className='c-tooltip-owner'
                          onMouseMove={showHint}
                          onMouseOver={showTranslation}
                          onMouseLeave={hideTranslation}
                          onClick={() => (this.openRoom('c-kitchen-utencils'))}
                        />
                        {hintText}
                      </div>

                      <div id='c-kitchen-utencils-3' className='c-tooltip-container' name='utencils'>
                        <div
                          className='c-tooltip-owner'
                          onMouseMove={showHint}
                          onMouseOver={showTranslation}
                          onMouseLeave={hideTranslation}
                          onClick={() => (this.openRoom('c-kitchen-utencils'))}
                        />
                        {hintText}
                      </div>

                    </div>
                  </div>
                  <div className='c-btn-container'>
                    {btns}
                  </div>
                </div>

                {/* Kitchen utencils */}
                <div id='c-kitchen-utencils' className='c-hidden'>
                  {translationBlock}
                  <div className='c-outer-wrapper'>
                    <div id='c-back-to-rooms' onClick={() => (this.openRoom('c-kitchen'))}>
                      <div></div>
                      <span className='c-tooltip'>Back</span>
                    </div>
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/household_items/images/kitchen/bg1.png'
                           alt=''/>

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-roasting-pan'
                        isTranslateForRooms={true}
                        hintText=''
                        text='roasting pan'
                        sro='kaskihkasikan'
                        syllabic='ᑲᐢᑭᐦᑲᓯᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_roasting_pan.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-ladle'
                        isTranslateForRooms={true}
                        hintText=''
                        text='ladle'
                        sro='kwāpahikan'
                        syllabic='ᒁᐸᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_ladle.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-toaster'
                        isTranslateForRooms={true}
                        hintText=''
                        text='toaster'
                        sro='kāspihkasikākan'
                        syllabic='ᑳᐢᐱᐦᑲᓯᑳᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_toaster.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-tea-kettle'
                        isTranslateForRooms={true}
                        hintText=''
                        text='tea kettle'
                        sro='cīwaskihkos/sīsīpaskihkos'
                        syllabic='ᒉᐘᐢᑭᐦᑯᐢ/ᓭᓭᐸᐢᑭᐦᑯᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_tea-kettle_both.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-cup'
                        isTranslateForRooms={true}
                        hintText=''
                        text='cup'
                        sro='othākanis/minihkwācikanis'
                        syllabic='ᐅᖭᑲᓂᐢ/ᒥᓂᐦᒁᒋᑲᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_cup_both.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-plate'
                        isTranslateForRooms={true}
                        hintText=''
                        text='plate'
                        sro='atōspowithākan/napakithākan'
                        syllabic='ᐊᑑᐢᐳᐏᖭᑲᐣ/ᓇᐸᑭᖭᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_plate_both.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-cooking-pot'
                        isTranslateForRooms={true}
                        hintText=''
                        text='cooking pot'
                        sro='piminawaswākanaskihk'
                        syllabic='ᐱᒥᓇᐘᔃᑲᓇᐢᑭᕽ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_cooking_pot.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-frying-pan'
                        isTranslateForRooms={true}
                        hintText=''
                        text='frying pan'
                        sro='sāsīskihkwān/sāsiskihkwān'
                        syllabic='ᓵᓭᐢᑭᐦᒁᐣ/ᓵᓯᐢᑭᐦᒁᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_frying_pan_both.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-spatula'
                        isTranslateForRooms={true}
                        hintText=''
                        text='spatula'
                        sro='kwīskiwīpahikan'
                        syllabic='ᑵᐢᑭᐍᐸᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_spatula.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-table-spoon'
                        isTranslateForRooms={true}
                        hintText=''
                        text='table spoon'
                        sro='mistīmihkwān'
                        syllabic='ᒥᐢᑌᒥᐦᒁᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_table_spoon.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-teaspoon'
                        isTranslateForRooms={true}
                        hintText=''
                        text='teaspoon'
                        sro='apiscīmihkwānis'
                        syllabic='ᐊᐱᐢᒉᒥᐦᒁᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_teaspoon.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-fork'
                        isTranslateForRooms={true}
                        hintText=''
                        text='fork'
                        sro='cīstahasīpon'
                        syllabic='ᒉᐢᑕᐦᐊᓭᐳᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_fork.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-table-knife'
                        isTranslateForRooms={true}
                        hintText=''
                        text='table knife'
                        sro='mīcisowihkomānis'
                        syllabic='ᒣᒋᓱᐏᐦᑯᒫᓂᐢ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_table_knife.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-hunting-knife'
                        isTranslateForRooms={true}
                        hintText=''
                        text='hunting knife'
                        sro='mācīwihkomān'
                        syllabic='ᒫᒉᐏᐦᑯᒫᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_hunting_knife.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-filleting-knife'
                        isTranslateForRooms={true}
                        hintText=''
                        text='filleting knife'
                        sro='pānisāwihkomān'
                        syllabic='ᐹᓂᓵᐏᐦᑯᒫᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_filleting_knife.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-kitchen-utencils-butcher'
                        isTranslateForRooms={true}
                        hintText=''
                        text='butcher'
                        sro='mistīhkomān'
                        syllabic='ᒥᐢᑌᐦᑯᒫᐣ'
                        audio='api/get.php?file=media/other/household_items/audio/audio_butcher.mp3'
                      />

                    </div>
                  </div>
                  <div className='c-btn-container'>
                    {btns}
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    );
                      } else {
      return (
          <NewProgressBar/>
      );
    }
  }
}

const CATEGORY = "household_items";

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.ch[CATEGORY]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchData() {
    dispatch(chActions.fetchCH(CATEGORY));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(HouseholdItems);
