import React from 'react';
import '../main.css';
import './Numbers.css';
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
import ProgressBar from "../ProgressBar/ProgressBar";
import NewProgressBar from "../ProgressBar/newProgressBar";



class Numbers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
                selected: -1
        };
        this.playAudio = this.playAudio.bind(this);
    }

    playAudio(path) {
        let audio = new Audio(path);
        audio.play();
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.data) {
            nextProps.fetchData();
        }

        if (nextProps.data !== this.props.data) {
            console.log('NOT EQUAL SO UPDATING');

            this.setState({
                selected: -1
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (!this.props.data
            || !nextProps.data
            || nextProps.data.title1 !== this.props.data.title1
            || nextState.selected !== this.state.selected)
    }

    componentDidMount() {
        if (!this.props.data) {
            this.props.fetchData();
        }
    }

    changeSelected(e, selected) {
        $('#el'+this.state.selected).removeClass('active');
        console.log('#el'+this.state.selected);

        this.setState({selected: selected});
        console.log('selected', selected);
        $(e.target).addClass('active');
    }

    render() {

        const data = this.props.data;
        if (data) {
            const dataSet = data.data_set;
            const dataSetL = dataSet.length;
            const selected = dataSet[0].data[this.state.selected];
            const playAudio = (audio) => this.playAudio.bind(null, `/api/get.php?file=${audio}`);
            if (!dataSetL) {
                return <div>No data...</div>;
            }
            return (
                <div className='c-wrapper'>
		    <h3 className='main-title-container'>{data.title1}</h3>
                    <div className='c-container'>
                        <div className='c-content'>
                            <div className='number-chart'>
                                <div className="numbers-header">
                                    {  this.state.selected === -1 &&
                                        <div className='initial-header'>
                                            <p>Numbers in Standard Roman Orthography and Syllabics 1 to 100</p>
                                        </div>

                                    }

                                    { this.state.selected > -1 &&
                                        <div className='header'>
                                            <div className='left-header'>
                                                {selected.title2}
                                            </div>
                                            <div className='right-header'>
                                                {selected.title3}
                                            </div>
                                        </div>

                                    }

                                </div>

                                {dataSet[0].data.map((b, i) =>
                                    <a className='action' key={i} onClick={playAudio(b.audio)}>
                                    <div  className='action-hover' id={'el'+i} onClick={(e) => this.changeSelected(e, i)}>
                                        {b.title1}
                                    </div></a>
                                )}


                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <NewProgressBar/>
            );
        }
    }
}

const CATEGORY = "numbers";

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.ch[CATEGORY]
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData() {
        dispatch(chActions.fetchCH(CATEGORY));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Numbers);
