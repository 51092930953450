import React from 'react';
import '../main.css';
import './YearlyCalendar.css';
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
import ProgressBar from "../ProgressBar/ProgressBar";
import NewProgressBar from "../ProgressBar/newProgressBar";

class YearlyCalendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedWeekday: -1,
            selectedHoliday:0,
            selectedMonth: (new Date()).getMonth(),
            selectedYear: (new Date()).getFullYear()
        };
        this.playAudio = this.playAudio.bind(this);
    }

    playAudio(path) {
        let audio = new Audio(path);
        audio.play();
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.data) {
            nextProps.fetchData();
        }

        if (nextProps.data !== this.props.data) {
            console.log('NOT EQUAL SO UPDATING');

            this.setState({
                selectedWeekday: -1,
                selectedHoliday:0,
                selectedMonth: (new Date()).getMonth(),
                selectedYear: (new Date()).getFullYear()
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (!this.props.data
            || !nextProps.data
            || nextProps.data.title1 !== this.props.data.title1
            || nextState.selectedWeekday !== this.state.selectedWeekday
            || nextState.selectedHoliday !== this.state.selectedHoliday
            || nextState.selectedMonth !== this.state.selectedMonth
            || nextState.selectedYear !== this.state.selectedYear)
    }

    componentDidMount() {
        if (!this.props.data) {
            this.props.fetchData();
        }
    }

    changeSelectedWeekday(selected) {
        this.setState({selectedWeekday: selected});
    }

    changeSelectedHoliday(selected) {
        this.setState({selectedHoliday: selected});
    }


    changeSelectedMonth(selected) {
        let year = this.state.selectedYear;
        if (selected<0){
            selected = 11;
            year--;
        }
        if (selected>11){
            selected = 0;
            year++;
        }
        this.setState({selectedMonth: selected,
            selectedYear: year
        });
    }
    changeSelectedYear(selected) {
        this.setState({selectedYear: selected});
    }
    removeHover(){
        $('.onMouseHover').removeClass('active-month');
    }

    addHoverToMonth(){
        $('.onMouseHover').addClass('active-month');

    }
    getEaster(Y) {
        let C = Math.floor(Y/100);
        let N = Y - 19*Math.floor(Y/19);
        let K = Math.floor((C - 17)/25);
        let I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15;
        I = I - 30*Math.floor((I/30));
        I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11));
        let J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4);
        J = J - 7*Math.floor(J/7);
        let L = I - J;
        let M = 3 + Math.floor((L + 40)/44);
        let D = L + 28 - 31*Math.floor(M/4);


        return [ D+"."+M , D -2+"."+M
        ];
    }
    getFathersDay(yearNow){
        const day= (new Date( yearNow, 5, 1));

        while (day.getDay() !== 0) {
            day.setDate(day.getDate() + 1);
        }
        return day.getDate()+14+'.6'
    }
    getLabourDay(yearNow){
        const day= (new Date( yearNow, 8, 1));

        while (day.getDay() !== 1) {
            day.setDate(day.getDate() + 1);
        }
        return day.getDate()+'.9'

    }
    getMothersDay(yearNow){
        const day= (new Date( yearNow, 4, 1));

        while (day.getDay() !== 0) {
            day.setDate(day.getDate() + 1);
        }
        return day.getDate()+7+'.5'

    }

    getThanksgivingDay(yearNow){
        const day= (new Date( yearNow, 9, 1));

        while (day.getDay() !== 1) {
            day.setDate(day.getDate() + 1);
        }
        return day.getDate()+7+'.10'

    }

    getVictoriaDay(yearNow){
        const day= (new Date( yearNow, 4, 18));

        while (day.getDay() !== 1) {
            day.setDate(day.getDate() + 1);
        }
        return day.getDate()+'.5'

    }

    getMonthHolidays(holidays){
        const yearNow = this.state.selectedYear;
        const firsDayOfSelectedMonth = (new Date( yearNow, this.state.selectedMonth , 1)).getDay();
        const daysInMonth = new Date(yearNow, this.state.selectedMonth+1 , 0).getDate();

        let daysKeys = [];
        for (let i = 1 ; i <= firsDayOfSelectedMonth ; i++){
            daysKeys.push({value: 0});
        }
        for (let i = 1 ; i <= daysInMonth ; i++){
            let holiday = holidays.filter((el, b) => {
                return el.date === i+'.'+(this.state.selectedMonth+1);

            } );
            daysKeys.push({value: i, holiday: holiday[0]});
        }
        let length = daysKeys.length;
        for (let i = 0 ; i < (42 - length) ; i++){
            daysKeys.push({value: 0});
        }
        return daysKeys;

    }
    render() {
        const data = this.props.data;
        if (data) {
            const dataSet = data.data_set;
            const dataSetL = dataSet.length;
            const holidays = dataSet[2].data;
            const selectedWeekday = dataSet[0].data[this.state.selectedWeekday];
            const selectedMonth= dataSet[1].data[this.state.selectedMonth];
            const selectedHoliday = this.state.selectedHoliday;
            const playAudio = (audio) => this.playAudio.bind(null, `/api/get.php?file=${audio}`);
            let daysKeys = this.getMonthHolidays(holidays);
            const yearNow = this.state.selectedYear;
            const days = dataSet[3].data;
            const EasterData = this.getEaster(yearNow);
            const Easter = EasterData[0];
            const GgoodFriday = EasterData[1];
            holidays[0]["date"] = Easter;
            holidays[1]["date"] = GgoodFriday;
            holidays[2]["date"] = this.getFathersDay(yearNow);
            holidays[3]["date"] = this.getLabourDay(yearNow);
            holidays[4]["date"] = this.getMothersDay(yearNow);
            holidays[5]["date"] = this.getThanksgivingDay(yearNow);
            holidays[6]["date"] = this.getVictoriaDay(yearNow);


            if (!dataSetL) {
                return <div>No data...</div>;
            }
            return (
                <div className='c-wrapper'>
		    <h3 className='main-title-container'>{data.title1}</h3>
                    <div className='c-container'>
                        <div className='c-content'>

                            <div className='yearly-calendar'>
                                <div className="calendar">
                                    <div className="top-header">
                                        <div className="yearNow">{yearNow}</div>
                                        <div className="arrows">
                                            <div  onClick={() => this.changeSelectedMonth(this.state.selectedMonth-1)}><img src={`/api/get.php?file=media/yearly_calendar/images/1.png`}/></div>
                                            <div  onClick={() => this.changeSelectedMonth(this.state.selectedMonth+1)}><img src={`/api/get.php?file=media/yearly_calendar/images/2.png`}/></div>

                                        </div>
                                        { this.state.selectedWeekday === -1 &&
                                            <div  className="transcription onMouseHover" onMouseOver={() => this.addHoverToMonth()} onClick={playAudio(selectedMonth.audio)}
                                                  onMouseOut={() => this.removeHover() }>{selectedMonth.title3}  </div>
                                        }

                                        { this.state.selectedWeekday > -1 &&
                                        <div className="transcription2">{selectedWeekday.title3}</div>
                                        }

                                    </div>
                                    <div className="clearfix"/>
                                    <div className="calendar-chart">
                                        <div className="top-header2">
                                            { this.state.selectedWeekday === -1 &&
                                                <div className="onMouseHover" onMouseOver={() => this.addHoverToMonth()} onClick={playAudio(selectedMonth.audio)}
                                                     onMouseOut={() => this.removeHover()}>
                                                    <div className="month-selected">{selectedMonth.title1}</div>
                                                    <div className="month-trans">{selectedMonth.title2}</div>
                                                </div>

                                            }
                                            { this.state.selectedWeekday > -1 &&
                                            <div>
                                                <div className="weekday-trans">{selectedWeekday.title2}</div>
                                            </div>
                                            }

                                            <div className="weekdays">
                                                {dataSet[0].data.map((b, i) =>
                                                    <a  key={i} onClick={playAudio(b.audio)}>
                                                        <div className='weekday' id={'el' + i} onMouseOver={() => this.changeSelectedWeekday(i)}
                                                             onMouseOut={() => this.changeSelectedWeekday(-1) }>
                                                            {b.title1}
                                                        </div>
                                                    </a>
                                                )}
                                            </div>
                                            <div className="days">
                                                {daysKeys.map((b, i) =>
                                                <div key={'day'+i}>

                                                    {!b.holiday &&
                                                    <div className='day'>
                                                        <span className="number">{b.value > 0 && b.value}</span>
                                                    </div>
                                                    }

                                                    {b.holiday &&
                                                    <a   >
                                                        <div className='day holiday' id={'day'+ i}
                                                             onMouseOver={() => this.changeSelectedHoliday(b.holiday)}
                                                             onClick={playAudio(b.holiday.audio)}
                                                             onMouseOut={() => this.changeSelectedHoliday(0) } >
                                                            <span className="number">{b.value > 0 && b.value} </span>
                                                            <div className="holiday-title">{b.holiday && b.holiday.title1}</div>
                                                        </div>
                                                    </a>
                                                    }

                                                </div>
                                                )}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="right-bar">
                                    <div className="description">
                                        <div className="month-terms">
                                            <h6>Month Terms</h6>
                                            <div className="meaning"><b>Meaning: </b>{selectedMonth.title1}</div>
                                            <div className="significance-div"><b>Significance: </b><span className="significance" >{selectedMonth.description}</span> </div>
                                        </div>
                                        <div className="holiday-term">
                                            <h6>Holiday Term</h6>

                                            {selectedHoliday !== 0 &&
                                            <div className="holiday-trans">
                                                <p>{selectedHoliday.title3}</p>
                                                <p>{selectedHoliday.title2}</p>
                                                <p>"{selectedHoliday.description}"</p>
                                            </div>
                                            }

                                            <div className="static">

                                                {days.map((b, i) =>
                                                    <div className="static-inner"
                                                         onClick={playAudio(b.audio)}
                                                         key={b.title1}>
                                                        <span className="static1">{b.title1} </span> <span className="static2">{b.title2} </span>
                                                    </div>
                                                )}


                                            </div>
                                        </div>

                                    </div>
                                    <div className="months">
                                        {dataSet[1].data.map((b, i) =>
                                            <div className='month-a'  key={'month'+i} onClick={playAudio(b.audio)}>
                                                <div className='month' id={'month' + i}
                                                     onClick={() => this.changeSelectedMonth(i)}>
                                                    {b.title1}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                </div>



                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <NewProgressBar/>
            );
        }
    }
}

const CATEGORY = "yearly_calendar";

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.ch[CATEGORY]
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData() {
        dispatch(chActions.fetchCH(CATEGORY));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(YearlyCalendar);

