'use strict'

import React from 'react';
import PropTypes from 'prop-types'
import AnimatedSubject from './AnimatedSubject';
import {coordinatesToPosition, calculateAnimation} from './calculateAnimation';

const styles = {
  backgroundImg: {
    maxWidth: '100%'
  },
  layerImg: {
    width: '100%',
    height: '100%',
  }
}

class AnimatedScene extends React.Component {
  constructor(props) {
    super(props);

    this.sceneBackground = React.createRef();

    this.state = {animatePhase: true, pause: true, xFactor: 1, yFactor: 1};
    this.prevFrameTime = undefined;
    this.timeline = 0;
  }

  componentDidMount() {
    this.handleResize();  //handle image resize once render is done
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    console.log("componentWillUnmount -> resize called");
    window.removeEventListener('resize', this.handleResize);
    clearTimeout(this.resizeTaskTimer);
    window.cancelAnimationFrame(this.requestID);
  }

  handleResize = () => {
    console.log("handling resize...");
    this.needRecalcSize = true;
    const self = this;
    const recalcState = () => {
      self.resizeTaskTimer = null;
      if (this.sceneBackground && this.sceneBackground.current) {
        const {xFactor, yFactor, sceneSize} = this.calcSceneParams(this.sceneBackground.current);
        self.setState({xFactor, yFactor, sceneSize});
      }
    }
    //throttling
    const delay = 500;

    if (!this.resizeTaskTimer) {
      this.resizeTaskTimer = setTimeout(recalcState, delay);
    }
  }

  runAnimation = () => {
    this.animationStarted = true;
    this.prevAnim = Date.now();
    const animate = () => {
      const now = Date.now();
      //const loopDuration = now - this.prevAnim
      //if (loopDuration > 100) console.log('loop duration = ', loopDuration)
      this.prevAnim = now;

      this.setState((state, props) => ({animatePhase: !state.animatePhase}));
      this.requestID = window.requestAnimationFrame(animate);
    }
    this.requestID = window.requestAnimationFrame(animate);
  }

  calcSceneParams = (img) => {
    const xFactor = img.width / img.naturalWidth;
    const yFactor = img.height / img.naturalHeight;
    const sceneSize = {width: img.width, height: img.height};
    return {xFactor, yFactor, sceneSize};
  }

  onBackgroundLoad = ({target: img}) => {
    const {xFactor, yFactor, sceneSize} = this.calcSceneParams(img);

    this.setState({xFactor, yFactor, sceneSize, pause: false});
    this.runAnimation();
  }

  onAnimatedSubjectHover = (id, pause) => {
    if (!pause) {
      this.prevFrameTime = Date.now();
    }
    this.setState({pause});

    let title = '';
    let subject = {};

    if(id === "jail_fence") {
      subject = this.props.data.data.subjects.find(subject => subject.id === id.split('_')[0]);
    } else {
      subject = this.props.data.data.subjects.find(subject => subject.id === id);
    }
    const enTitle = subject.titles.en;
    if (enTitle) {
      title = enTitle;
    }
    if (subject.titles[this.props.tr]) {
      title += ' - ' + subject.titles[this.props.tr];
    }
    if (subject.titles[this.props.lg]) {
      title += ' : ' + subject.titles[this.props.lg];
    }

    this.props.onWordTitle(title);

    //---Fix for double click issue on iphone---//
    //check if mobile
    var mobile = (
      typeof window.orientation !== "undefined")
      || (navigator.userAgent.indexOf('IEMobile') !== -1
      );
    if(mobile)
    {
      this.onAnimatedSubjectClick(id);  //even when only onAnimatedSubjectHover, still play audio for iphone/mobiles.
    }
    //-----------------------------------------//
  }

  onTouchPauseStart = (id) => {
    this.stopId = id;
  }

  onTouchPauseEnd = (id) => {
    if (this.stopId === id) {
      //resumeAnimation
      this.prevFrameTime = Date.now();
      this.setState({pause: false});
      this.props.onWordTitle('');
    }
  }

  onAnimatedSubjectClick = (id) => {
    const subject = this.props.data.data.subjects.find(subject => subject.id === id);
    this.props.onWordAudio(subject.audio);
    console.log("onAnimatedSubjectClick");
  }

  renderAnimatedSubjects = () => {
    const subjects = this.props.data.data.subjects;
    const {xFactor, yFactor, pause, sceneSize} = this.state;

    if (!pause) {
      const now = Date.now();
      this.timeline += this.prevFrameTime ? now - this.prevFrameTime : 0;
      this.prevFrameTime = now;
    }

    const animatedSubjects = subjects.map(subject => {
      const {model, x, y, scaleX, scaleY, visibility, rotate} = calculateAnimation(subject.animation, this.timeline);
      const {x: centerX, y: centerY} = coordinatesToPosition({x, y}, sceneSize);

      return (
        <AnimatedSubject
          id={subject.id}
          key={subject.id}
          pause={subject.pause === undefined ? true : subject.pause }
          onClick={this.onAnimatedSubjectClick}
          onHover={this.onAnimatedSubjectHover}
          onTouchPauseStart={this.onTouchPauseStart}
          onTouchPauseEnd={this.onTouchPauseEnd}
          activeModelId={model}
          scaleX={scaleX}
          scaleY={scaleY}
          rotate={rotate}
          centerX={centerX}
          centerY={centerY}
          models={subject.animation.models}
          hidden={!visibility}
          xFactor={xFactor}
          yFactor={yFactor}
        />
      )
    })
    return animatedSubjects;
  }

  renderLayers = () => {
    const {xFactor, yFactor} = this.state;
    const data = this.props.data;

    if (!data || (data && !data.layers)) {
      return <div></div>;
    }

    return this.props.data.layers.map(layer => {
      const {id, zIndex, imageSrc, position, size: {width, height}, borderRadius, rotate} = layer

      const positionToCss = ({left, right, top, bottom}, {xFactor, yFactor}) => {
        const res = {}
        if (left === undefined && right === undefined || top === undefined && bottom === undefined) {
          console.error('position is not correct')
          return res
        }

        if (left !== undefined) {
          res.left = `${left * xFactor}px`
        } else if (right !== undefined) {
          res.right = `${right * xFactor}px`
        }

        if (top !== undefined) {
          res.top = `${top * yFactor}px`
        } else if (bottom !== undefined) {
          res.bottom = `${bottom * yFactor}px`
        }

        return res
      }

      const layerPosition = positionToCss(position, {xFactor, yFactor})

      const layerStyle = {
        zIndex,
        position: 'absolute',
        width: `${width * xFactor}px`,
        height: `${height * yFactor}px`,
        ...layerPosition,
        overflow: 'hidden',
        backgroundImage: `url(/api/get.php?file=${imageSrc})`,
        backgroundSize: 'cover',
        transform: `rotate(${rotate}deg)`,
      }

      if (borderRadius) {
        layerStyle.borderRadius = borderRadius
      }

      return <div key={id} style={layerStyle} />
    })
  }

  render() {
    const data = this.props.data;

    if (!data || (data && !data.background_image)) {
      return <div></div>;
    }

    return (
      <div style={{flex: '1 1 auto', justifyContent: "center", alignItems: "center", display: "flex", maxWidth: '100%'}}>
        <div style={{position: "relative", display: "inline-block", overflow: "hidden"}}>
          <img
            onLoad={this.onBackgroundLoad}
            src={`/api/get.php?file=${this.props.data.background_image}`}
            ref={this.sceneBackground}
            style={styles.backgroundImg}
          />
          {this.animationStarted && this.renderAnimatedSubjects()}
          {this.renderLayers()}
        </div>
      </div>
    )
  }
}

AnimatedScene.propTypes = {
  tr: PropTypes.string,
  lg: PropTypes.string,
  data: PropTypes.shape({
    background_image: PropTypes.string,
    data: PropTypes.shape({
      subjects: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        titles: PropTypes.object.isRequired,
        audio: PropTypes.string.isRequired,
        animation: PropTypes.shape({
          duration: PropTypes.number.isRequired,
          offset: PropTypes.number,
          totalFrames: PropTypes.number.isRequired,
          models: PropTypes.array.isRequired,
          keyframes: PropTypes.object.isRequired,
        }),
      }))
    }),
    layers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        zIndex: PropTypes.number.isRequired,
        imageSrc: PropTypes.string.isRequired,
        position: PropTypes.shape({
          left: PropTypes.number,
          right: PropTypes.number,
          top: PropTypes.number,
          bottom: PropTypes.number,
        }).isRequired,
        size: PropTypes.shape({
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
        }).isRequired,
        borderRadius: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  onWordTitle: PropTypes.func.isRequired,
  onWordAudio: PropTypes.func.isRequired,
}

AnimatedScene.defaultProps = {
  lang: 'en'
}

export default AnimatedScene
