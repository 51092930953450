import React from 'react';
//import '../main.css';
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
import MaleKinshipTerms from "../maleKinshipTerms/MaleKinshipTerms";
import FemaleKinshipTerms from "../femaleKinshipTerms/FemaleKinshipTerms";
import "../activities/click_and_hear/birdComponent.css";

class KinshipTerms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_category: 0
        };
        this.playAudio = this.playAudio.bind(this);
    }


    playAudio(path) {
        let audio = new Audio(path);
        audio.play();
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.data) {
            nextProps.fetchData();
        }

        if (nextProps.data !== this.props.data) {
            console.log('NOT EQUAL SO UPDATING');

            this.setState({
                current_category: 0
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (!this.props.data
            || !nextProps.data
            || nextProps.data.title1 !== this.props.data.title1
            || nextState.current_category !== this.state.current_category);
    }

    componentDidMount() {
        if (!this.props.data) {
            this.props.fetchData();
        }
    }
    changeCategory (category) {
        this.setState({current_category: category});
    }

    render() {
        const data = this.props.data;

        if (data) {
            const cc = this.state.current_category;
            const dataSet = data.data_set;
            const dataSetL = dataSet.length;
            let categories = '';

            if (!dataSetL) {
                return <div>No data...</div>;
            } else if (dataSetL > 1) {
                categories=dataSet.map((item, i) => {
                    if (cc === i) {
                      return  <div key={item.title1} className="tab-slider--trigger active">
                            {item.title1}
                          </div>
                    }else{
                      return  <div
                            key={item.title1}
                            onClick={() => this.changeCategory(i)}
                            className="tab-slider--trigger">
                            {item.title1}
                          </div>
                    }
                })
            }

            return (
                <div>
                   
                    <div className="page-title">
                      <h1 className="uc-first">{data.title1}</h1>
                    </div>
                    <div>
                        <div className="tab-slider--nav">
                        <ul className="tab-slider--tabs">{categories}</ul>
                        </div>
                    </div>
                    { this.state.current_category === 0 &&
                        <MaleKinshipTerms/>
                    }
                    { this.state.current_category === 1 &&
                       <FemaleKinshipTerms/>
                    }

                </div>
            )
        } else {
            return (
                <div>Loading...</div>
            );
        }

    }
}

const CATEGORY = "kinship_terms";

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.ch[CATEGORY]
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData() {
        dispatch(chActions.fetchCH(CATEGORY));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(KinshipTerms);
