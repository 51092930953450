import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Tab, Tabs, Sonnet } from 'react-bootstrap'; //for lyrics tabs

const Song6 = () => {

	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
		);  //check if mobile device

	if(mobile)
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>Teacher – okiskinwahamākīw by Darrell McCallum</p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/9-okiskinwahamakiw.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<Tabs defaultActiveKey="sro" id="uncontrolled-tab-example">
						<Tab eventKey="sro" title="SRO" default>
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">okiskinwahamākīw</h4>
									<div className="audio-text card-text">
										<div>kikiskīthimāw cī okiskinwahamākīw</div>
										<div>okiskinwahamākīw, okiskinwahamākīw</div>

										<div>kikiskīthimāw cī okiskinwahamākīw</div>
										<div>okiskinwahamākīw, kā-kiskinwahamāsk</div>

										<div>kikiskīthimāw cī omasinahikīsīs</div>
										<div>omasinahikīsīs, omasinahikīsīs</div>
									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="syllabics" title="Syllabics">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ</h4>
									<div className="audio-text card-text">
										<div>ᑭᑭᐢᑫᖨᒫᐤ ᒉ ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ</div>
										<div>ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ</div>

										<div>ᑭᑭᐢᑫᖨᒫᐤ ᒉ ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ</div>
										<div>ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ ᑳᑭᐢᑭᓌᐦᐊᒫᐢᐠ</div>

										<div>ᑭᑭᐢᑫᖨᒫᐤ ᒉ ᐅᒪᓯᓇᐦᐃᑫᓭᐢ</div>
										<div>ᐅᒪᓯᓇᐦᐃᑫᓭᐢ ᐅᒪᓯᓇᐦᐃᑫᓭᐢ</div>


									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="english" title="English">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">Do you know a teacher?</h4>
									<div className="audio-text card-text">
										<div>Do you know a teacher</div>
										<div>Teacher, teacher</div>
										<div>Do you know a teacher</div>
										<div>Teacher who teaches you</div>

										<div>Do you know a secretary</div>
										<div>A secretary, a secretary who writes</div>

										<div>Do you know a teacher</div>
										<div>Teacher, teacher</div>
										<div>Do you know a teacher</div>
										<div>Teacher who teaches you</div>

										<div>Do you know a secretary</div>
										<div>A secretary, a secretary who writes</div>

										<div>Repeat</div>


									</div>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>

			</div>
		);
	}
	else
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>Teacher – okiskinwahamākīw by Darrell McCallum</p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/9-okiskinwahamakiw.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">okiskinwahamākīw</h4>
									<div className="audio-text card-text">
										<div>kikiskīthimāw cī okiskinwahamākīw</div>
										<div>okiskinwahamākīw, okiskinwahamākīw</div>

										<div>kikiskīthimāw cī okiskinwahamākīw</div>
										<div>okiskinwahamākīw, kā-kiskinwahamāsk</div>

										<div>kikiskīthimāw cī omasinahikīsīs</div>
										<div>omasinahikīsīs, omasinahikīsīs</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ</h4>
									<div className="audio-text card-text">
										<div>ᑭᑭᐢᑫᖨᒫᐤ ᒉ ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ</div>
										<div>ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ</div>

										<div>ᑭᑭᐢᑫᖨᒫᐤ ᒉ ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ</div>
										<div>ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ ᑳᑭᐢᑭᓌᐦᐊᒫᐢᐠ</div>

										<div>ᑭᑭᐢᑫᖨᒫᐤ ᒉ ᐅᒪᓯᓇᐦᐃᑫᓭᐢ</div>
										<div>ᐅᒪᓯᓇᐦᐃᑫᓭᐢ ᐅᒪᓯᓇᐦᐃᑫᓭᐢ</div>

									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">Do you know a teacher?</h4>
									<div className="audio-text card-text">
										<div>Do you know a teacher</div>
										<div>Teacher, teacher</div>
										<div>Do you know a teacher</div>
										<div>Teacher who teaches you</div>

										<div>Do you know a secretary</div>
										<div>A secretary, a secretary who writes</div>

										<div>Do you know a teacher</div>
										<div>Teacher, teacher</div>
										<div>Do you know a teacher</div>
										<div>Teacher who teaches you</div>

										<div>Do you know a secretary</div>
										<div>A secretary, a secretary who writes</div>

										<div>Repeat</div>


									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}

};

export default Song6;
