// For handling array of syllabic_chart

import * as actionTypes from '../actions/actionTypes'

export const syllabicChartReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_SYLLABIC_CHART_SUCCESS:
	console.log("ACTION TYPES: FETCH SYLLABIC CHART")
          return action.syllabic_chart;
    default:
	console.log("DEFAULT STATE")
          return state;
  }
};

