import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Tab, Tabs, Sonnet } from 'react-bootstrap'; //for lyrics tabs

const Song7 = () => {

	let audio_attribution =	<div className="audio-attribution">
		<p>The animal I see</p>
	</div>

	let audio_player = <div>
				<AudioPlayer
					preload="none"
					src="/api/get.php?file=media/cree_audio/audio/4-pisiskiwak.mp3"
				/>
			  </div>

	let card1 = <div className="card">
		<div className="card-body">
			<h4 className="audio-text-title card-title">pisiskiwak</h4>
			<div className="audio-text card-text">
				<div>nikī-wāpamāw mostos,</div>
				<div>nikī-wāpamāw mostos,</div>
				<div>nikī-wāpamāw mostos,</div>
				<div>ōmisi ī-itwīt,</div>
				<div>moo, moo, moo, moo, moo, moo.</div>

				<div>nikī-wāpamāw atim,</div>
				<div>nikī-wāpamāw atim,</div>
				<div>nikī-wāpamāw atim,</div>
				<div>ōmisi ī-itwīt,</div>
				<div>arf, arf, arf, arf, arf, arf.</div>

				<div>nikī-wāpamāw kohkōs,</div>
				<div>nikī-wāpamāw kohkōs,</div>
				<div>nikī-wāpamāw kohkōs,</div>
				<div>ōmisi ī-itwīt,</div>
				<div>oink, oink, oink, oink, oink, oink.</div>

			</div>
		</div>
	</div>

	let card2 = <div className="card">
		<div className="card-body">
			<h4 className="audio-text-title card-title">ᐱᓯᐢᑭᐘᐠ</h4>
			<div className="audio-text card-text">
				<div>ᓂᑫᐚᐸᒫᐤ ᒧᐢᑐᐢ</div>
				<div>ᓂᑫᐚᐸᒫᐤ ᒧᐢᑐᐢ</div>
				<div>ᓂᑫᐚᐸᒫᐤ ᒧᐢᑐᐢ</div>
				<div>ᐆᒥᓯ ᐁᐃᑘᐟ</div>
				<div>ᒨ ᒨ ᒨ ᒨ ᒨ ᒨ</div>

				<div>ᓂᑫᐚᐸᒫᐤ ᐊᑎᒼ</div>
				<div>ᓂᑫᐚᐸᒫᐤ ᐊᑎᒼ</div>
				<div>ᓂᑫᐚᐸᒫᐤ ᐊᑎᒼ</div>
				<div>ᐆᒥᓯ ᐁᐃᑘᐟ</div>
				<div>ᐊᕒᑊ ᐊᕒᑊ ᐊᕒᑊ ᐊᕒᑊ ᐊᕒᑊ ᐊᕒᑊ</div>

				<div>ᓂᑫᐚᐸᒫᐤ ᑯᐦᑰᐢ</div>
				<div>ᓂᑫᐚᐸᒫᐤ ᑯᐦᑰᐢ</div>
				<div>ᓂᑫᐚᐸᒫᐤ ᑯᐦᑰᐢ</div>
				<div>ᐆᒥᓯ ᐁᐃᑘᐟ</div>
				<div>ᐅᐃᐣᐠ ᐅᐃᐣᐠ ᐅᐃᐣᐠ ᐅᐃᐣᐠ ᐅᐃᐣᐠ ᐅᐃᐣᐠ</div>
			</div>
		</div>
	</div>

	let card3 = <div className="card">
		<div className="card-body">
			<h4 className="audio-text-title card-title">The animal I see</h4>
			<div className="audio-text card-text">
				<div>I saw a cow</div>
				<div>I saw a cow</div>
				<div>I saw a cow</div>
				<div>This is what it said,</div>
				<div>moo, moo, moo</div>

				<div>I saw a dog</div>
				<div>I saw a dog</div>
				<div>I saw a dog</div>
				<div>This is what it said</div>
				<div>Arf, arf, arf</div>

				<div>I saw a pig</div>
				<div>I saw a pig</div>
				<div>I saw a pig</div>
				<div>This is what it said</div>
				<div>oink, oink oink</div>

				<div>I saw a duck</div>
				<div>I saw a duck</div>
				<div>I saw a duck</div>
				<div>This is what it said</div>
				<div>Quack, quack, quack</div>

				<div>I saw an owl</div>
				<div>I saw an owl</div>
				<div>I saw an owl</div>
				<div>This is what it said</div>
				<div>who, who, who</div>

				<div>I saw a bird</div>
				<div>I saw a bird</div>
				<div>I saw a bird</div>
				<div>This is what it said</div>
				<div>Whistle, whistle, whistle</div>

				<div>I saw a cat</div>
				<div>I saw a cat</div>
				<div>I saw a cat</div>
				<div>This is what it said</div>
				<div>meow, meow, meow</div>

				<div>I saw a duck</div>
				<div>I saw a duck</div>
				<div>I saw a duck</div>
				<div>This is what it said</div>
				<div>quack, quack, quack</div>

			</div>
		</div>
	</div>



	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
		);  //check if mobile device

	if(mobile)
	{
		return (
			<div>
				{audio_attribution}
				{audio_player}
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<Tabs defaultActiveKey="sro" id="uncontrolled-tab-example">
							<Tab eventKey="sro" title="SRO" default>
								{card1}
							</Tab>
							<Tab eventKey="syllabics" title="Syllabics">
								{card2}
							</Tab>
							<Tab eventKey="english" title="English">
								{card3}
							</Tab>
						</Tabs>
					</div>
				</div>

			</div>
		);
	}
	else
	{
		return (
			<div>
				{audio_attribution}
				{audio_player}
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							{card1}
						</div>
						<div className="col-md-6 col-lg-4">
							{card2}
						</div>
						<div className="col-md-6 col-lg-4">
							{card3}
						</div>
					</div>
				</div>

			</div>
		);
	}
};

export default Song7;
