import * as actionTypes from '../actions/actionTypes'

export const catalogReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CATALOG_SUCCESS:
		console.log("KW LOADING CATALOG")
          return action.catalog;
    default:
	console.log("DEFAULT STATE")
          return state;
  }
};
