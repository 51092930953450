import React from 'react';
import './NorthernSaskatchewanPlaceNames.css';
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
import ProgressBar from "../ProgressBar/ProgressBar";
import NewProgressBar from "../ProgressBar/newProgressBar";

class NorthernSaskatchewanPlaceNames extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section: 0
    };
    this.playAudio = this.playAudio.bind(this);
  }

  playAudio(path) {
    let audio = new Audio(path);
    audio.play();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.data) {
      nextProps.fetchData();
    }

    if (nextProps.data !== this.props.data) {
      console.log('NOT EQUAL SO UPDATING');

      this.setState({
        section: 0
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (!this.props.data
        || !nextProps.data
        || nextProps.data.title1 !== this.props.data.title1
        || nextState.section !== this.state.section
    )
  }

  componentDidMount() {
    if (!this.props.data) {
      this.props.fetchData();
    }
  }
  backAction(transformTop, scale, top) {
    $('.back-action, .back-section-action').css({
      display: 'none'
    });
    $('.menu').css({
      visibility: 'visible'
    });
    $('.map-top, .map-center,  .map-bottom').css({
      display: 'block'
    });
    $('.map').css({
      transform: `scale(${scale})`,
      transitionDuration: 'initial',
      transformOrigin: `center ${transformTop}%`,
      top: top
    });
    this.setState({section: 0})
  }

  backSectionAction() {
    let data= ($(window).width() <= 776) ? sectionsDataSmall[this.state.section-1] : sectionsData[this.state.section-1];

    let transformTop = data.transformTop;
    let scale= data.scale;
    let top = data.top;
    let transformLeft = data.transformLeft;

    $('.back-section-action').css({
      display: 'none'
    });
    let sectionClass = '.section' + this.state.section;
    $(sectionClass).css({
      display: 'block'
    });
    $('.map').css({
      transform: `scale(${scale})`,
      transitionDuration: 'initial',
      transformOrigin: `${transformLeft} ${transformTop}%`,
      top: top
    });
  }

  zoomMap( section) {
    let data= ($(window).width() <= 776) ? sectionsDataSmall[section-1] : sectionsData[section-1];

    let transformTop = data.transformTop;
    let scale= data.scale;
    let top = data.top;
    let transformLeft = data.transformLeft;

    $('.back-action').css({
      display: 'block'
    });
    $('.menu').css({
      visibility: 'hidden'
    });
    $('.map-top, .map-center,  .map-bottom').css({
      display: 'none'
    });
   $('.map').css({
     transform: `scale(${scale})`,
     transitionDuration: '2s',
     transformOrigin: `${transformLeft} ${transformTop}%`,
     top: top
   });
   this.setState({section: section})
  }
  zoomMap2(section) {
    let data = ($(window).width() <= 776) ? sectionsDataSmall2[section] : sectionsData2[section];

    let transformLeft = data.transformLeft;
    let transformTop = data.transformTop;
    let scale= data.scale;
    let top = data.top;

    $('.back-section-action').css({
      display: 'block'
    });
    let sectionClass = '.section' + this.state.section;
    $(sectionClass).css({
      display: 'none'
    });
    $('.map').css({
      transform: `scale(${scale})`,
      transitionDuration: '2s',
      transformOrigin: `${transformLeft}% ${transformTop}%`,
      top: top
    });
  }
  sectionOver(e){
    $(e.target).children().css({
      visibility: 'visible'
    });
  }
  sectionOut(e){
    $(e.target).children().css({
      visibility: 'hidden'
    });
  }
  sectionOver1(e){
    $(e.target).css({
      visibility: 'visible'
    });
  }

  render () {
    let width = $(window).width();
    console.log('width', width);

    const data = this.props.data;
    if (data) {
      const dataSet = data.data_set[0].data;
      const dataSetL = dataSet.length;
      const playAudio = (audio) => this.playAudio.bind(null, `/api/get.php?file=${audio}`);
      if (!dataSetL) {
        return <div>No data...</div>;
      }
    return (
      <div>
        <div>
          <div className='main-outer-wrapper'>
            <div className='main-inner-wrapper'>
              <h3 className='main-title-container'>Northern Saskatchewan Place Names</h3>

            <div className='c-wrapper northern-saskatchewan-place'>
              <div className='c-container'>
                <div className='c-content '>
                  <div className="menu">
                       <div className="menu-title">
                         <h3>{data.title1}</h3>
                       </div>
                    <div className="flag">
                      <img className='flag-img' src='api/get.php?file=media/other/northern_saskatchewan_place_names/images/flag.png' alt=''/>
                    </div>

                    <div className="description">
                        Click on a section of the map to zoom in
                    </div>

                    <div className="legend">
                      <img className='legend-img' src='api/get.php?file=media/other/northern_saskatchewan_place_names/images/legend0.png' alt=''/>
                    </div>


                  </div>
                  <div className="back-action"  onClick={(e) => this.backAction(0, 1, 0)} >
                    {'<<Back to menu'}
                  </div>
                  <div className="back-section-action"  onClick={(e) => this.backSectionAction()} >
                    {'<<Back'}
                  </div>
                  <div className="map">

                    <img className='map-img' src='api/get.php?file=media/other/northern_saskatchewan_place_names/images/main-map.png' alt=''/>
                    <div className="map-top" onMouseOver={(e) => this.sectionOver(e)}
                         onMouseOut={(e) => this.sectionOut(e)}
                         onClick={(e) => this.zoomMap(1)} >
                        <div className="section-title" >SECTION 1</div>
                    </div>
                    <div className="map-center"
                         onMouseOver={(e) => this.sectionOver(e)}

                         onMouseOut={(e) => this.sectionOut(e)}
                         onClick={(e) => this.zoomMap( 2)} >
                      <div className="section-title" >SECTION 2</div>
                    </div>
                    <div className="map-bottom"
                         onMouseOver={(e) => this.sectionOver(e)}
                         onMouseOut={(e) => this.sectionOut(e)}
                         onClick={(e) => this.zoomMap( 3)} >
                      <div className="section-title" >SECTION 3</div>
                    </div>
                    <div className="map-top-before"/>
                    <div className="map-center-before"/>


                    { this.state.section === 1 &&
                    <div className="section1">
                      <div className="map-top-left"
                           onClick={(e) => this.zoomMap2(0)}
                           onMouseOver={(e) => this.sectionOver(e)}
                           onMouseOut={(e) => this.sectionOut(e)} >
                      </div>
                      <div className="map-top-right"
                           onClick={(e) => this.zoomMap2(1 )}
                           onMouseOver={(e) => this.sectionOver(e)}
                           onMouseOut={(e) => this.sectionOut(e)} >
                      </div>
                    </div>
                    }
                    { this.state.section === 2 &&
                    <div className="section2">
                      <div className="map-center-left"
                           onClick={(e) => this.zoomMap2(2)}
                           onMouseOver={(e) => this.sectionOver(e)}
                           onMouseOut={(e) => this.sectionOut(e)} >
                        <div className="section-title" >2-A</div>
                      </div>
                      <div className="map-center-right"
                           onClick={(e) => this.zoomMap2(3)}
                           onMouseOver={(e) => this.sectionOver(e)}
                           onMouseOut={(e) => this.sectionOut(e)} >
                        <div className="section-title-left" >2-B</div>
                      </div>
                    </div>
                    }
                    { this.state.section === 3 &&
                    <div className="section3">
                      <div className="map-bottom-left"
                           onClick={(e) => this.zoomMap2(4)}
                           onMouseOver={(e) => this.sectionOver(e)}
                           onMouseOut={(e) => this.sectionOut(e)} >
                        <div className="section-title" >3-A</div>
                      </div>
                      <div className="map-bottom-right"
                           onClick={(e) => this.zoomMap2(5)}
                           onMouseOver={(e) => this.sectionOver(e)}
                           onMouseOut={(e) => this.sectionOut(e)} >
                        <div className="section-title-left" >3-B</div>
                      </div>
                    </div>
                    }

                    {dataSet.map((b, i) =>
                        <div key={i} >
                          <div className='place'  onClick={playAudio(b.audio)} style={{top: b.top+'px', left: b.left+'px'}}>
                            {b.title2}
                          </div>

                          <div className="place-name" style={{top: b.top2+'px', left: b.left2+'px'}}>{b.title1}</div>
                        </div>

                    )}

                  </div>
                </div></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }else {
      return (
          <NewProgressBar/>
      );
    }
}}

const sectionsDataSmall = [
    {
  transformTop: 0,
  scale: 1.2 ,
  top :  0,
      transformLeft: 'center'
},{
  transformTop: 70,
  scale: 1.2 ,
  top :   '-40px',
    transformLeft: 'center'
},{
  transformTop: 90,
  scale: 1.2 ,
  top :  '-305px',
    transformLeft: 'center'
}];

const sectionsDataSmall2 = [
  {
    transformLeft: 20,
    transformTop: 0,
    scale: 2.3,
    top :  0
  },{
  transformLeft: 90,
      transformTop: 10,
      scale: 2.3,
      top :  0
  }, {
    transformLeft: 10,
    transformTop: 60,
    scale: 2.2,
    top :  0
  },{
    transformLeft: 90,
    transformTop: 60,
    scale: 2.2,
    top :  0
  }, {
    transformLeft: 10,
    transformTop: 100,
    scale: 2.2,
    top :  0
  },{
    transformLeft: 90,
    transformTop: 100,
    scale: 2.4,
    top :  0
  }];

const sectionsData = [
  {
    transformTop: 0,
    scale: 1.8 ,
    top :  0,
    transformLeft: 'right'
  },{
    transformTop: 70,
    scale: 1.7 ,
    top :   '-40px',
    transformLeft: 'right'
  },{
    transformTop: 90,
    scale: 1.5 ,
    top :  '-305px',
    transformLeft: 'right'
  }];

const sectionsData2 = [
  {
    transformLeft: 50,
    transformTop: 0,
    scale: 3.2,
    top :  0
  },{
    transformLeft: 100,
    transformTop: 20,
    scale: 3.2,
    top :  0
  }, {
    transformLeft: 50,
    transformTop: 60,
    scale: 3.3,
    top :  0
  },{
    transformLeft: 100,
    transformTop: 60,
    scale: 3.3,
    top :  0
  }, {
    transformLeft: 45,
    transformTop: 100,
    scale: 3.2,
    top :  0
  },{
    transformLeft: 100,
    transformTop: 100,
    scale: 3.4,
    top :  0
  }];


const CATEGORY = "northern_saskatchewan_place";

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.ch[CATEGORY]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchData() {
    dispatch(chActions.fetchCH(CATEGORY));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NorthernSaskatchewanPlaceNames);
