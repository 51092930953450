export const FETCH_VERBS_SUCCESS = 'FETCH_VERBS_SUCCESS';
export const FETCH_VERB_BY_ID_SUCCESS = 'FETCH_VERBS_BY_ID_SUCCESS';

export const FETCH_WILD_ANIMALS_SUCCESS = 'FETCH_WILD_ANIMALS_SUCCESS';
export const FETCH_FOOD_SUCCESS = 'FETCH_FOOD_SUCCESS';
export const FETCH_BIRDS_SUCCESS = 'FETCH_BIRDS_SUCCESS';
export const FETCH_MALE_KINSHIP_SUCCESS = 'FETCH_MALE_KINSHIP_SUCCESS';


//export const FETCH_DOMESTIC_AND_FARM_ANIMALS_SUCCESS = 'FETCH_DOMESTIC_AND_FARM_ANIMALS_SUCCESS';


export const FETCH_CH_SUCCESS = 'FETCH_CH_SUCCESS';
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS';

//CHA
export const CHA_SCENE_SELECTED = 'CHA_SCENE_SELECTED';

export const FETCH_CHA_FILE_SUCCESS = 'FETCH_CHA_FILE_SUCCESS';
export const FETCH_CHA_SCENES_REQUEST = 'FETCH_CHA_REQUEST';
export const FETCH_CHA_SCENES_SUCCESS = 'FETCH_CHA_SCENES_SUCCESS';
export const FETCH_CHA_SCENES_FAILURE = 'FETCH_CHA_SCENES_FAILURE';

export const FETCH_CATALOG_SUCCESS = 'FETCH_CATALOG_SUCCESS';

export const FETCH_SYLLABIC_CHART_SUCCESS = 'FETCH_SYLLABIC_CHART_SUCCESS';

export const FETCH_CHA_SCENES_DATA_REQUEST = 'FETCH_CHA_SCENES_DATA_REQUEST';
export const FETCH_CHA_SCENES_DATA_SUCCESS = 'FETCH_CHA_SCENES_DATA_SUCCESS';
export const FETCH_CHA_SCENES_DATA_FAILURE = 'FETCH_CHA_SCENES_DATA_FAILURE';
