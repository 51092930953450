import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Tab, Tabs, Sonnet } from 'react-bootstrap'; //for lyrics tabs

const Song4 = () => {

	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
		);  //check if mobile device

	if(mobile)
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>The Lord's Prayer</p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/the_lords_prayer.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<Tabs defaultActiveKey="sro" id="uncontrolled-tab-example">
						<Tab eventKey="sro" title="SRO" default>
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">kā-tipīthihcikīt otayamihāwin</h4>
									<div className="audio-text card-text">
										<div>nohtāwīnān kihcikīsikohk kāyāyin,</div>
										<div>kitawī-kanātīthihtākwan kiwihthowin,</div>
										<div>kitipīthihcikīwin kitawī-ocihcipathiw,</div>
										<div>ī-itīthihtaman kitawī-itōcikātīw ōta</div>
										<div>askīhk, kā-isi-ayāk kihcikīsikohk.</div>

										<div>mīthinān anohc kā-kīsikāk</div>
										<div>kā-ohci-pimātisiyāhk, mīna asīnamawinān</div>
										<div>nimacihtiwināna, kā-isi-asīnamawakihcik</div>
										<div>aniki kā-wanitōtākoyahkwāw; mīna</div>
										<div>kāwitha itohtahinān kotīhimikowinihk,</div>
										<div>māka mītākwīnamawinān maci-kīkway.</div>

										<div>kītha kitayān tipīthihcikīwin, mīna</div>
										<div>sohkātisiwin, mīna kistīthihtākosiwin,</div>
										<div>kākikī mīna kākikī.</div>
										<div>Amen.</div>


									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="syllabics" title="Syllabics">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">ᑳ-ᑎᐯᖨᐦᒋᑫᐟ ᐅᑕᔭᒥᐦᐋᐏᐣ</h4>
									<div className="audio-text card-text">
										<div>ᓄᐦᑖᐍᓈᐣ ᑭᐦᒋᑫᓯᑯᕽ ᑳᔮᔨᐣ</div>
										<div>ᑭᑕᐍᑲᓈᑌᖨᐦᑖᑿᐣ ᑭᐏᐦᖪᐏᐣ</div>
										<div>ᑭᑎᐯᖨᐦᒋᑫᐏᐣ ᑭᑕᐍᐅᒋᐦᒋᐸᖨᐤ</div>
										<div>ᐁᐃᑌᖨᐦᑕᒪᐣ ᑭᑕᐍᐃᑑᒋᑳᑌᐤ ᐆᑕ</div>
										<div>ᐊᐢᑫᕽ ᑳᐃᓯᐊᔮᐠ ᑭᐦᒋᑫᓯᑯᕽ</div>

										<div>ᒣᖨᓈᐣ ᐊᓄᐦᐨ ᑳᑫᓯᑳᐠ</div>
										<div>ᑳᐅᐦᒋᐱᒫᑎᓯᔮᕽ ᒣᓇ ᐊᓭᓇᒪᐏᓈᐣ</div>
										<div>ᓂᒪᒋᐦᑎᐏᓈᓇ ᑳᐃᓯᐊᓭᓇᒪᐘᑭᐦᒋᐠ</div>
										<div>ᐊᓂᑭ ᑳᐘᓂᑑᑖᑯᔭᐦᒁᐤ; ᒣᓇ</div>
										<div>ᑳᐏᖬ ᐃᑐᐦᑕᐦᐃᓈᐣ ᑯᑌᐦᐃᒥᑯᐏᓂᕽ</div>
										<div>ᒫᑲ ᒣᑖᑵᓇᒪᐏᓈᐣ ᒪᒋᑫᑿᐩ</div>

										<div>ᑫᖬ ᑭᑕᔮᐣ ᑎᐯᖨᐦᒋᑫᐏᐣ ᒣᓇ</div>
										<div>ᓱᐦᑳᑎᓯᐏᐣ ᒣᓇ ᑭᐢᑌᖨᐦᑖᑯᓯᐏᐣ</div>
										<div>ᑳᑭᑫ ᒣᓇ ᑳᑭᑫ</div>
										<div>Amen.</div>


									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="english" title="English">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">The Lord's Prayer</h4>
									<div className="audio-text card-text">
										<div>Our Father who art in heaven,</div>
										<div>Hallowed be thy name.</div>
										<div>Thy kingdom come.</div>
										<div>Thy will be done, on earth as it is in heaven.</div>
										<div>Give us this day our daily bread.</div>
										<div>And forgive us our trespassess, as we forgive those who trespass against us.</div>
										<div>And lead us not into temptation, but deliver us from evil.</div>
										<div>For thine is the kingdom, the power, and the glory, forever and ever.</div>
										<div>Amen.</div>
									</div>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>

			</div>
		);
	}
	else
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>The Lord's Prayer</p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/the_lords_prayer.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">kā-tipīthihcikīt otayamihāwin</h4>
									<div className="audio-text card-text">
										<div>nohtāwīnān kihcikīsikohk kāyāyin,</div>
										<div>kitawī-kanātīthihtākwan kiwihthowin,</div>
										<div>kitipīthihcikīwin kitawī-ocihcipathiw,</div>
										<div>ī-itīthihtaman kitawī-itōcikātīw ōta</div>
										<div>askīhk, kā-isi-ayāk kihcikīsikohk.</div>

										<div>mīthinān anohc kā-kīsikāk</div>
										<div>kā-ohci-pimātisiyāhk, mīna asīnamawinān</div>
										<div>nimacihtiwināna, kā-isi-asīnamawakihcik</div>
										<div>aniki kā-wanitōtākoyahkwāw; mīna</div>
										<div>kāwitha itohtahinān kotīhimikowinihk,</div>
										<div>māka mītākwīnamawinān maci-kīkway.</div>

										<div>kītha kitayān tipīthihcikīwin, mīna</div>
										<div>sohkātisiwin, mīna kistīthihtākosiwin,</div>
										<div>kākikī mīna kākikī.</div>
										<div>Amen.</div>


									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">ᑳ-ᑎᐯᖨᐦᒋᑫᐟ ᐅᑕᔭᒥᐦᐋᐏᐣ</h4>
									<div className="audio-text card-text">

										<div>ᓄᐦᑖᐍᓈᐣ ᑭᐦᒋᑫᓯᑯᕽ ᑳᔮᔨᐣ</div>
										<div>ᑭᑕᐍᑲᓈᑌᖨᐦᑖᑿᐣ ᑭᐏᐦᖪᐏᐣ</div>
										<div>ᑭᑎᐯᖨᐦᒋᑫᐏᐣ ᑭᑕᐍᐅᒋᐦᒋᐸᖨᐤ</div>
										<div>ᐁᐃᑌᖨᐦᑕᒪᐣ ᑭᑕᐍᐃᑑᒋᑳᑌᐤ ᐆᑕ</div>
										<div>ᐊᐢᑫᕽ ᑳᐃᓯᐊᔮᐠ ᑭᐦᒋᑫᓯᑯᕽ</div>

										<div>ᒣᖨᓈᐣ ᐊᓄᐦᐨ ᑳᑫᓯᑳᐠ</div>
										<div>ᑳᐅᐦᒋᐱᒫᑎᓯᔮᕽ ᒣᓇ ᐊᓭᓇᒪᐏᓈᐣ</div>
										<div>ᓂᒪᒋᐦᑎᐏᓈᓇ ᑳᐃᓯᐊᓭᓇᒪᐘᑭᐦᒋᐠ</div>
										<div>ᐊᓂᑭ ᑳᐘᓂᑑᑖᑯᔭᐦᒁᐤ; ᒣᓇ</div>
										<div>ᑳᐏᖬ ᐃᑐᐦᑕᐦᐃᓈᐣ ᑯᑌᐦᐃᒥᑯᐏᓂᕽ</div>
										<div>ᒫᑲ ᒣᑖᑵᓇᒪᐏᓈᐣ ᒪᒋᑫᑿᐩ</div>

										<div>ᑫᖬ ᑭᑕᔮᐣ ᑎᐯᖨᐦᒋᑫᐏᐣ ᒣᓇ</div>
										<div>ᓱᐦᑳᑎᓯᐏᐣ ᒣᓇ ᑭᐢᑌᖨᐦᑖᑯᓯᐏᐣ</div>
										<div>ᑳᑭᑫ ᒣᓇ ᑳᑭᑫ</div>
										<div>Amen.</div>

									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">The Lord's Prayer</h4>
									<div className="audio-text card-text">
										<div>Our Father who art in heaven,</div>
										<div>Hallowed be thy name.</div>
										<div>Thy kingdom come.</div>
										<div>Thy will be done, on earth as it is in heaven.</div>
										<div>Give us this day our daily bread.</div>
										<div>And forgive us our trespassess, as we forgive those who trespass against us.</div>
										<div>And lead us not into temptation, but deliver us from evil.</div>
										<div>For thine is the kingdom, the power, and the glory, forever and ever.</div>
										<div>Amen.</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		);
	}
};

export default Song4;
