// For handling array of food

import * as actionTypes from '../actions/actionTypes'

export const foodReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_FOOD_SUCCESS:
          return action.food;
    default:
          return state;
  }
};

