import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Tab, Tabs, Sonnet } from 'react-bootstrap'; //for lyrics tabs

const Song2 = () => {

	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
		);  //check if mobile device

	if(mobile)
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>To town I will go - otīnahk nīkītohtān – Darrell McCallum</p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/1-otinahk.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div>
						<Tabs defaultActiveKey="sro" id="uncontrolled-tab-example">
							<Tab eventKey="sro" title="SRO" default>
								<div className="card">
									<div className="card-body">
										<h4 className="audio-text-title card-title">otīnahk nīkītohtān</h4>
										<div className="audio-text card-text">
											<div>ōtīnāhk nikī-itohtān,</div>
											<div>astotin nitātāwān,</div>
											<div>astotin nitātāwān,</div>
											<div>ōtīnāhk nikī-itohtān.</div>

											<div>ōtīnāhk nikī-itohtān,</div>
											<div>nikāwiy niwīcīwaw,</div>
											<div>nikāwiy niwīcīwaw,</div>
											<div>ōtīnāhk nikī-itohtān.</div>

											<div>ōtīnāhk nikī-itohtān,</div>
											<div>nohtāwiy niwīcīwāw,</div>
											<div>nohtāwiy niwīcīwāw,</div>
											<div>ōtīnāhk nikī-itohtān.</div>

										</div>
									</div>
								</div>
							</Tab>
							<Tab eventKey="syllabics" title="Syllabics">
								<div className="card">
									<div className="card-body">
										<h4 className="audio-text-title card-title">ᐅᑌᓇᕽ ᓀᑫᑐᐦᑖᐣ</h4>
										<div className="audio-text card-text">
											<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>
											<div>ᐊᐢᑐᑎᐣ ᓂᑖᑖᐚᐣ</div>
											<div>ᐊᐢᑐᑎᐣ ᓂᑖᑖᐚᐣ</div>
											<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>

											<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>
											<div>ᓂᑳᐏᐩ ᓂᐍᒉᐘᐤ</div>
											<div>ᓂᑳᐏᐩ ᓂᐍᒉᐘᐤ</div>
											<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>

											<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>
											<div>ᓄᐦᑖᐏᐩ ᓂᐍᒉᐚᐤ</div>
											<div>ᓄᐦᑖᐏᐩ ᓂᐍᒉᐚᐤ</div>
											<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>
										</div>
									</div>
								</div>
							</Tab>
							<Tab eventKey="english" title="English">
								<div className="card">
									<div className="card-body">
										<h4 className="audio-text-title card-title">To town I will go</h4>
										<div className="audio-text card-text">
											<div>To town I will go</div>
											<div>To town I will go</div>
											<div>I will buy a hat</div>
											<div>I will buy a hat</div>
											<div>To town I will go</div>
											<div>To town I will go</div>

											<div>I am going with my mother</div>
											<div>I am going with my mother</div>
											<div>To town I will go</div>
											<div>To town I will go</div>
											<div>I am going with my father</div>
											<div>I am going with my father</div>
											<div>To town I will go</div>

											<div>To town I will go</div>
											<div>To town I will go</div>
											<div>I will buy shoes</div>
											<div>I will buy shoes</div>
											<div>To town I will go</div>
											<div>To town I will go</div>
											<div>I am going with my grandfather</div>
											<div>I am going with my grandfather</div>
											<div>To town I will go</div>

										</div>
									</div>
								</div>
							</Tab>
						</Tabs>
					</div>
				</div>

			</div>
		);
	}
	else
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>To town I will go - otīnahk nīkītohtān – Darrell McCallum</p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/1-otinahk.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">otīnahk nīkītohtān</h4>
									<div className="audio-text card-text">
										<div>ōtīnāhk nikī-itohtān,</div>
										<div>astotin nitātāwān,</div>
										<div>astotin nitātāwān,</div>
										<div>ōtīnāhk nikī-itohtān.</div>

										<div>ōtīnāhk nikī-itohtān,</div>
										<div>nikāwiy niwīcīwaw,</div>
										<div>nikāwiy niwīcīwaw,</div>
										<div>ōtīnāhk nikī-itohtān.</div>

										<div>ōtīnāhk nikī-itohtān,</div>
										<div>nohtāwiy niwīcīwāw,</div>
										<div>nohtāwiy niwīcīwāw,</div>
										<div>ōtīnāhk nikī-itohtān.</div>

									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">ᐅᑌᓇᕽ ᓀᑫᑐᐦᑖᐣ</h4>
									<div className="audio-text card-text">

										<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>
										<div>ᐊᐢᑐᑎᐣ ᓂᑖᑖᐚᐣ</div>
										<div>ᐊᐢᑐᑎᐣ ᓂᑖᑖᐚᐣ</div>
										<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>

										<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>
										<div>ᓂᑳᐏᐩ ᓂᐍᒉᐘᐤ</div>
										<div>ᓂᑳᐏᐩ ᓂᐍᒉᐘᐤ</div>
										<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>

										<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>
										<div>ᓄᐦᑖᐏᐩ ᓂᐍᒉᐚᐤ</div>
										<div>ᓄᐦᑖᐏᐩ ᓂᐍᒉᐚᐤ</div>
										<div>ᐆᑌᓈᕽ ᓂᑫᐃᑐᐦᑖᐣ</div>

									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">To town I will go</h4>
									<div className="audio-text card-text">
										<div>To town I will go</div>
										<div>To town I will go</div>
										<div>I will buy a hat</div>
										<div>I will buy a hat</div>
										<div>To town I will go</div>
										<div>To town I will go</div>

										<div>I am going with my mother</div>
										<div>I am going with my mother</div>
										<div>To town I will go</div>
										<div>To town I will go</div>
										<div>I am going with my father</div>
										<div>I am going with my father</div>
										<div>To town I will go</div>

										<div>To town I will go</div>
										<div>To town I will go</div>
										<div>I will buy shoes</div>
										<div>I will buy shoes</div>
										<div>To town I will go</div>
										<div>To town I will go</div>
										<div>I am going with my grandfather</div>
										<div>I am going with my grandfather</div>
										<div>To town I will go</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
};

export default Song2;
