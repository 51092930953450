import React from 'react';
import { Link } from 'react-router-dom';

const HomeActivities = () => {
    var mobile = (
        typeof window.orientation !== "undefined")
        || (navigator.userAgent.indexOf('IEMobile') !== -1
        );  //check if mobile
    var mobileIntroParagraph;
    if(!mobile)
    {
        //only show main paragraph if not mobile
        mobileIntroParagraph = (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8" id="mission-statement">
                        <p>The Lac La Ronge Indian Band, N-12 Education Program will provide an education that meets the unique needs of our children; that emphasizes the four goals of pride, culture, skills, and values; and that helps our children achieve academic excellence and proficiency with the Cree Language.</p>
                    </div>
                    <div className="col-md-4" id="main-logo">
                        <img src="/api/get.php?file=media/Home/logo/logo180x180.png" alt="Learn Cree Logo" />
                    </div>
                </div>
            </div>
        )
    }

  return (
    <div className="page-links">
        {mobileIntroParagraph}
    <section>
	<div className="section-title text-center">
	    <h1>Activities</h1>
	</div>
    </section>
    <section className="interactive-picture">
        <div className="interactive-div categories">
            <div className="container1">
                <div className="food-div main1">
                <div className="food-box1">
                        <Link to="/cha/animal_homes" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/animal_homes.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Animal Homes &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/hs/birds" className="box-link">
                           <div className="card interactive-box">
                              <img style={{maxHeight: "156px"}} src="/api/get.php?file=media/Home/birds.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Birds &nbsp;&nbsp; > </h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    
                    <div className="food-box1">
                        <Link to="/bones" className="box-link">
                           <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/bones.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Bones &nbsp;&nbsp; > </h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    
                    <div className="food-box1">
                        <Link to="/cha/buildings" className="box-link"> 
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/buildings.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Buildings &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/classroom-objects" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/classroom_objects.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">ClassRoom Objects &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/hs/clothing" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/clothing.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Clothing &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/hs/colours" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/colours.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Colours &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/ch/days_of_the_week" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/days_of_the_week.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Days of the Week &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/hs/domestic_and_farm_animals" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/domestic_and_farm_animals.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Domestic and Farm Animals &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/ch/fish" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/fish.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Fish &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/hs/food" className="box-link">
                            <div className="card interactive-box">
                              <img style={{maxHeight: "156px"}} src="/api/get.php?file=media/Home/food.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Food &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
		   <div className="food-box1">
                        <Link to="/household-items" className="box-link">
                           <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/household_items.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Household Items &nbsp;&nbsp; > </h5>
                              </div>
                            </div>
                        </Link>
                    </div>
		   <div className="food-box1">
                        <Link to="/ch/insects" className="box-link">
                           <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/insects.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Insects &nbsp;&nbsp; > </h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    
                    <div className="food-box1">
                        <Link to="/kinship-terms" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/kinship_terms.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Kinship Terms &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/northern-saskatchewan-place-names" className="box-link"> 
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/northern_saskatchewan_place_names.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Northern Saskatchewan Place Names&nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/numbers" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/numbers.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Numbers &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/ch/occupations" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/occupations.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Occupations &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                     <div className="food-box1">
                        <Link to="/syllabic-chart" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/syllabics_chart.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Syllabic Chart &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/syllabic-matching-game/type1/1" className="box-link"> 
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/syllabics_game.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Syllabic Matching Game &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    
                   
                    <div className="food-box1">
                        <Link to="/six-seasons" className="box-link">
                           <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/seasons.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Six Seasons &nbsp;&nbsp; > </h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/trees" className="box-link">
                            <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/trees.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Trees &nbsp;&nbsp; ></h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                    <div className="food-box1">
                        <Link to="/ch/verbs" className="box-link">
                           <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/verbs.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Verbs &nbsp;&nbsp; > </h5>
                              </div>
                            </div>
                        </Link>
                    </div>

                    <div className="food-box1">
                        <Link to="/ch/wild_animals" className="box-link">
                           <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/wild_animals.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Wild Animals &nbsp;&nbsp; > </h5>
                              </div>
                            </div>
                        </Link>
                    </div>

                     <div className="food-box1">
                        <Link to="/yearly-calendar" className="box-link">
                           <div className="card interactive-box">
                              <img src="/api/get.php?file=media/Home/calendar.jpg" className="card-img-top" alt="..."/>
                              <div className="card-body text-center">
                                <h5 className="card-title">Yearly Calendar &nbsp;&nbsp; > </h5>
                              </div>
                            </div>
                        </Link>
                    </div>
                </div>
                 
               
            </div>
        </div>
    </section>
    </div>
  );
};

export default HomeActivities;
