import React from 'react';
import '../main.css';
import './Trees.css';
import Axios from "axios";
import ProgressBar from "../ProgressBar/ProgressBar";
//import {fetchCHSuccess, getData} from "../../actions/chActions";
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
import KWPreload from "./KWPreload";

class Trees extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: "trees",
            sro: "mistikwak",
            syllabics: "ᒥᐢᑎᑿᐠ",
            treeClassName: "",
            data: {},
	    preloaded: false
        };

	this.setLoaded = this.setLoaded.bind(this)
    }

    changeSelected(treeName, sro, syllabics, className) {
        this.setState({selected: treeName});
        this.setState({sro: sro});
        this.setState({syllabics: syllabics});
        this.setState({treeClassName: "tree-clup-" + className});
        this.playAudio('/api/get.php?file=media/trees/audio/' + treeName + '.mp3');
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.data) {
            nextProps.fetchData();
        }

        if (nextProps.data !== this.props.data) {
            console.log('NOT EQUAL SO UPDATING');
        }
    }

   setLoaded(){
	this.setState({preloaded: true})
   }

    componentDidMount() {
        if (!this.props.data) {
            this.props.fetchData();
        }
    }


    playAudio(path) {
        let audio = new Audio(path);
        audio.play();
    }


    render() {
	console.log("PPPP PROPS")
	console.log(this.props)

        const data = this.props.data;
	if (data && !this.state.preloaded){

		let audio = [
			"alder.mp3",
			"balsam fir.mp3",
			"birch.mp3",
			"black popular.mp3",
			"black spruce.mp3",
			"jack pine.mp3",
			"juniper.mp3",
			"maple.mp3",
			"red willow.mp3",
			"tamarack.mp3",
			"trees.mp3",
			"white popular.mp3",
			"white spruce.mp3",
			"willow.mp3"
		]
			
		let images = [
			"balsam_fir_clup.png",
			"birch_clup.png",
			"black popular.png",
			"black spruce.png",
			"popular_clup.png",
			"red willow.png",
			"tamarack_clup.png",
			"trees.png",
			"white_spruce_clup.png",
			"willow.png",
			"balsam fir.png",
			"birch.png",
			"black_spruce_clup.png",
			"jack_pine_clup.png",
			"popular.png",
			"red_willows_clup.png",
			"tamarack.png",
			"white_popular_clup.png",
			"white spruce.png",
			"birchbark_clup.png",
			"black_popular_clup.png",
			"black_spruce-clup.png",
			"jack pine.png",
			"red_willow_clup.png",
			"red willows.png",
			"text_bg.png",
			"white popular.png",
			"willow_clup.png"
		]

		return <KWPreload images={images} audio={audio} setLoaded={this.setLoaded} />


	}
        else if (data) {
            //const pathToImg = "/files/media/trees/images/";
            const pathToImg = "/api/get.php?file=media/trees/images/";
            let treeList = "";
            let {selected, syllabics, sro, treeClassName} = this.state;

            if (Object.keys(data).length > 0) {
                treeList = data.data_set[0].data.map(tree => (
                    <li
                        key={tree.treeName}
                        className={selected === tree.treeName ? "selected" : ""}
                        onClick={this.changeSelected.bind(this, tree.treeName, tree.sro, tree.syllabics, tree.className)}
                    >
                        {tree.treeName}

                        <img className="preload" width="20"
                             src={`/files/media/trees/images/${tree.treeName}.png`}
                        />
                        <img className="preload" width="20"
                             src={`/files/media/trees/images/${tree.treeName.split(' ').join('_')}_clup.png`}
                        />
                    </li>
                ));
            }

            return (
                <div className='c-wrapper'>
		    <h3 className='main-title-container'>Trees</h3>
                    <div className='c-container trees-container'>
                        <div className="trees">
                            <div className="tree-list">
                                <h3 className="sro">{sro}</h3>
                                <h3 className="syllabics">{syllabics}</h3>
                                <ul>
                                    {treeList}
                                </ul>
                            </div>
                            <div className="right-block">
                                <img src={pathToImg + selected + '.png'}/>
                                <div className={treeClassName}>
                                    <hr/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

	} else {
		return (
			<div>Loading</div>
		)
	}
/*
        } else {
            return (
               <ProgressBar/>
            );
        }
*/
    }
}

const CATEGORY = "trees";

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.ch[CATEGORY]
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData() {
        dispatch(chActions.fetchCH(CATEGORY));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Trees)




















