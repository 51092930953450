'use strict'

export function calculateAnimation(config, timeline, ratio = 10000) {
  const {offset = 0, duration, models, totalFrames, keyframes} = config
  const frameDuration = duration / totalFrames
  const ownTimeline = timeline - offset
  const  currLoopTimeline = ownTimeline % duration

  let currentFrameNum = Math.ceil(currLoopTimeline / frameDuration) || 1
  if (totalFrames < currentFrameNum) {
    console.error('totalFrames < currentFrameNum')
    currentFrameNum = 1
  }
  const prevFrameNum = currentFrameNum === 1 ? totalFrames : currentFrameNum - 1
  const spreadedKeyframes = {}
  let prevFoundFrame
  for (let i = 1; i <= totalFrames; i++) {
    let foundFrame = keyframes[`${i}`]
    if (!foundFrame) {
      foundFrame = prevFoundFrame
    }
    if (!foundFrame) {
      throw new Error('Keyframes not defined for animation.')
    }
    spreadedKeyframes[i] = foundFrame
    prevFoundFrame = foundFrame
  }

  const currentFrame = spreadedKeyframes[currentFrameNum]
  const prevFrame = spreadedKeyframes[prevFrameNum]

  const {position: prevPosition, model: prevModel, rotate: prevRotate = 0, scale: prevScale = '1'} = prevFrame
  const {position: currPosition, model: currModel, scale: currScale = '1', rotate: currRotate = 0} = currentFrame

  const currFrameTimeline = currLoopTimeline - frameDuration * (currentFrameNum - 1)
  let k = currFrameTimeline / frameDuration
  if (k > 1) {
    if (k > 1.1) {
      throw new Error('Coefficient k should be less then 1')
    }
    k = 1
  }

  const x = prevPosition.x + (currPosition.x - prevPosition.x) * k
  const y = prevPosition.y + (currPosition.y - prevPosition.y) * k

  const prevPositionIsVisible = isVisible({x: prevPosition.x, y: prevPosition.y}, ratio)

  const parseScale = (scale) => {
    let scaleX = 1
    let scaleY = 1
    const scaleParts = scale === '1' ? [] : scale.split(',')
    if (scaleParts.length > 1) {
      ({'0': scaleX, '1': scaleY} = scaleParts)
      scaleX = +scaleX
      scaleY = +scaleY
    }
    return {scaleX, scaleY}
  }

  const {scaleX: currScaleX, scaleY: currScaleY} = parseScale(currScale)
  let rotate = currRotate
  let scaleX = currScaleX
  let scaleY = currScaleY

  if (prevModel === currModel) {
    const {scaleX: prevScaleX, scaleY: prevScaleY} = parseScale(prevScale)
    const isPositive = (v) => v === 0 || v / Math.abs(v) > 0
    if ((currScaleX === prevScaleX || isPositive(currScaleX) === isPositive(prevScaleX))
      && ((currScaleY === prevScaleY)  || isPositive(currScaleY) === isPositive(prevScaleY))) {
      rotate = prevRotate + (currRotate - prevRotate) * k
      scaleX = prevScaleX + (currScaleX - prevScaleX) * k
      scaleY = prevScaleY + (currScaleY - prevScaleY) * k
    }
  }

  return {model: currModel, x, y, scaleX, scaleY, rotate, visibility: prevPositionIsVisible}
}

export function coordinatesToPosition({x, y}, {width, height}, ratio = 10000) {
  return {x: Math.round(x / ratio * width), y: Math.round(y / ratio * height)}
}

export function isVisible({x, y}, ratio) {
  if (ratio + x <= 0) return false
  if (x >= ratio) return false
  if (y + ratio <= 0) return false
  if (y >= ratio) return false
  return true
}

export function calcModelActiveFrameConfig(config, timeline) {
  const totalKeyframeNums = Object.keys(config.keyframes).length
  if (totalKeyframeNums > 1 && config.duration <= 0) {
    console.error(`model.config.duration must be greater then 0. For model ${config.id} this assertion is wrong`)
  }
  if (totalKeyframeNums === 1 || timeline === 0 || !config.duration) {
    return config.keyframes['0']
  }
  const keyframeDuration = config.duration / totalKeyframeNums
  const loopNum = Math.floor(timeline / config.duration)
  const keyframeNum = Math.floor((timeline - loopNum * config.duration) / keyframeDuration)
  if (keyframeNum >= totalKeyframeNums) {
    console.error('keyframeNum >= totalKeyframeNums')
  }
  return config.keyframes[`${keyframeNum}`]
}

export function calcTopRightCorner({centerX, centerY}, {width, height}, {xFactor, yFactor}) {
  const top = centerY - height * yFactor / 2
  const left = centerX - width * xFactor / 2
  return {left, top}
}