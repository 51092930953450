// For handling array of verbs

import * as actionTypes from '../actions/actionTypes'

export const verbsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_VERBS_SUCCESS:
          return action.verbs;
    default:
          return state;
  }
};


// For handling a single verb
export const verbReducer = (state = [], action) => {
  switch (action.type) {
    // Handle fetch by Id
    case actionTypes.FETCH_VERB_BY_ID_SUCCESS:
      return action.verb;
    default:
      return state;
  }
};

