import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Tab, Tabs, Sonnet } from 'react-bootstrap'; //for lyrics tabs

const Song3 = () => {

	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
		);  //check if mobile device

	if(mobile)
	{
		return (
			<div>
				<div className="audio-attribution">
					<p></p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/11 kinihithowan.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div>
						<Tabs defaultActiveKey="sro" id="uncontrolled-tab-example">
							<Tab eventKey="sro" title="SRO" default>
								<div className="card">
									<div className="card-body">
										<h4 className="audio-text-title card-title">kinīhithowān</h4>
										<div className="audio-text card-text">
											<div>mostos, mostos</div>
											<div>kinīhithowān</div>
											<div>apisīs, apisīs</div>
											<div>ōmisi nititwān</div>

											<div>mō, mō, mō</div>

											<div>kohkōs, kohkōs</div>
											<div>kinīhithowān</div>
											<div>apisīs, apisīs</div>
											<div>ōmisi nititwān</div>

											<div>kwa, kwa, kwa</div>

											<div>misti-misihthīw, misti-misihthīw</div>
											<div>kinīhithowān</div>
											<div>apisīs, apisīs</div>
											<div>ōmisi nititwān</div>
											 
											<div>pa, pa, pa, pa</div>

											<div>mistatim, mistatim</div>
											<div>kinīhithowān</div>
											<div>apisīs, apisīs</div>
											<div>ōmisi nititwān</div>
											 
											<div>ī, ī, ī</div>
											 
											<div>kinosīw, kinosīw</div>
											<div>kinīhithowān</div>
											<div>apisīs, apisīs</div>
											<div>ōmisi nititwān</div>
											 
											<div>plōp, plōp, plop</div>





										</div>
									</div>
								</div>
							</Tab>
							<Tab eventKey="syllabics" title="Syllabics">
								<div className="card">
									<div className="card-body">
										<h4 className="audio-text-title card-title">ᑭᓀᐦᐃᖪᐚᐣ</h4>
										<div className="audio-text card-text">

											<div>ᒧᐢᑐᐢ ᒧᐢᑐᐢ</div>
											<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
											<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
											<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>

											<div>ᒨ ᒨ ᒨ</div>

											<div>ᑯᐦᑰᐢ ᑯᐦᑰᐢ</div>
											<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
											<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
											<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>

											<div>ᑿ ᑿ ᑿ</div>

											<div>ᒥᐢᑎᒥᓯᐦᖧᐤ ᒥᐢᑎᒥᓯᐦᖧᐤ</div>
											<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
											<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
											<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>
											 
											<div>ᐸ ᐸ ᐸ ᐸ</div>

											<div>ᒥᐢᑕᑎᒼ ᒥᐢᑕᑎᒼ</div>
											<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
											<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
											<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>
											 
											<div>ᐁ ᐁ ᐁ</div>
											 
											<div>ᑭᓄᓭᐤ ᑭᓄᓭᐤ</div>
											<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
											<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
											<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>
											 
											<div>ᓬᐆᑊ ᑊᓬᐆᑊ ᑊᓬᐅᑊ</div>


										</div>
									</div>
								</div>
							</Tab>
							<Tab eventKey="english" title="English" disabled>
								<div className="card">
									<div className="card-body">
										<h4 className="audio-text-title card-title">Do You Speak Cree?</h4>
										<div className="audio-text card-text">
											<div>Cow, Cow</div>
											<div>Do you speak Cree</div>
											<div>A little bit, A little bit</div>
											<div>This is what I say</div>
											<div>Mo, mo, mo</div>
											<div>Pig, Pig</div>
											<div>Do you speak Cree</div>
											<div>A little bit, a little bit</div>
											<div>This is what I say</div>
											<div>Kwa, kwa, kwa</div>
											<div>Turkey, Turkey</div>
											<div>Do you speak Cree</div>
											<div>A little bit, a little bit</div>
											<div>This is what I say</div>
											<div>Pa, pa, pa, pa</div>
											<div>Horse, horse</div>
											<div>Do you speak Cree</div>
											<div>A little bit, a little bit</div>
											<div>This is what I say</div>
											<div>Ī, ī, ī</div>
											<div>Fish, fish</div>
											<div>Do you speak Cree</div>
											<div>A little bit, a little bit</div>
											<div>This is what I say</div>
											<div>plōp, plōp, plōp</div>

										</div>
									</div>
								</div>
							</Tab>
						</Tabs>
					</div>
				</div>

			</div>
		);
	}
	else
	{
		return (
			<div>
				<div className="audio-attribution">
					<p></p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/11 kinihithowan.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">kinīhithowān</h4>
									<div className="audio-text card-text">
										<div>mostos, mostos</div>
										<div>kinīhithowān</div>
										<div>apisīs, apisīs</div>
										<div>ōmisi nititwān</div>

										<div>mō, mō, mō</div>

										<div>kohkōs, kohkōs</div>
										<div>kinīhithowān</div>
										<div>apisīs, apisīs</div>
										<div>ōmisi nititwān</div>

										<div>kwa, kwa, kwa</div>


										<div>misti-misihthīw, misti-misihthīw</div>
										<div>kinīhithowān</div>
										<div>apisīs, apisīs</div>
										<div>ōmisi nititwān</div>
										 
										<div>pa, pa, pa, pa</div>

										<div>mistatim, mistatim</div>
										<div>kinīhithowān</div>
										<div>apisīs, apisīs</div>
										<div>ōmisi nititwān</div>
										 
										<div>ī, ī, ī</div>
										 
										<div>kinosīw, kinosīw</div>
										<div>kinīhithowān</div>
										<div>apisīs, apisīs</div>
										<div>ōmisi nititwān</div>
										 
										<div>plōp, plōp, plop</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">ᑭᓀᐦᐃᖪᐚᐣ</h4>
									<div className="audio-text card-text">
										<div>ᒧᐢᑐᐢ ᒧᐢᑐᐢ</div>
										<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
										<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
										<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>

										<div>ᒨ ᒨ ᒨ</div>

										<div>ᑯᐦᑰᐢ ᑯᐦᑰᐢ</div>
										<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
										<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
										<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>

										<div>ᑿ ᑿ ᑿ</div>

										<div>ᒥᐢᑎᒥᓯᐦᖧᐤ ᒥᐢᑎᒥᓯᐦᖧᐤ</div>
										<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
										<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
										<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>
										 
										<div>ᐸ ᐸ ᐸ ᐸ</div>

										<div>ᒥᐢᑕᑎᒼ ᒥᐢᑕᑎᒼ</div>
										<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
										<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
										<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>
										 
										<div>ᐁ ᐁ ᐁ</div>
										 
										<div>ᑭᓄᓭᐤ ᑭᓄᓭᐤ</div>
										<div>ᑭᓀᐦᐃᖪᐚᐣ</div>
										<div>ᐊᐱᓭᐢ ᐊᐱᓭᐢ</div>
										<div>ᐆᒥᓯ ᓂᑎᑤᐣ</div>
										 
										<div>ᓬᐆᑊ ᑊᓬᐆᑊ ᑊᓬᐅᑊ</div>


									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">Do You Speak Cree?</h4>
									<div className="audio-text card-text">

										<div>Cow, Cow</div>
										<div>Do you speak Cree</div>
										<div>A little bit, A little bit</div>
										<div>This is what I say</div>
										<div>Mo, mo, mo</div>
										<div>Pig, Pig</div>
										<div>Do you speak Cree</div>
										<div>A little bit, a little bit</div>
										<div>This is what I say</div>
										<div>Kwa, kwa, kwa</div>
										<div>Turkey, Turkey</div>
										<div>Do you speak Cree</div>
										<div>A little bit, a little bit</div>
										<div>This is what I say</div>
										<div>Pa, pa, pa, pa</div>
										<div>Horse, horse</div>
										<div>Do you speak Cree</div>
										<div>A little bit, a little bit</div>
										<div>This is what I say</div>
										<div>Ī, ī, ī</div>
										<div>Fish, fish</div>
										<div>Do you speak Cree</div>
										<div>A little bit, a little bit</div>
										<div>This is what I say</div>
										<div>plōp, plōp, plōp</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
};

export default Song3;
