'use strict'

import React from 'react';
import PropTypes from 'prop-types'
import AnimatedModel from './AnimatedModel'

class AnimatedSubject extends React./*Pure*/Component {

  onClick = () => this.props.onClick(this.props.id)

  onMouseEnter = () => this.props.onHover(this.props.id, this.props.pause)

  onMouseLeave = () => this.props.onHover(this.props.id, false)

  renderModels = () => {
    const {
      id, models, activeModelId, hidden, scaleX, scaleY, rotate, centerX, centerY, xFactor, yFactor,
      onTouchPauseStart, onTouchPauseEnd
    } = this.props

    return models.map(item => {
      const key = `${id}_${item.id}`
      return (
        <AnimatedModel
          key={key}
          id={key}
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onTouchPauseStart={onTouchPauseStart}
          onTouchPauseEnd={onTouchPauseEnd}
          config={item}
          active={!hidden && activeModelId && activeModelId === item.id}
          centerX={centerX}
          centerY={centerY}
          subjectScaleX={scaleX}
          subjectScaleY={scaleY}
          subjectRotate={rotate}
          xFactor={xFactor}
          yFactor={yFactor}
        />
      )
    })
  }

  render() {
    return this.renderModels()
  }
}

AnimatedSubject.propTypes = {
  id: PropTypes.string.isRequired,
  models: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    keyframes: PropTypes.object.isRequired,
    duration: PropTypes.number.isRequired,
  })),
  activeModelId: PropTypes.string,
  pause: PropTypes.bool.isRequired,
  centerX: PropTypes.number.isRequired,
  centerY: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
  onTouchPauseStart: PropTypes.func.isRequired,
  onTouchPauseEnd: PropTypes.func.isRequired,
  scaleX: PropTypes.number.isRequired,
  scaleY: PropTypes.number.isRequired,
  hidden: PropTypes.bool.isRequired,
  xFactor: PropTypes.number.isRequired,
  yFactor: PropTypes.number.isRequired,
}

AnimatedSubject.defaultProps = {}

export default AnimatedSubject
