import React from 'react';
import '../../main.css';
import './SyllabicMatchingGameType1.css';
import * as chActions from "../../../actions/chActions";


import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import ProgressBar from "../../ProgressBar/ProgressBar";
import NewProgressBar from "../../ProgressBar/newProgressBar";
import Axios from 'axios';

import {Button} from "react-bootstrap"; //statover button

class SyllabicMatchingGameType1 extends React.Component {

    constructor(props) {
        super(props);
        let w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        let initialSmallPos = this.shuffle(POSITION_SMALL);
        let initialBigPos = this.shuffle(POSITION);
        let position = (w < 768)? initialSmallPos: initialBigPos;
        this.state = {
            page: 0,
            result: -1,
            position: position,
            resultArray: [],
            totalGameArray : [],
            initialSmallPos: initialSmallPos,
            initialBigPos: initialBigPos,
            gameNumber:  props.match.params.val,
            main_menu: null,
        };
        this.playAudio = this.playAudio.bind(this);
        this.resetPage = this.resetPage.bind(this);

    }


    playAudio(path) {
        let audio = new Audio(path);
        audio.play();
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.data) {
            nextProps.fetchData();
        }

        if (nextProps.data !== this.props.data) {
            
            let w = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;
            let initialSmallPos = this.shuffle(POSITION_SMALL);
            let initialBigPos = this.shuffle(POSITION);
            let position = (w < 768)? initialSmallPos: initialBigPos;
            this.setState({
                page: 0,
                result: -1,
                position: position,
                resultArray: [],
                totalGameArray : [],
                initialSmallPos: initialSmallPos,
                initialBigPos: initialBigPos,
                gameNumber:  nextProps.match.params.val

            });
        }
    }
    componentWillMount () {


    };

    shouldComponentUpdate(nextProps, nextState) {
        return (!this.props.data
            || !nextProps.data
            || nextProps.data.title1 !== this.props.data.title1
            || nextState.page !== this.state.page
            || nextState.result !== this.state.result
            || nextState.position !== this.state.position
            || nextState.gameNumber !== this.state.gameNumber)
    }

    componentDidMount() {
        if (!this.props.data) {
            this.props.fetchData();
        }
        window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        let w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        if (w < 768){
            this.setState( {
                position: this.state.initialSmallPos
            });
        } else {
            this.setState( {
                position: this.state.initialBigPos
            });
        }
    }
    checkResult(winArray){
        let resultArray = this.state.resultArray;
        let result = this.arraysEqual(resultArray, winArray) ? 1 : 0 ;
        const playAudio = (audio) => this.playAudio.bind(null, `/api/get.php?file=${audio}`);
        result ? (playAudio("media/syllabic_matching_game/audio/2_firstSound01.mp3"))(): (playAudio("media/syllabic_matching_game/audio/1_secondSound01.mp3"))();
        this.setState({result: result});


    }
    shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;

        while (0 !== currentIndex) {

            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    arraysEqual(a1,a2) {
        let result = true;
        for (let i=0 ; i< 6; i++){
            // if (!a1[i]){
            //     a1[i] = '';
            // }
            // if (!a2[i]){
            //     a2[i] = '';
            // }
            result = result && a1[i] === a2[i];
        }

        return result;
    }

    onDragOver(ev) {
        ev.preventDefault();

    }
    onDrag(ev) {
        $(ev.target).css({visibility: 'hidden'});
    }
    onDrop(ev) {
        ev.preventDefault();
        let letter = ev.dataTransfer.getData("letter");
        let array = this.state.resultArray.slice();
        array[ev.target.id] = letter;
        this.setState({resultArray: array})
    }

    onDragStart(ev) {
        ev.dataTransfer.setData("letter", ev.target.id);
        let items = this.state.resultArray.slice();
        for(let i in items){
            if (items.hasOwnProperty(i)){
               if ( items[i]  ===  ev.target.id){
                      items[i] = '';
               }
            }
        }
        this.setState({resultArray: items})
    }

    onDragEnd(event) {
        $(event.target).css({visibility: 'visible'});
        let offsetX = $(event.target).parent().offset().left + (parseFloat($(event.target).css('width'))/2);
        let offsetY = $(event.target).parent().offset().top +(parseFloat($(event.target).css('height'))/2);
        let left = event.pageX - offsetX ;
        let top = event.pageY - offsetY ;
           let id = parseInt(event.target.getAttribute('data-id'));
           let position = this.state.position.slice();
           position[id] = {left: left, top: top}
          this.setState({position: position})

    }

    reset(){
        let w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        let position = (w < 768)? this.state.initialSmallPos : this.state.initialBigPos;
        this.setState({
                result: -1,
                position: position,
                resultArray: []
        });
    }
    resetShuffle(){
        let w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        let initialSmallPos = this.shuffle(POSITION_SMALL);
        let initialBigPos = this.shuffle(POSITION);
        let position = (w < 768)? initialSmallPos: initialBigPos;
        this.setState({
            result: -1,
            position: position,
            initialSmallPos: initialSmallPos,
            initialBigPos: initialBigPos,
            resultArray: []
        });
    }
    nextPage(dataSetL, winArray){
        const data = this.props.data;
        let resultArray = this.state.resultArray;
        let result = this.arraysEqual(resultArray, winArray) ? 1 : 0 ;
         let totalGameArray = this.state.totalGameArray.slice();
         totalGameArray.push(result);
        this.resetShuffle();
        let page =  this.state.page + 1;
        if (page > dataSetL-1){
            const sum = totalGameArray.reduce(this.add);
            const length = totalGameArray.length;
             let result = Math.floor((sum/length)*100);
             let str;
             if (result < 90){
                 str = `You got only ${result}% of the answers right. ${data.resultBad}`;
             } else {
                 str = `You got ${result}% of the answers right. ${data.resultGood}`;
             }

            page = {'result': str};

        }
        this.setState({
            page: page,
            totalGameArray: totalGameArray
        });
    }

    add(accumulator, a) {
        return accumulator + a;
    }

    resetPage()
    {
        //reset page button
        this.setState({
            page: 0,
        });
    }
    render() {
        const data = this.props.data;
        const gameNumber = this.state.gameNumber;

        if (data)
        {
            const main_menu = data.menu;
            if ( typeof(this.state.page) === 'object')
            {
                return(
                    /*  game over page   */
                    <div className='c-wrapper'>
                        <h3>{data.title1}</h3>
                        <div className='c-container'>
                            <div className='c-content'>
                                <div className='matching-game2'>
                                    <div style={{margin: '10%'}}>
                                        <h3 className="align-middle">{this.state.page['result']}</h3>
                                        <Button variant="primary" onClick={(e) => this.resetPage()}>Start Over?</Button>{' '}
                                    </div>
                                    <h5 className="total-result-tip">Thank you for participating.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else
            {
                const dataSet = data.data_set;
                const dataSetL = dataSet.length;
                const selectedPage = this.state.page;
                const gameData = dataSet[selectedPage].data;
                const pages = [...Array(dataSetL).keys()];
                const winArray =[];
                let categories = '';
                let totalString = '';
                    for (let i=0 ; i< 6; i++){
                        if (!gameData[i]){
                            gameData[i] = '';
                        }
                    }
                gameData.map((b, i)=> {
                    winArray[i] = (b.title2);
                    totalString +=  b.title1 ? b.title1 : '';
                });
                if (!dataSetL) {
                    return <div>No data...</div>;
                }

                categories = main_menu && main_menu.map((item, i) => {
                  if(i+1 == gameNumber)
                  {
                    return <div key={i}   className="tab-slider--trigger active"><Link style={{color :'#fff',textDecoration: 'none'}} to={item.link}>{item.title}</Link></div>;
                  }
                  else
                  {
                    return <div key={i}   className="tab-slider--trigger "><Link style={{textDecoration: 'none',color:'black'}} to={item.link}>{item.title}</Link></div>;
                  }
                });



                return (

                    <div className='c-wrapper'>
                         <div>
                          <div className="page-title">
                          <h1 className="uc-first">{data.title1}</h1>
                        </div>
                        <div className="tab-slider--nav">
                          <ul className="tab-slider--tabs">{categories}</ul>
                        </div>
                      </div>
                        <div className='c-container'>
                            <div className='c-content'>
                                <div className='matching-game2'>
                                    <div className="description">
                                        <h4>{data.title2}</h4>
                                        <p>{data.description.toUpperCase()}</p>
                                    </div>
                                    <div className="next" onClick={(e) => this.nextPage(dataSetL, winArray)}>
                                        <span>NEXT</span>
                                        <div className="next-button">
                                            <div className="next-button-inner"/>
                                        </div>
                                    </div>
                                    <div className="result">
                                        { this.state.result === -1  &&
                                        <div></div>}
                                        { this.state.result === 0  &&
                                        <div>Sorry, please try again.</div>}
                                        { this.state.result === 1 &&
                                        <div>Yeah, you got it right.</div>}
                                    </div>


                                    <div className="elements">
                                        <div className="elements-title1">Syllabic Box</div>
                                        <div className="elements-title2">{totalString}</div>

                                        {gameData.map((b, i) =>
                                            <div className="element" draggable="true"
                                                 onDragStart={(event) =>this.onDragStart(event)}
                                                 onDragEnd={(event) => this.onDragEnd(event) }
                                                 onDrag={(event) => this.onDrag(event) }
                                                 key={i} id={b.title2}
                                                 data-id = {i}
                                                 style={this.state.position[i]}
                                            >
                                                {b.title2}
                                            </div>
                                        )}
                                    </div>
                                    <div className="actions">
                                        <div className="submit" onClick={(e) => this.checkResult(winArray)}>SUBMIT</div>
                                        <div className="reset" onClick={(e) => this.reset()}>RESET</div>

                                    </div>
                                    <div className="pagintaion">{
                                        pages.map((b,i)=>{
                                            if (i === this.state.page ){
                                               return <div key={i} className="current-page page" >{i+1}</div>
                                            } else {
                                                return <div className="page"  key={i}>{i+1}</div>
                                            }

                                        })
                                    }
                                    </div>
                                    <div className="clearfix"/>
                                    <div className="game-box">
                                        {gameData.map((b, i) =>
                                            <div className="box-element"  key={i}>
                                                <div className="box-title">{b.title1}</div>
                                                <div className="box" id={i}
                                                     onDragOver={(event) => this.onDragOver(event)}
                                                     onDrop={(event) => this.onDrop(event)}
                                                >

                                                </div>
                                            </div>
                                        )}
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                );
            }


        } else {
            return (
                <NewProgressBar/>
            );
        }
    }
}

const CATEGORY = "syllabic_matching_game";

const POSITION_SMALL = [
    {
        top: '74px',
        left: '126px'
    },
    {
        top: '145px',
        left: '93px'
    },
    {
        top: '36px',
        left: '40px'
    },
    {
        top: '15px',
        left: '126px'
    },
    {
        top: '132px',
        left: '207px'
    },
    {
        top: '42px',
        left: '194px'
    },
    {
        top: '122px',
        left: '26px'
    },
];

const POSITION = [
    {
        top: '45px',
        left: '68px'
    },
    {
        top: '13px',
        left: '140px'
    },
    {
        top: '44px',
        left: '211px'
    },
    {
        top: '20px',
        left: '289px'
    },
    {
        top: '54px',
        left: '376px'
    },
    {
        top: '45px',
        left: '444px'
    },
    {
        top: '61px',
        left: '496px'
    },
];


const mapStateToProps = (state, ownProps) => {
    let number =  ownProps.match.params.val;
    return {
        data: state.ch[CATEGORY + number]
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData() {
        let number =  ownProps.match.params.val;
        dispatch(chActions.fetchCH(CATEGORY + number));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SyllabicMatchingGameType1);
