import React from 'react';
import { Link } from 'react-router-dom';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Home = () => {
	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
		);  //check if mobile
	var logo, mission_statement, slider;
	if(!mobile)	//desktop
	{
		logo = (
			<div className="col-md-4" id="main-logo">
				{/*<img src="/api/get.php?file=media/Home/logo/logo180x180.png" alt="Learn Cree Logo" />*/}
			</div>
		)

		mission_statement = (
			<div className="col-md-12" id="mission-statement">
				<p>The Lac La Ronge Indian Band, N-12 Education Program will provide an education that meets the unique needs of our children; that emphasizes the four goals of pride, culture, skills, and values; and that helps our children achieve academic excellence and proficiency with the Cree Language.</p>
			</div>
		)

		slider = (
			<div className="col-md-12">
				<AutoplaySlider
					play={true}
					cancelOnInteraction={false} // should stop playing on user interaction
					interval={6000}
				>
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/bpes01.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/bpes02.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/bpes03.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/cmrs01.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/cmrs02.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/cmrs03.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/smvs01.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/smvs02.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/smvs03.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/srs01.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/srs02.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/srs03.jpg' />

				</AutoplaySlider><br/><br/>
			</div>
		)
	}
	else
	{
		mission_statement = (
			<div className="col-md-8" id="mission-statement">
				<p>The Lac La Ronge Indian Band, N-12 Education Program will provide an education that meets the unique needs of our children; that emphasizes the four goals of pride, culture, skills, and values; and that helps our children achieve academic excellence and proficiency with the Cree Language.</p>
			</div>
		)

		slider = (
			<div className="col-md-12">
				<AutoplaySlider
					play={true}
					cancelOnInteraction={false} // should stop playing on user interaction
					interval={6000}
				>
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/bpes01_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/bpes02_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/bpes03_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/cmrs01_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/cmrs02_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/cmrs03_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/smvs01_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/smvs02_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/smvs03_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/srs01_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/srs02_low.jpg' />
					<div data-src='/api/get.php?file=media/Home/slider/2021-06/srs03_low.jpg' />
				</AutoplaySlider><br/><br/>
			</div>
		)
	}
  return (
    <div className="front-page">
	<div className="container-fluid">
	    <div className="row">
			{slider}
			{mission_statement}
			{logo}
		</div>
	</div>
    <section style={{marginTop: "20px", marginBottom: "20px"}}>
	<div className="container-fluid">
		<div className="row">
			<div className="col-md-4">
				<div className="card">
					<Link to="/activities" style={{textDecoration: "none"}}>
						<div className="card-img-container">
							<img src="/api/get.php?file=media/Home/animal_homes.jpg" className="card-img-top" alt="..."/>
						</div>
						<div className="card-block">
							<h5 className="card-title">Activities</h5>
							<p className="card-text">
								<span>Interactive activities to help you learn Cree.</span>
							</p>
						</div>
					</Link>
				</div>
			</div>
			<div className="col-md-4">
				<div className="card">
					<Link to="/audio" style={{textDecoration: "none"}}>
						<div className="card-img-container">
							<img src="/api/get.php?file=media/Home/audio_openclipart_music_note_transparent_background-17.png" className="card-img-top" alt="..."/>
						</div>
						<div className="card-block">
							<h5 className="card-title">Songs and Audio</h5>
							<p className="card-text">
								<span>Listen to songs and audio in Cree.</span>
								<span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
							</p>
						</div>
					</Link>
				</div>
			</div>
			<div className="col-md-4">
				<div className="card">
					<a href="https://www.youtube.com/channel/UCCaF1WwP8klED4-3yeYrQsA" style={{textDecoration: "none"}}>
						<div className="card-img-container">
							<img src="/api/get.php?file=media/Home/video_openclipart_232032.jpg" className="card-img-top" alt="..."/>
						</div>
						<div className="card-block">
							<h5 className="card-title">Videos</h5>
							<p className="card-text">
								<span>Instructional videos for learning Cree.</span>
								<span> &nbsp; &nbsp; (on Facebook)</span>
							</p>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
    </section>
    </div>
  );
};

export default Home;
