import React from 'react';
import '../main.css';
import './SyllabicChart.css';

class SyllabicChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.playAudio = this.playAudio.bind(this);
	this.syllabics = require('./syllabic_chart.json')
    }

    playAudio(path) {
        let audio = new Audio(path);
        audio.play();
    }

  render () {
	let data_set = this.syllabics.data_set[0].data;
	 const playAudio = (audio) => this.playAudio.bind(null, `/api/get.php?file=${audio}`);

            return (
              <div className='c-wrapper'>
		<h3 className='main-title-container'>{this.syllabics.title1}</h3>
                <div className='c-container'>
                  <div className='c-content'>
                      <div className='inner-container'>
                          <div className='chart'>
                              <div className="center">
					{data_set.map((row) => {
						return row.map((char, index) => {
							if (Object.keys(char).length === 0){
								return <div key={index} className="char"></div>
							}
							
							if (char.hasOwnProperty('audio')){
								return (
								  <div key={index} className="char" onClick={playAudio(char.audio)}>
								      <p>{char.title2}</p>
								      <p>{char.title1}</p>
								  </div>
								)
							}
							else {
								return (
								  <div key={index} className="char">
								      <p>{char.title2}</p>
								      <p>{char.title1}</p>
								  </div>
								)
								
							}

						})
					}
					)}
			      </div>
			</div>
			</div>
			</div>
		</div>
		</div>


	
	)	

  }
}
export default SyllabicChart;
