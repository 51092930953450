import React from "react";
import "./styles.css";
import { connect } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
  }
  static defaultProps = {
    data: {
      file: 0,
      from: 10
    }
  };
  /*value={`${parseInt(((value/maxValue)*100))}`} this.props.data.from  maxValue={maxValue}*/
  render() {
    let value = this.props.data.file;
    let maxValue = this.props.data.from;
    let percentage = parseInt((value / maxValue) * 100);

    return (
      <div className="circularProgressbarWrapper">
        <div className="circularProgressbardiv">
          <p>
            <b>Loading activity</b>
          </p>
          <CircularProgressbar
            value={percentage}
            maxValue="100"
            text={`${percentage}%`}
            styles={buildStyles({
              pathTransition: percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
	      pathColor: '#f88', textColor: '#f88'
            })}
          />
        </div>
      </div>
    );
  }
}

//<CircularProgressbar value={count} maxValue={num_files} text={`${ Math.ceil( count/num_files*100 )}%`} styles={buildStyles({pathColor: '#f88', textColor: '#f88'})} />


const CATEGORY = "loading";
const mapStateToProps = (state, ownProps) => {
  let data = {};
  switch (ownProps.category) {
    case "cha":
      data = state.cha.scenesData[CATEGORY];
      break;
    default:
      data = state.ch[CATEGORY];
  }
  return { data };
};

export default connect(mapStateToProps)(ProgressBar);
