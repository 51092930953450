'use strict'

import React from 'react';
import PropTypes from 'prop-types'
import {calcModelActiveFrameConfig, calcTopRightCorner} from './calculateAnimation';

class AnimatedModel extends React./*Pure*/Component  {
  constructor(props) {
    super(props)
    this.modelActivatedTime = 0;
    const activeFrameConfig = this.calcActiveFrameConfig(props);
    this.state = {hovered: false, activeFrameConfig};
  }

  componentDidMount() {
    if (!this.modelActivatedTime && this.props.active) {
      this.modelActivatedTime = Date.now();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.resumeAnimationTimer)
    if (this.resumeAnimation) {
      this.resumeAnimation();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      this.modelActivatedTime = 0;
    }
    const activeFrameConfig = this.calcActiveFrameConfig(nextProps)
    if (this.state.activeFrameConfig !== activeFrameConfig) {
      this.setState({activeFrameConfig});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.modelActivatedTime && this.props.active) {
      this.modelActivatedTime = Date.now();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {centerX, centerY, subjectScale, subjectRotate, active, xFactor, yFactor} = this.props;
    const {
      centerX: _centerX, centerY: _centerY, subjectScale: _subjectScale, subjectRotate: _subjectRotate, active: _active,
      xFactor: _xFactor, yFactor: _yFactor
    } = nextProps;
    if (_centerX !== centerX || _centerY !== centerY || subjectRotate !== _subjectRotate
      || subjectScale !== _subjectScale || active !== _active || xFactor !== _xFactor || yFactor !== _yFactor) {
      return true;
    }
    if (this.state.hovered !== nextState.hovered ||
       this.state.activeFrameConfig !== nextState.activeFrameConfig) {
      return true;
    }
    return false;
  }

  onMouseEnter = () => {
    this.setState({hovered: true});
    this.props.onMouseEnter();
  }

  onMouseLeave = () => {
    this.setState({hovered: false});
    this.props.onMouseLeave();
    clearTimeout(this.resumeAnimationTimer);
  }

  onTouchStart = () => {
    clearTimeout(this.resumeAnimationTimer);
    this.setState({hovered: true});
    this.props.onMouseEnter();
  }

  onTouchEnd = () => {//return
    const id = Math.random();
    this.props.onTouchPauseStart(id);
    this.resumeAnimation = () => {
      this.setState({hovered: false});
      this.props.onTouchPauseEnd(id);
    }
    clearTimeout(this.resumeAnimationTimer);
    this.resumeAnimationTimer = setTimeout(() => this.resumeAnimation(), 2000);
  }

  calcActiveFrameConfig = (props) => {
    const {config, active} = props;
    if (!active) {
      return
    }
    const timeline = this.modelActivatedTime ? Date.now() - this.modelActivatedTime : 0;
    const activeFrameConfig = calcModelActiveFrameConfig(config, timeline);
    return activeFrameConfig;
  }

  getKeyframeImage(config, keyframe) {
    return config.images.find(image => image.id === keyframe.image);
  }

  calcAppearanceSize(config) {
    const {hovered} = this.state;
    const {scale} = config.hover;

    if (hovered && scale) {
      const {subjectScaleX, subjectScaleY} = scale;

      if (subjectScaleX && subjectScaleY) {
        return {subjectScaleX, subjectScaleY};
      }
    }

    return null;
  }

  calcStrokeSize(config) {
    const {width, height} = config.hover.stroke;
    if (width && height) {
      return {width, height};
    }
    let maxImageWidth = 0;
    let maxImageHeight = 0;
    for (const frameKey in config.keyframes) {
      const keyframe = config.keyframes[frameKey];
      const image = this.getKeyframeImage(config, keyframe);
      const {width: imageWidth, height: imageHeight, yOffset = 0, xOffset = 0} = image;
      const fullImageWidth = imageWidth + Math.abs(xOffset) * 2;
      const fullImageHeight = imageHeight + Math.abs(yOffset) * 2;
      maxImageWidth = maxImageWidth < fullImageWidth ? fullImageWidth : maxImageWidth;
      maxImageHeight = maxImageHeight < fullImageHeight ? fullImageHeight : maxImageHeight;
    }
    const padding = config.hover.stroke.padding || 0;
    return {width: width || maxImageWidth + padding * 2, height: height || maxImageHeight + padding * 2};
  }

  calcImgPositionInStroke(imgConfig, strokeSize) {
    const {width: imageWidth, height: imageHeight, yOffset = 0, xOffset = 0} = imgConfig;
    const {width: strokeWidth, height: strokeHeight} = strokeSize;

    const left = (strokeWidth - imageWidth) / 2 + xOffset;
    const top = (strokeHeight - imageHeight) / 2 + yOffset;
    return {left, top};
  }

  getModelImagesConfigs(config) {
    const images = new Set();
    for (const frameKey in config.keyframes) {
      const keyframe = config.keyframes[frameKey];
      const image = this.getKeyframeImage(config, keyframe);
      images.add(image);
    }
    return Array.from(images);
  }

  renderAppearance = () => {
    const {id, config, active, xFactor, yFactor} = this.props;

    const images = this.getModelImagesConfigs(config);

    const activeImage = this.state.activeFrameConfig && this.getKeyframeImage(config, this.state.activeFrameConfig);

    const strokeSize = this.calcStrokeSize(config);

    return images.map(image => {
      const isActiveImage = activeImage && activeImage === image;
      const visibility = active && isActiveImage;

      const {scale = 1, rotate = 0, width, height} = image;

      const {left, top} = this.calcImgPositionInStroke(image, strokeSize);

      const style = {
        position: 'absolute',
        left: `${left * xFactor}px`,
        top: `${top * yFactor}px`,
        visibility: visibility ? 'visible' : 'hidden',
        transformOrigin: 'center center',
        transform: `scale(${scale}) rotate(${rotate}deg)`,
        width: `${xFactor * width}px`,
        height: `${xFactor * height}px`
      }

      return (
        <img
          key={image.id}
          src={`/api/get.php?file=${image.url}`}
          style={style}
        />
      )
    })
  }

  renderStroke = ({width, height}, {left, top}) => {
    const {hovered} = this.state;
    if (!hovered) {
      return null;
    }
    const {id, onClick, subjectScale, subjectRotate, xFactor, yFactor, config} = this.props;
    const {borderRadius, boxShadow, zIndex = 100} = config.hover.stroke;

    const style = {
      position: 'absolute',
      zIndex,
      transformOrigin: 'center center',
      transform: `scale(${subjectScale}) rotate(${subjectRotate}deg)`,
      width: `${width * xFactor}px`,
      height: `${height * yFactor}px`,
      left: `${left}px`,
      top: `${top}px`,
      borderRadius,
      boxShadow,
    }
    return (
      <div
        className="modelStroke"
        key={`stroke-${id}`}
        style={style}
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
      />
    )
  }

  render() {
    const {id, config, active, onClick, subjectRotate, centerX, centerY, xFactor, yFactor} = this.props;

    const {width, height} = this.calcStrokeSize(config);

    const {left, top} = calcTopRightCorner({centerX, centerY}, {width, height}, {xFactor, yFactor});

    const {subjectScaleX, subjectScaleY} = this.calcAppearanceSize(config) || {...this.props};

    const style = {
      transform: `scaleX(${subjectScaleX}) scaleY(${subjectScaleY}) rotate(${subjectRotate}deg)`,
      width: `${width * xFactor}px`,
      height: `${height * yFactor}px`,
      transformOrigin: 'center center',
      position: 'absolute',
      left: `${left}px`,
      top: `${top}px`,
      zIndex: config.zIndex || 50,
    }

    return [
      <div
        key={id}
        style={style}
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
        hidden={!active}
      >
        {this.renderAppearance()}
      </div>,
      this.renderStroke({width, height}, {left, top})
    ]
  }
}

AnimatedModel.propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      scale: PropTypes.string,
      rotate: PropTypes.number,
    })).isRequired,
    keyframes: PropTypes.object.isRequired,
    duration: PropTypes.number.isRequired,
    zIndex: PropTypes.number,
    hover: PropTypes.shape({
      stroke: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        padding: PropTypes.number,
        zIndex: PropTypes.number,
        borderRadius: PropTypes.string,
        boxShadow: PropTypes.string
      }).isRequired,
      scale: PropTypes.shape({
        subjectScaleX: PropTypes.number,
        subjectScaleY: PropTypes.number,
        position: PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number
        }),
      }),
    }).isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  centerX: PropTypes.number.isRequired,
  centerY: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onTouchPauseStart: PropTypes.func.isRequired,
  onTouchPauseEnd: PropTypes.func.isRequired,
  subjectScaleX: PropTypes.number.isRequired,
  subjectScaleY: PropTypes.number.isRequired,
  subjectRotate: PropTypes.number,
  active: PropTypes.bool.isRequired,
  xFactor: PropTypes.number.isRequired,
  yFactor: PropTypes.number.isRequired,

}

AnimatedModel.defaultProps = {
  subjectRotate: 0,
}

export default AnimatedModel
