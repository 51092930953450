import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Tab, Tabs, Sonnet } from 'react-bootstrap'; //for lyrics tabs

const Song5 = () => {

	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
		);  //check if mobile device

	if(mobile)
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>Days of week - iyakoni kīsikāwa by Laura Burnouf</p>
				</div>
				<div>
					<AudioPlayer
						// preload="none"
						src="/api/get.php?file=media/cree_audio/audio/07 iyakoni kisikawa.mp3"
						// src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<Tabs defaultActiveKey="sro" id="uncontrolled-tab-example">
						<Tab eventKey="sro" title="SRO" default>
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">iyakoni kīsikāwa</h4>
									<div className="audio-text card-text">
										<div>piyakokīsikāw, nīsokīsikāw, nistokīsikaw,</div>
										<div>niyokīsikāw, niyānanokīsikāw ikwa</div>
										<div>mātināwikīsikāw ispī</div>
										<div>ayamihikīsikāw mīna</div>
										<div>iyakoni kīsikāwa</div>
										<div>iyakoni kīsikāwa</div>

										<div>kā-piyakokīsikāk nikiskinwahamāson</div>
										<div>kā-nīsokīsikāk nohkom nikiyokawāw</div>
										<div>kā-nistokīsikāk niwīcīwāw</div>
										<div>nimosōm ī-nātakwīt</div>
										<div>kā-niyokīsikāk nitatoskān</div>
										<div>kā-niyānanokīsikāk nimītawān</div>
										<div>kā-mātināwikīsikāk nisipwihtān</div>
										<div>otīnahk niwī-itohtān</div>
										<div>kā-ayamihikīsikāk nitāthwīpin</div>
										<div>ikwa mīna ninitawai-ayamihān</div>
										<div>ikwa mīna ninitawai-ayamihān</div>
									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="syllabics" title="Syllabics">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">ᐃᔭᑯᓂ ᑫᓯᑳᐘ</h4>
									<div className="audio-text card-text">
										<div>ᐱᔭᑯᑫᓯᑳᐤ ᓀᓱᑫᓯᑳᐤ ᓂᐢᑐᑫᓯᑲᐤ</div>
										<div>ᓂᔪᑫᓯᑳᐤ ᓂᔮᓇᓄᑫᓯᑳᐤ ᐃᑿ</div>
										<div>ᒫᑎᓈᐏᑫᓯᑳᐤ ᐃᐢᐯ</div>
										<div>ᐊᔭᒥᐦᐃᑫᓯᑳᐤ ᒣᓇ</div>
										<div>ᐃᔭᑯᓂ ᑫᓯᑳᐘ</div>
										<div>ᐃᔭᑯᓂ ᑫᓯᑳᐘ</div>

										<div>ᑳᐱᔭᑯᑫᓯᑳᐠ ᓂᑭᐢᑭᓌᐦᐊᒫᓱᐣ</div>
										<div>ᑳᓀᓱᑫᓯᑳᐠ ᓄᐦᑯᒼ ᓂᑭᔪᑲᐚᐤ</div>
										<div>ᑳᓂᐢᑐᑫᓯᑳᐠ ᓂᐍᒉᐚᐤ</div>
										<div>ᓂᒧᓲᒼ ᐁᓈᑕᑵᐟ</div>
										<div>ᑳᓂᔪᑫᓯᑳᐠ ᓂᑕᑐᐢᑳᐣ</div>
										<div>ᑳᓂᔮᓇᓄᑫᓯᑳᐠ ᓂᒣᑕᐚᐣ</div>
										<div>ᑳᒫᑎᓈᐏᑫᓯᑳᐠ ᓂᓯᐽᐦᑖᐣ</div>
										<div>ᐅᑌᓇᕽ ᓂᐍᐃᑐᐦᑖᐣ</div>
										<div>ᑳᐊᔭᒥᐦᐃᑫᓯᑳᐠ ᓂᑖᙷᐱᐣ</div>
										<div>ᐃᑿ ᒣᓇ ᓂᓂᑕᐘᐃᐊᔭᒥᐦᐋᐣ</div>
										<div>ᐃᑿ ᒣᓇ ᓂᓂᑕᐘᐃᐊᔭᒥᐦᐋᐣ</div>

									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="english" title="English">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">Those are the weekdays</h4>
									<div className="audio-text card-text">
										<div>Monday, Tuesday, Wednesday,</div>
										<div>Thursday, Friday, and Saturday</div>
										<div>Sunday</div>
										<div>Those are the days of the week</div>
										<div>Those are the days of the week</div>
										<div>On Monday, I go to school</div>
										<div>On Tuesday I visit my grandmother</div>
										<div>On Wednesday, I will with</div>
										<div>my grandfather to check snares</div>
										<div>On Thursday I work</div>
										<div>On Friday I play</div>
										<div>On Saturday, I go town</div>
										<div>On Sunday, I rest and go to church</div>
										<div>On Sunday, I rest and go to church</div>


									</div>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>

			</div>
		);
	}
	else
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>Days of week - iyakoni kīsikāwa by Laura Burnouf</p>
				</div>
				<div>
					<AudioPlayer
						// preload="none"
						src="/api/get.php?file=media/cree_audio/audio/07 iyakoni kisikawa.mp3"
						// src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
					/>
				</div>
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">iyakoni kīsikāwa</h4>
									<div className="audio-text card-text">
										<div>piyakokīsikāw, nīsokīsikāw, nistokīsikaw,</div>
										<div>niyokīsikāw, niyānanokīsikāw ikwa</div>
										<div>mātināwikīsikāw ispī</div>
										<div>ayamihikīsikāw mīna</div>
										<div>iyakoni kīsikāwa</div>
										<div>iyakoni kīsikāwa</div>

										<div>kā-piyakokīsikāk nikiskinwahamāson</div>
										<div>kā-nīsokīsikāk nohkom nikiyokawāw</div>
										<div>kā-nistokīsikāk niwīcīwāw</div>
										<div>nimosōm ī-nātakwīt</div>
										<div>kā-niyokīsikāk nitatoskān</div>
										<div>kā-niyānanokīsikāk nimītawān</div>
										<div>kā-mātināwikīsikāk nisipwihtān</div>
										<div>otīnahk niwī-itohtān</div>
										<div>kā-ayamihikīsikāk nitāthwīpin</div>
										<div>ikwa mīna ninitawai-ayamihān</div>
										<div>ikwa mīna ninitawai-ayamihān</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">ᐃᔭᑯᓂ ᑫᓯᑳᐘ</h4>
									<div className="audio-text card-text">
										<div>ᐱᔭᑯᑫᓯᑳᐤ ᓀᓱᑫᓯᑳᐤ ᓂᐢᑐᑫᓯᑲᐤ</div>
										<div>ᓂᔪᑫᓯᑳᐤ ᓂᔮᓇᓄᑫᓯᑳᐤ ᐃᑿ</div>
										<div>ᒫᑎᓈᐏᑫᓯᑳᐤ ᐃᐢᐯ</div>
										<div>ᐊᔭᒥᐦᐃᑫᓯᑳᐤ ᒣᓇ</div>
										<div>ᐃᔭᑯᓂ ᑫᓯᑳᐘ</div>
										<div>ᐃᔭᑯᓂ ᑫᓯᑳᐘ</div>

										<div>ᑳᐱᔭᑯᑫᓯᑳᐠ ᓂᑭᐢᑭᓌᐦᐊᒫᓱᐣ</div>
										<div>ᑳᓀᓱᑫᓯᑳᐠ ᓄᐦᑯᒼ ᓂᑭᔪᑲᐚᐤ</div>
										<div>ᑳᓂᐢᑐᑫᓯᑳᐠ ᓂᐍᒉᐚᐤ</div>
										<div>ᓂᒧᓲᒼ ᐁᓈᑕᑵᐟ</div>
										<div>ᑳᓂᔪᑫᓯᑳᐠ ᓂᑕᑐᐢᑳᐣ</div>
										<div>ᑳᓂᔮᓇᓄᑫᓯᑳᐠ ᓂᒣᑕᐚᐣ</div>
										<div>ᑳᒫᑎᓈᐏᑫᓯᑳᐠ ᓂᓯᐽᐦᑖᐣ</div>
										<div>ᐅᑌᓇᕽ ᓂᐍᐃᑐᐦᑖᐣ</div>
										<div>ᑳᐊᔭᒥᐦᐃᑫᓯᑳᐠ ᓂᑖᙷᐱᐣ</div>
										<div>ᐃᑿ ᒣᓇ ᓂᓂᑕᐘᐃᐊᔭᒥᐦᐋᐣ</div>
										<div>ᐃᑿ ᒣᓇ ᓂᓂᑕᐘᐃᐊᔭᒥᐦᐋᐣ</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">Those are the weekdays</h4>
									<div className="audio-text card-text">
										<div>Monday, Tuesday, Wednesday,</div>
										<div>Thursday, Friday, and Saturday</div>
										<div>Sunday</div>
										<div>Those are the days of the week</div>
										<div>Those are the days of the week</div>
										<div>On Monday, I go to school</div>
										<div>On Tuesday I visit my grandmother</div>
										<div>On Wednesday, I will with</div>
										<div>my grandfather to check snares</div>
										<div>On Thursday I work</div>
										<div>On Friday I play</div>
										<div>On Saturday, I go town</div>
										<div>On Sunday, I rest and go to church</div>
										<div>On Sunday, I rest and go to church</div>


									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
};

export default Song5;
