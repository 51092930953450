import React from 'react';
import '../main.css';
import './Bones.css';
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
import ProgressBar from "../ProgressBar/ProgressBar";
import NewProgressBar from "../ProgressBar/newProgressBar";

class Bones extends React.Component {

    playAudio(path) {
        let originHost = window.location.origin;
        let audio = new Audio(originHost + path);
        audio.load();
        audio.play();
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.data) {
            nextProps.fetchData();
        }

        if (nextProps.data !== this.props.data) {
            console.log('NOT EQUAL SO UPDATING');
        }
    }

    componentDidMount() {
        if (!this.props.data) {
            this.props.fetchData();
        }
    }


    render() {
        const data = this.props.data;
        if (data) {
            const pathToAudio = '/api/get.php?file=media/bones/audio/'
            const playAudio = (audio) => this.playAudio.bind(null, `${pathToAudio}${audio}`);

            return (
                <div className='c-wrapper'>
			<div className="page-title">
			  <h1>Bones</h1>
			</div>
                    <div className='c-container'>
                        <div className='c-content c-content-bones'>
                            <div className="bones">
                                <div>
                                    <div className="left-top-info-block info-block"
                                         onClick={playAudio("maskikanikan.mp3")}>
                                        <p>collar bone</p>
                                        <p>māskikanikan</p>
                                        <p>ᒫᐢᑭᑲᓂᑲᐣ</p>
                                        <div className="left-top-line"></div>
                                    </div>
                                    <div className="left-middle1-info-block info-block"
                                         onClick={playAudio("mitithikan.mp3")}>
                                        <p>shoulder blade</p>
                                        <p>mitithīkan</p>
                                        <p>ᒥᑎᖧᑲᐣ</p>
                                        <div className="left-middle1-line"></div>
                                    </div>
                                    <div className="left-middle2-info-block info-block"
                                         onClick={playAudio("mawikanikan.mp3")}>
                                        <p>spine</p>
                                        <p>māwikanikan</p>
                                        <p>ᒫᐏᑲᓂᑲᐣ</p>
                                        <div className="left-middle2-line"></div>
                                    </div>
                                    <div className="left-bottom-info-block info-block"
                                         onClick={playAudio("mikitik.mp3")}>
                                        <p>knee cap</p>
                                        <p>mikitik</p>
                                        <p>ᒥᑭᑎᐠ</p>
                                        <div className="left-bottom-line"></div>
                                    </div>
                                </div>
                                <img src={`/api/get.php?file=media/bones/images/bones-4_clip.png`}/>
                                <div>
                                    <div className="right-top-info-block info-block"
                                         onClick={playAudio("mistikwanikan.mp3")}>
                                        <p>skull</p>
                                        <p>mistikwānikan</p>
                                        <p>ᒥᐢᑎᒁᓂᑲᐣ</p>
                                        <div className="right-top-line"></div>
                                    </div>
                                    <div className="right-middle1-info-block info-block"
                                         onClick={playAudio("maskikanikan.mp3")}>
                                        <p>breast bone</p>
                                        <p>māskikanikan</p>
                                        <p>ᒫᐢᑭᑲᓂᑲᐣ</p>
                                        <div className="right-middle1-line"></div>
                                    </div>
                                    <div className="right-middle2-info-block info-block"
                                         onClick={playAudio("mispikikan.mp3")}>
                                        <p>rib</p>
                                        <p>mispikīkan</p>
                                        <p>ᒥᐢᐱᑫᑲᐣ</p>
                                        <div className="right-middle2-line"></div>
                                    </div>
                                    <div className="right-bottom-info-block info-block"
                                         onClick={playAudio("mitokanikan.mp3")}>
                                        <p>hip bone</p>
                                        <p>mitokanikan</p>
                                        <p>ᒥᑐᑲᓂᑲᐣ</p>
                                        <div className="right-bottom-line"></div>
                                    </div>
                                    <div className="title-block">
                                        <p>BONES</p>
                                        <p>oskana</p>
                                        <p>ᐅᐢᑲᓇ</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <NewProgressBar/>
            )
        }
    }
}


const CATEGORY = "bones";

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.ch[CATEGORY]
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData() {
        dispatch(chActions.fetchCH(CATEGORY));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Bones);
