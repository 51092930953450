import React from 'react';
import './BaseCmpWithHover.css';
import PropTypes from 'prop-types';

const BaseCmpWithHover = props => {
  const showHint = (e) =>{
    const tooltips = document.querySelectorAll('.c-tooltip-container .c-tooltip');
    const x = (e.clientX + 20) + 'px';
    const y = (e.clientY + 20) + 'px';

    for (let i = 0, len = tooltips.length; i < len; i++) {
      tooltips[i].style.left = x;
      tooltips[i].style.top = y;
    }
  };

  const showTranslation = () => {
    document.querySelectorAll('div[name="text"]').forEach(el => el.innerText = props.text || '');
    document.querySelectorAll('div[name="sro"]').forEach(el => el.innerText = props.sro || '');
    document.querySelectorAll('div[name="syllabic"]').forEach(el => el.innerText = props.syllabic || '');
  };

  const hideTranslation = () => {
    const defaultText = props.isTranslateForRooms ? 'English' : '';
    const defaultSbo = props.isTranslateForRooms ? 'Standard Roman Orthography' : '';
    const defaultSyllabic = props.isTranslateForRooms ? 'Syllabics' : '';

    document.querySelectorAll('div[name="text"]').forEach(el => el.innerText = defaultText);
    document.querySelectorAll('div[name="sro"]').forEach(el => el.innerText = defaultSbo);
    document.querySelectorAll('div[name="syllabic"]').forEach(el => el.innerText = defaultSyllabic);
  };

  const playAudio = () => {
    if (props.audio) {
      (new Audio(props.audio)).play();
    }
  };

  const hintText = (typeof props.hintText === 'string')
    ? props.hintText
    : props.hintText.map((el, i) => <p key={i}>{el}</p>);

  const cmpClasses = 'c-tooltip-container' + (props.needToHide ? ' c-hidden' : '');

  return (
    <div id={props.identifier} className={cmpClasses}>
      <div
        className='c-tooltip-owner'
        onMouseMove={showHint}
        onMouseOver={showTranslation}
        onMouseLeave={hideTranslation}
        onClick={props.onClickAction ? props.onClickAction : playAudio}
      />

      { hintText &&
        (<span className='c-tooltip'>
          {hintText}
        </span>)
      }
    </div>
  );
};

BaseCmpWithHover.propTypes = {
  identifier: PropTypes.string.isRequired,
  hintText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  text: PropTypes.string,
  sro: PropTypes.string,
  syllabic: PropTypes.string,
  isTranslateForRooms: PropTypes.bool,
  audio: PropTypes.string,
  needToHide: PropTypes.bool,
  onClickAction: PropTypes.func,
};

export default BaseCmpWithHover;
