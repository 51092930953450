import {combineReducers } from 'redux';
import {verbReducer, verbsReducer} from './verbReducers'
import {wildAnimalsReducer} from './wildAnimalsReducers'
import {foodReducer} from './foodReducers'
//import {domesticAndFarmAnimalsReducer} from './domesticAndFarmAnimalsReducers'
import {syllabicChartReducer} from './syllabicChartReducers'
import {birdsReducer} from './BirdsReducers'
import {chReducer} from './chReducers'
import { catalogReducer } from './catalogReducer';
import chaReducer from './chaReducer'

export default combineReducers({
  catalog: catalogReducer,
  syllabic_chart: syllabicChartReducer,
  verbs: verbsReducer,
  verb: verbReducer,
  wild_animals: wildAnimalsReducer,
  food: foodReducer,
//  domestic_and_farm_animals: domesticAndFarmAnimalsReducer,
  birds: birdsReducer,
  ch: chReducer,
  cha: chaReducer
});
