import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Tab, Tabs, Sonnet } from 'react-bootstrap'; //for lyrics tabs

const Song9 = () => {

	let audio_attribution =	<div className="audio-attribution">
		<p>owanihikīw kī-wanihikīsiw - The Trapper Trapped</p>
	</div>

	let audio_player = <div>
				<AudioPlayer
					preload="none"
					src="/api/get.php?file=media/cree_audio/audio/trapper_song_john_halkett.mp3"
				/>
			  </div>

	let card1 = <div className="card">
		<div className="card-body">
			<h4 className="audio-text-title card-title">owanihikīw kī-wanihikīsiw</h4>
			<div className="audio-text card-text">
				<div>owanihikīw kī-wanihikīsiw</div>
				<div>wanihikiskanāhk, kī-wanihamawīw wacaskwa,</div>
				<div>wanihikiskanāhk, wacask ōta, wacask nitī,</div>
				<div>ōta wacaskwak, nītī wacaskwak, mihcīt wacaskwak </div>
				<div>owanihikīw kī-wanihikīsiw wanihikiskanāhk</div>

				<div>owanihikīw kī-wanihikīsiw</div>
				<div>wanihikiskanāhk, kī-wanihamawīw amiskwa, </div>
				<div>wanihikiskanāhk, amisk ōta, amisk nītī, </div>
				<div>ōta amiskwak, nītī amiskwak, mihcīt amiskwak </div>
				<div>owanihikīw kī-wanihikīsiw wanihikiskanāhk</div>

				<div>owanihikīw kī-wanihikīsiw</div>
				<div>wanihikiskanāhk kī-wanihamawīw nikikwa, </div>
				<div>wanihikiskanāhk, nikik ōta, nikik nītī,</div>
				<div>ōta nikikwak, nītī nikikwak, mihcīt nikikwak </div>
				<div>owanihikīw kī-wanihikīsiw wanihikiskanāhk</div>
			</div>
		</div>
	</div>

	let card2 = <div className="card">
		<div className="card-body">
		</div>
	</div>

	let card3 = <div className="card">
		<div className="card-body">
		</div>
	</div>



	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
		);  //check if mobile device

	if(mobile)
	{
		return (
			<div>
				{audio_attribution}
				{audio_player}
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<Tabs defaultActiveKey="sro" id="uncontrolled-tab-example">
							<Tab eventKey="sro" title="SRO" default>
								{card1}
							</Tab>
							<Tab eventKey="syllabics" title="Syllabics">
								{card2}
							</Tab>
							<Tab eventKey="english" title="English">
								{card3}
							</Tab>
						</Tabs>
					</div>
				</div>

			</div>
		);
	}
	else
	{
		return (
			<div>
				{audio_attribution}
				{audio_player}
				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							{card1}
						</div>
						<div className="col-md-6 col-lg-4">
							{card2}
						</div>
						<div className="col-md-6 col-lg-4">
							{card3}
						</div>
					</div>
				</div>

			</div>
		);
	}
};

export default Song9;
