import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About us</h1>
        <div>
            <img src="/api/get.php?file=media/Home/logo/logo180x180.png" alt="Learn Cree Logo" />
        </div>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed tortor non nisi vestibulum bibendum. Phasellus tempus ornare dui facilisis elementum. Praesent vestibulum varius nisl vitae maximus. Aenean porttitor vehicula faucibus. Vivamus volutpat diam sed lectus vulputate venenatis. Curabitur quis varius nisl, vitae consectetur nunc. Maecenas consectetur mauris ut efficitur semper. Maecenas tincidunt, dui et egestas pharetra, nisl quam cursus erat, laoreet tincidunt odio sem ut quam.</p>
      <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc pellentesque dolor sit amet purus facilisis auctor. Cras sit amet enim mollis, iaculis lorem non, vestibulum metus. Vivamus in leo sagittis, consequat massa vel, finibus nisl. Fusce aliquam urna orci, vitae rhoncus lorem dictum id. Quisque id faucibus leo. Aliquam in turpis in velit scelerisque pretium. Quisque tincidunt metus ac vehicula pretium. Donec euismod libero eros, id convallis purus vulputate non. Vivamus faucibus augue in augue condimentum consequat non eget arcu. Vestibulum ullamcorper ac elit ut consectetur. Nunc non velit mollis, consequat diam sit amet, ultricies enim. Nunc lectus augue, consequat non aliquet sed, rutrum sit amet justo. Fusce ultricies eget magna ut fringilla.</p>
      <p>Mauris blandit metus et nibh blandit tincidunt. Sed malesuada ullamcorper eros, ac commodo tortor consectetur vitae. Nunc varius volutpat venenatis. Quisque et justo eu mi posuere mollis. Phasellus mattis eget libero et vehicula. Proin libero diam, posuere quis porttitor eget, bibendum eget tortor. Proin nec est accumsan, lobortis erat eu, vehicula massa. Sed tristique pellentesque varius. Vestibulum feugiat sollicitudin odio, id lacinia elit pulvinar pellentesque.</p>
    </div>
  );
};

export default About;
