import React from 'react';
import { Link } from 'react-router-dom';

const HomeAudio = () => {
    var mobile = (
        typeof window.orientation !== "undefined")
        || (navigator.userAgent.indexOf('IEMobile') !== -1
        );  //check if mobile
    var mobileIntroParagraph;
    if(!mobile)
    {
        //only show main paragraph if not mobile
        mobileIntroParagraph = (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8" id="mission-statement">
                        <p>The Lac La Ronge Indian Band, N-12 Education Program will provide an education that meets the unique needs of our children; that emphasizes the four goals of pride, culture, skills, and values; and that helps our children achieve academic excellence and proficiency with the Cree Language.</p>
                    </div>
                    <div className="col-md-4" id="main-logo">
                        <img src="/api/get.php?file=media/Home/logo/logo180x180.png" alt="Learn Cree Logo" />
                    </div>
                </div>
            </div>
        )
    }

  return (
    <div className="page-links">
        {mobileIntroParagraph}
    <section>
	<div className="section-title text-center">
	    <h1>Audio</h1>
	</div>
    </section>
    <section className="cree-section">
        <div className="cree-div categories">
            <div className="container1">
                <div className="row">

                    <div className="col-md-12">
                        <Link to="/song10" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>awa kisīthiniw</h5>
					<p>(This Old Man)</p>
					<i className="fas fa-chevron-right"></i>
				</div>
                            </div>
                        </Link>
                    </div>


                    <div className="col-md-12">
                        <Link to="/song5" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>iyakoni kīsikāwa</h5>
					<p>(those are the weekdays)</p>
					<i className="fas fa-chevron-right"></i>
				</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-12">
                    <Link to="/song1" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
				    <h5>kā-kanātahk</h5>
				    <p>(O Canada)</p>
				    <i className="fas fa-chevron-right">  </i>
				</div>
                        </div>
                    </Link>
                    </div>
                    <div className="col-md-12">
                        <Link to="/song4" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>kā-tipīthihcikīt otayamihāwin</h5>
					<p>(The Lord's  Prayer)</p>
					<i className="fas fa-chevron-right"></i>
				</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-12">
                        <Link to="/song17" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>kikī-wāpimāw iskwīsis</h5>
					<p>(Did You See a Girl)</p>
					<i className="fas fa-chevron-right"></i>
				</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-12">
                        <Link to="/song12" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>kimowan, kimowan sipwīhtī</h5>
					<p>(Rain, Rain, Go Away)</p>
					<i className="fas fa-chevron-right"></i>
				</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-12">
                        <Link to="/song3" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>kinīhithowān</h5>
					<p>(Do You Speak cree)</p>
					<i className="fas fa-chevron-right">  </i>
				</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-12">
                        <Link to="/song16" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>kīspin kisākihin</h5>
					<p>(If You Love Me)</p>
					<i className="fas fa-chevron-right">  </i>
				</div>
                            </div>
                        </Link>
                    </div>


                    <div className="col-md-12">
                        <Link to="/song8" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>kītha kitaskiy</h5>
					<p>(This Land is Your Land)</p>
					<i className="fas fa-chevron-right">  </i>
				</div>
                            </div>
                        </Link>
                    </div>


                    <div className="col-md-12">
                        <Link to="/song13" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>kohkominahkīsīs nikamon</h5>
					<p>(Itsy Bitsy Spider)</p>
					<i className="fas fa-chevron-right">  </i>
				</div>
                            </div>
                        </Link>
                    </div>


                    <div className="col-md-12">
                        <Link to="/song14" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>nimiskawāw pakān</h5>
					<p>(Found A Peanut)</p>
					<i className="fas fa-chevron-right">  </i>
				</div>
                            </div>
                        </Link>
                    </div>



                    <div className="col-md-12">
                        <Link to="/song2" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>ōtīnāhk</h5>
					<p>(ᐆᑌᓈᕽ)</p>
					<i className="fas fa-chevron-right">  </i>
				</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-12">
                        <Link to="/song6" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>okiskinwahamākīw</h5>
					<p>(ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ)</p>
					<i className="fas fa-chevron-right">  </i>
				</div>
                            </div>
                        </Link>
                    </div>


                    <div className="col-md-12">
                        <Link to="/song9" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>owanihikīw kī-wanihikīsiw</h5>
					<p>(The Trapper Trapped)</p>
					<i className="fas fa-chevron-right">  </i>
				</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-12">
                        <Link to="/song7" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>pisiskiwak</h5>
					<p>(ᐱᓯᐢᑭᐘᐠ)</p>
					<i className="fas fa-chevron-right"></i>
				</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-12">
                        <Link to="/song11" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>piyak, nīso, nisto nāpīsisak</h5>
					<p>(one, two, three boys)</p>
					<i className="fas fa-chevron-right"></i>
				</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-12">
                        <Link to="/song15" style={{textDecoration: 'none',color:'black'}}>
                            <div className="cree-box" style={{display: "flex"}}>
				<div style={{marginRight: "20px"}}><img width="50px" src="/api/get.php?file=media/icons/headphones-2104207_IO-Images_100px.png" alt="audio symbol" /></div>
				<div>
					<h5>wāsiso, wāsiso acāhkosis</h5>
					<p>(Twinkle, Twinkle, Little Star</p>
					<i className="fas fa-chevron-right"></i>
				</div>
                            </div>
                        </Link>
                    </div>


                </div>
            </div>
        </div>
    </section>
    </div>
  );
};

export default HomeAudio;
