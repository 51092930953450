'use strict'

import React from 'react';
import { connect } from 'react-redux';
import * as chaActions from '../../../actions/chaActions';
import AnimatedScene from './AnimatedScene';
import PropTypes from 'prop-types'
import selectn from 'selectn'
//import ProgressBar from "../../ProgressBar/ProgressBar";
//import NewProgressBar from "../../ProgressBar/newProgressBar";
import KWPreload from '../../../KWPreload/KWPreload';
import "../click_and_hear/birdComponent.css";

const styles = {
  animalHomes: {
    maxWidth: '100%'
  },
  sceneTitleStyle: {
    textAlign: "center",
    backgroundColor: "black",
    color: "white",
    padding: 10
  },
  currentWord: {
    textAlign: 'center',
  },
  currentWordInvisible: {
    visibility: 'hidden',
    textAlign: 'center',
  }
}

class AnimatedScenePage extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      currentWord: ''
    }
    console.log("Props",props)
    this.audio2 = new Audio()
  }

  componentDidMount() {
    this.scrollToTop()

    if (!this.props.scenes) {
      this.props.fetchScenes();
    } else if (!this.props.selectedScene) {
      this.selectFirstScene(this.props.scenes)
    } else if (!this.props.sceneData) {
      this.props.fetchSceneData(this.props.selectedScene)
    }
  }

  componentWillReceiveProps(nextProps) {

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.key !== prevProps.location.key) {
      this.props.fetchScenes();
    }
    if (this.props.scenes !== prevProps.scenes && this.props.scenes) {
      this.selectFirstScene(this.props.scenes)
    }
    if (this.props.selectedScene !== prevProps.selectedScene && !this.props.sceneData) {
      this.props.fetchSceneData(this.props.selectedScene)
    }
  }

  selectFirstScene = (scenes) => {

    const scenesData = this.getScenesData(this.props.scenes)
    if (scenes && !Array.isArray(scenesData)) {
      console.error('scenesData must be an array')
      return
    }
    const firstScene = scenesData[0]
    //const firstScene = scenesData[1]
    if (firstScene) {
      this.props.selectScene(firstScene.id)
    } else {
      console.error('there is no scene for current activity')
    }
  }

  playAudio(path) {
  //  let audio = new Audio(`/api/get.php?file=${path}`)
  this.audio2.src = `/api/get.php?file=${path}`
  this.audio2.load()
    
	console.log("UUUUUUUUUUUUUUUUUUUUUUUU")
	console.log(path)
	console.log(this.audio2.src)

    this.audio2.play();
  }

  scrollToTop() {
    if (this.refs.scroller) {
      this.refs.scroller.scrollTop = 0;
    }

  }

  changeScenes = (scene) => {
    //this.scrollToTop()

    this.props.selectScene(scene)
    this.setState({currentWord: ''})
  }

  onWordTitle = (word) => this.setState({currentWord: word})

  onWordAudio = (audioPath) => {
    audioPath && this.playAudio(audioPath)
  }

  getScenesData = (scenes) => selectn('data_set', scenes)

  /*renderScenesSelector = (scenesData) => {
    if (!Array.isArray(scenesData) || scenesData.length < 2) {
      return null
    }
    return scenesData.map((item) => {
      //TODO consolidate styles
      if (this.props.selectedScene && this.props.selectedScene == item.id) {
        const style = {
          display: "inline-block",
          marginRight: 10,
          marginTop: 5,
          marginBottom: 5,
          padding: 10,
          borderRadius: 2,
          backgroundColor: "#3aa0d0",
          color: '#fff',
          cursor: "pointer"
        }
        return <div key={item.id} style={style}>{item.title}</div>
      } else {
        const style = {
          display: "inline-block",
          marginRight: 10,
          marginTop: 5,
          marginBottom: 5,
          padding: 10,
          backgroundColor: '#eee',
          borderRadius: 2,
          cursor: "pointer"
        }
        
        return (

          <div key={item.id} onClick={() => this.changeScenes(item.id)} style={style} >
            {item.title}
          </div>
        )
      }
    })
  }
*/
    renderScenesSelector = scenesData => {
    if (!Array.isArray(scenesData) || scenesData.length < 2) {
      return null;
    }

    return scenesData.map(item => {
      //TODO consolidate styles
      if (this.props.selectedScene && this.props.selectedScene == item.id) {
        return (
          <div key={item.title} className="tab-slider--trigger active">
            {item.title}
          </div>
        );
      } else {
        return (
          <div
            key={item.title}
            onClick={() => this.changeScenes(item.id)}
            className="tab-slider--trigger">
            {item.title}
          </div>
        );
      }
    });
  };

  //renderLoader = () => <NewProgressBar category='cha'/>
  renderLoader = () => <div>Loading</div>

  render() {
    const {currentWord} = this.state
   
    const scenesData = this.getScenesData(this.props.scenes)
    if (!scenesData) {
      return this.renderLoader()
    }

    let selectedSceneCommonData
    if (this.props.selectedScene && this.props.scenes) {
      selectedSceneCommonData = scenesData.find(i => i.id === this.props.selectedScene)
    }

    const {
      sceneTitleStyle, currentWordInvisible: currentWordInvisibleStyle, currentWord: currentWordStyle,
      animalHomes: animalHomesStyle
    } = styles

    return (
      <div className="animalHomes" style={animalHomesStyle} >
        
            {this.props.sceneData ? (
              <div>
                <div className="page-title">
                  <h1 className="uc-first">{this.props.scenes.title}</h1>
                </div>
                <div className="tab-slider--nav">
                  <ul className="tab-slider--tabs">{this.renderScenesSelector(scenesData)}</ul>
                </div>

                <div style={{display: "flex",marginTop:'15px', maxWidth: '100%'}}>
                  <div style={{flex: 1, display: "flex", flexDirection: "column", maxWidth: '100%'}}>

                    <h4 className="animalHomes_currentWord" style={currentWord ? currentWordStyle : currentWordInvisibleStyle} >
                      {currentWord || 'empty'}
                    </h4>

                    <AnimatedScene
                      onWordTitle={this.onWordTitle}
                      onWordAudio={this.onWordAudio}
                      data={this.props.sceneData}
                      key={this.props.selectedScene}
                      tr={'tr'}
                      lg={'lg'}
                    />

                  </div>
                </div>
              </div>
              )
              :
              this.renderLoader()
            }
         
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const activity = ownProps.match.params.val
  const scenes = state.cha.scenes[activity]
  const selectedScene = state.cha.selectedScene[activity]
  const sceneData = selectedScene ? selectn(`${activity}.${selectedScene}`, state.cha.scenesData) : undefined
 
  return {
    scenes,
    selectedScene,
    sceneData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectScene(scene) {
	console.log("SELECT SCENE AAA")
    dispatch(chaActions.selectScene(ownProps.match.params.val, scene));
  },
  fetchScenes() {
	console.log("FETCH SCENE BBB")
    dispatch(chaActions.fetchScenes(ownProps.match.params.val));
  },
  fetchSceneData(selectedScene) {
	console.log("FETCH SCENE CCC")
    dispatch(chaActions.fetchSceneData(ownProps.match.params.val, selectedScene));
  }
});

AnimatedScenePage.propTypes = {
  selectScene: PropTypes.func.isRequired,
  fetchScenes: PropTypes.func.isRequired,
  fetchSceneData: PropTypes.func.isRequired,
  scenes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    data_set: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      data_source: PropTypes.string.isRequired
    })),
  }),
  selectedScene: PropTypes.string,
  sceneData: PropTypes.object,
}

// Use connect to put them together
export default connect(mapStateToProps, mapDispatchToProps)(AnimatedScenePage);
