// For handling array of wild_animals

import * as actionTypes from '../actions/actionTypes'

export const wildAnimalsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_WILD_ANIMALS_SUCCESS:
          return action.wild_animals;
    default:
          return state;
  }
};

