import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { recaptchaKey } from "../../constants/recaptcha";
import '../main.css';
import './Contact.css';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            mailSent: false,
            error: null
        }
    }

    onChange = (value) => {
        console.log("Captcha value:", value);
    }

    handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${'../../../api/sendContactForm.php'}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
          .then(result => {
              this.setState({
                  mailSent: result.data.sent
              })
          })
          .catch(error => this.setState({ error: error.message }));
    }

    generate_e(last, first){
	let ch = ["a", "b", "c", "d", "e", "i", "l", "r", "u", ".", "@"]
	let str = first + ch[9] + last + ch[10] + ch[6] + ch[6] + ch[7] + ch[5] + ch[1] + ch[4] + ch[3] + ch[8] + ch[9] + ch[2] + ch[0]
	return str
    }


    render () {
        return (
            <div className='contact c-wrapper'>
                <h3>Contact Us</h3>
                <div className='c-container'>
                    <div className='c-content-contact'>
			<div style={{marginBottom: "20px"}}>
				<div style={{color: "rgb(85, 187, 255)"}}>To order Cree resources and merchandise:</div>
				<div style={{color: "rgb(85, 187, 255)"}}>Cree Language and Culture Coordinator</div>
				<div>Minnie McKenzie</div>
				<div>{this.generate_e("mckenzie", "minnie")}</div>
				<div>(306) 425-4938 (ext. 240)</div>
			</div>
			<div style={{marginBottom: "20px"}}>
				<div style={{color: "rgb(85, 187, 255)"}}>Cree Curriculum Developer:</div>
				<div>Christina Clarke</div>
				<div>{this.generate_e("clarke", "christina")}</div>
				<div>(306) 425-4938 (ext. 305)</div>
			</div>
			<div style={{marginBottom: "20px"}}>
				<div style={{color: "rgb(85, 187, 255)"}}>Cree Developer Technician</div>
				<div>Dolly Ratt</div>
				<div>{this.generate_e("ratt", "dolly")}</div>
				<div>(306) 425-4938 (ext. 310/263)</div>
			</div>
			<div style={{marginBottom: "20px"}}>
				<div style={{color: "rgb(85, 187, 255)"}}>Graphic Designer</div>
				<div>Jeffery Bell</div>
				<div>{this.generate_e("bell", "jeffery")}</div>
				<div>(306) 425-4938 (ext. 324)</div>
			</div>
			<div style={{marginBottom: "20px"}}>
				<div style={{color: "rgb(85, 187, 255)"}}>Web Developer/Designer</div>
				<div>Kevin Waddell</div>
				<div>(306) 381-9460</div>
			</div>
		    </div>
                </div>
            </div>
        );
    }
};

export default Contact;
