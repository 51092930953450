import Axios from "axios";
import { FETCH_CHA_FILE_SUCCESS } from "./actionTypes";
var CancelToken = Axios.CancelToken;
var cancel;

export const fetchCHSuccess = data => {
  return {
    type: "FETCH_CH_SUCCESS",
    data
  };
};

var requestedScene = [];

export const fetchCH = activity => {
  console.log("IN fetchCH")
  if (requestedScene.length !== 0) {
    console.log("here");
    cancel();
  }
  requestedScene.push(activity);
  return dispatch => {
    dispatch({
      type: "FETCH_FILE_SUCCESS",
      loading: {
        file: 0,
        from: 10
      }
    });
    return Axios.get(`/api/${activity}/`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    })
      .then(response => {
        // console.log(requestedScene, response);
	console.log("EEEE")
        let data_set = response.data.data_set ? response.data.data_set : [];
	console.log(data_set)
        let dataArray = [];
        data_set.map(item => {
          if (item.data) {
            dataArray = dataArray.concat(item.data);
          }
        });

        if (dataArray.length > 0) {
          let totalFiles = countFiles(dataArray);
          counter = 0;
          let fetchCHSuccessData = { activity, data: response.data };
          response.data["media"] = getData(dataArray, dispatch, totalFiles, fetchCHSuccessData);
	  console.log("GGGGGG")
          console.log(response.data)
        } else {
          dispatch(fetchCHSuccess({ activity, data: response.data }));
        }
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };
};

export const fetchFilterCH = (activity, optionArray) => {
  if (requestedScene.length !== 0) {
    console.log("here");
    cancel();
  }
  return dispatch => {
    dispatch({
      type: "FETCH_FILE_SUCCESS",
      loading: {
        file: 0,
        from: 10
      }
    });
    return Axios.get(`/api/${activity}/`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    })
      .then(response => {
        let data_set = response.data.data_set ? response.data.data_set : [];
        let dataArray = {};
        data_set.map(item => {
          if (item.data) {
            dataArray = dataArray.concat(item.data);
          }
        });

        if (dataArray.length > 0) {
          let totalFiles = countFiles(dataArray);
          let fetchCHSuccessData = { activity, data: response.data };
          response.data["media"] = getData(dataArray, dispatch, totalFiles, fetchCHSuccessData);
        } else {
          let selected = [];

          const result = optionArray.map(e => {
            let tmp = response.data.data_set.filter(item => item.mainCategory == e);

            return selected.concat(tmp);
          });
          var margeArray = [].concat.apply([], result);

          selected["title1"] = "Catalogue";
          selected["data_set"] = margeArray;

          dispatch(
            fetchCHSuccess({ activity, data: selected.data_set.length ? selected : response.data })
          );
        }
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };
};

function filterData(optionArray, arr) {}

export const countFiles = array => {
  let counter = 0;
  array.map((item, i) => {
    if (item.image) {
      counter++;
    }
    if (item.image2) {
      counter++;
    }
    if (item.audio) {
      counter++;
    }
  });
  return counter;
};

export const getData = (array, dispatch, totalFiles, fetchCHSuccessData) => {
  let images = [];
  let audios = [];
  array.map((item, i) => {
    if (item.image) {
      let image = fetchImage(item.image, dispatch, totalFiles, fetchCHSuccessData);
      images.push(image);
    }
    if (item.image2) {
      let image2 = fetchImage(item.image2, dispatch, totalFiles, fetchCHSuccessData);
      images.push(image2);
    }
    if (item.audio) {
      let media = fetchMedia(item.audio, dispatch, totalFiles, fetchCHSuccessData);
      audios.push(media);
    }
  });
  return {
    images: images,
    files: audios
  };
};

let counter = 0;

/* Modified by KJW - temporary hack

export const fetchImage = (path, dispatch, from, fetchCHSuccessData) => {
  const img = new Image();
  img.src = `/api/get.php?file=${path}`; // by setting an src, you trigger browser download
  // img.addEventListener('load', fileDone(dispatch, file,from), false);
  // img.addEventListener('complete', fileDone(dispatch, file,from), false);

  img.onload = function() {
    dispatchFileSuccess(path, dispatch, from, fetchCHSuccessData);
  };
  img.onerror = function() {
    dispatchFileSuccess(path, dispatch, from, fetchCHSuccessData);
  };
  return img;
};

export const fetchMedia = (path, dispatch, from, fetchCHSuccessData) => {
  let audio = new Audio(`/api/get.php?file=${path}`);
  audio.oncanplaythrough = function() {
    dispatchFileSuccess(path, dispatch, from, fetchCHSuccessData);
  };
  audio.onerror = function() {
    dispatchFileSuccess(path, dispatch, from, fetchCHSuccessData);
  };
  return audio;
};


*/

export const fetchImage = (path, dispatch, from, fetchCHSuccessData) => {
  let img = `/api/get.php?file=${path}`;
  dispatchFileSuccess(path, dispatch, from, fetchCHSuccessData);
  return img;
};

export const fetchMedia = (path, dispatch, from, fetchCHSuccessData) => {
  let audio = `/api/get.php?file=${path}`;
  dispatchFileSuccess(path, dispatch, from, fetchCHSuccessData);

  return audio;
};


export const dispatchFileSuccess = (path, dispatch, from, fetchCHSuccessData) => {
  counter++;
  dispatch({
    type: "FETCH_FILE_SUCCESS",
    loading: { file: counter, from }
  });

  if (counter === from || counter >= from) {
    counter = 0;
    dispatch(fetchCHSuccess(fetchCHSuccessData));
    dispatch({
      type: "FETCH_FILE_SUCCESS",
      loading: {
        file: 0,
        from: 10
      }
    });
  }

   console.log("file Done" + counter+ " from " + from);
};
