import React from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import { Image } from 'react-bootstrap'; //for logo holder

class Knav extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      main_menu: null,
      main_menu_jsx: null
    };
  };

  componentWillMount () {
    //load menu if mobie or not
    var mobile = (
        typeof window.orientation !== "undefined")
        || (navigator.userAgent.indexOf('IEMobile') !== -1
    );  //check if mobile
    if(!mobile)
    {
      Axios.get('/api/main_menu')
          .then((response) => {
            const main_menu = response.data;

            this.setState({main_menu});
            this.create_menu(main_menu);
          })
          .catch(error => {
            console.log(error);
            throw(error);
          });
    }
    else
    {
      Axios.get('/api/main_menu_mobile')
          .then((response) => {
            const main_menu = response.data;

            this.setState({main_menu});
            this.create_menu(main_menu);
          })
          .catch(error => {
            console.log(error);
            throw(error);
          });
    }

  };

  // componentDidMount () {
  //   //this.update_menu();
  //   $('#main-menu').hide();
  // };

  componentDidUpdate () {
    this.update_menu();
  };

  shouldComponentUpdate (nextProps, nextState) {
    return !this.state.main_menu_jsx;
  };

  update_menu () {

    this.sm_init();
    this.mobile_collapse();
    this.mobile_toggle();
  };

  // SmartMenus init
  sm_init () {
    $('#main-menu').smartmenus({
      subMenusSubOffsetX: 1,
      subMenusSubOffsetY: -8
    });
  // $('#main-menu').css('display', 'none');

  };

  // SmartMenus jQuery - collapse mobile navbar on menu item select
  mobile_collapse () {
    $('.main-nav').on('select.smapi', function (e) {
      if ($(this).hasClass('sm-collapsible'))
      {
        $('.navbar-toggler')[0].click();
        $('#main-menu-state').hide();
      }
    });
  };

  // SmartMenus mobile menu toggle button
  mobile_toggle () {
    var $mainMenuState = $('#main-menu-state');

    if ($mainMenuState.length) {
      // animate mobile menu
      $mainMenuState.change(function (e) {
        var $menu = $('#main-menu');
        //this is for hamburger
        if (this.checked)
        {
          $menu.show().slideDown(25, function () { $menu.css('display', 'block'); });
        } else {
          $menu.hide().slideUp(25, function () { $menu.css('display', 'none'); });
        }
      });
      // hide mobile menu beforeunload
      $(window).bind('beforeunload unload', function () {
        if ($mainMenuState[0].checked)
        {
          $mainMenuState[0].click();
        }
      });
    }
  };

  

  // disableLink (e) {
  //   e.preventDefault();
  // }

  renderMenu = (items, parent_menu) => {
    var $menu = $('#main-menu');
    if (parent_menu) {
      return (
          //mobile
        <ul id="main-menu" className="sm sm-blue">
          {items.map((i, index) => {
            return (
              <li key={index}>
                {
                  !(i.disable) ?
                  (
                      i.link == '#' ?
                      <a href={i.link}>{i.title}</a> :
                      <Link to={i.link} onClick={() => mobile_toggle()}>{i.title}</Link>
                  ) :
                  (<span style={{ color: 'grey' }}>{ i.title} </span>)
                }
                {i.menu && this.renderMenu(i.menu, false)}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
          //web
        <ul>
          {items.map((i, index) => {
            return <li key={index}>
              {
                !(i.disable) ?
                  (i.link == '#' ? <a href={i.link}>{i.title}</a> : <Link to={i.link}>{i.title}</Link>) :
                  (<span style={{ display: 'inline-block', color: 'grey', padding: '9px 23px', fontSize: '16px', fontWeight: 'bold', lineHeight: '24px', fontFamily: "'PT Sans Narrow', 'Arial Narrow', Arial, Helvetica, sans-serif" }}>{ i.title }</span>)
              }
              {i.menu && this.renderMenu(i.menu, false)}
            </li>;
          })}
        </ul>
      );
    }
  };

  Menu = data => {
    return (
        this.renderMenu(data.menu, true)
    );
  };

  create_menu (data) {
    var mobile = (
        typeof window.orientation !== "undefined")
        || (navigator.userAgent.indexOf('IEMobile') !== -1
        );  //check if mobile

    if(mobile)
    {
      this.setState({
        main_menu_jsx: (
            <div className="container-fluid">
            <div style={{display: "flex", justifyContent: "space-between", paddingTop: "10px"}}>
              <div>
                <a href="/">
                  <img src="/api/get.php?file=media/Home/logo/logo237x45.png" alt="Learn Cree Logo" />
                </a>
              </div>
		  <a href="https://llribedu.ca">
		    <Image src="/api/get.php?file=media/Home/logo/ed-logo-transparent-backround-kw40px.png" height="40" style={{marginRight: "10px"}} />
		  </a>
                  <a href="http://llrib.com">
                    <Image src="/api/get.php?file=media/Home/logo/llrib_logo-transparent-background-4x3-6inch.png" height="40" />
                  </a>
              <div></div>
              <div>
                <nav className="main-nav" role="navigation" style={{backgroundColor: "white", display: "inline-block"}}>
                  <input id="main-menu-state" type="checkbox"/>
                  <label className="main-menu-btn" htmlFor="main-menu-state">
                    <span className="main-menu-btn-icon"></span> Toggle main menu visibility
                  </label>
                  {this.Menu(data)}
                </nav>
              </div>
            </div>
            </div>
            /*<div className="container-fluid">
              <div className="row">
                <div className="col-md-4" id="main-logo">
                  <img src="/api/get.php?file=media/Home/logo/logo180x180.png" alt="Learn Cree Logo" />
                  <div className="col-md-8" style={{display: "flex", justifyContent: "flex-end", paddingTop: "10px"}}>
                    <nav className="main-nav" role="navigation" style={{backgroundColor: "white", display: "inline-block"}}>
                      <input id="main-menu-state" type="checkbox"/>
                      <label className="main-menu-btn" htmlFor="main-menu-state">
                        <span className="main-menu-btn-icon"></span> Toggle main menu visibility
                      </label>
                      {this.Menu(data)}
                    </nav>
                  </div>
                </div>
                {/!*<div className="col-md-4" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <div style={{fontSize: "2em", fontFamily: "ABeeZee", display: "inline-block", color: "#5bf", fontWeight: "bold" }}>learncree.ca</div>
                  <div className="col-md-8" style={{display: "flex", justifyContent: "flex-end", paddingTop: "10px"}}>
                    <nav className="main-nav" role="navigation" style={{backgroundColor: "white", display: "inline-block"}}>
                      <input id="main-menu-state" type="checkbox"/>
                      <label className="main-menu-btn" htmlFor="main-menu-state">
                        <span className="main-menu-btn-icon"></span> Toggle main menu visibility
                      </label>
                      {this.Menu(data)}
                    </nav>
                  </div>
                </div>*!/}

              </div>
            </div>*/
        )
      });
    }
    else
    {
      this.setState({
        main_menu_jsx: (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <a href="/">
                    <Image src="/api/get.php?file=media/Home/logo/logo40x40.png" rounded />
                  </a>
                  <div style={{fontSize: "2em", fontFamily: "ABeeZee", display: "inline-block", color: "#5bf", fontWeight: "bold", padding: "5%"}}>
                    learncree.ca
                  </div>
		  <a href="https://llribedu.ca">
		    <Image src="/api/get.php?file=media/Home/logo/ed-logo-transparent-backround-kw40px.png" height="40" style={{marginRight: "10px"}} />
		  </a>
                  <a href="http://llrib.com">
                    <Image src="/api/get.php?file=media/Home/logo/llrib_logo-transparent-background-4x3-6inch.png" height="40" />
                  </a>
                </div>
                <div className="col-md-8" style={{display: "flex", justifyContent: "flex-end", paddingTop: "10px"}}>

                  <nav className="main-nav" role="navigation" style={{backgroundColor: "white", display: "block"}}>
                    <input id="main-menu-state" type="checkbox"/>
                    <label className="main-menu-btn" htmlFor="main-menu-state">
                      <span className="main-menu-btn-icon"></span> Toggle main menu visibility
                    </label>
                    {this.Menu(data)}
                  </nav>
                </div>
              </div>
            </div>
        )
      });
    }
  };

  /*
    create_menu (data) {
      this.setState({
        main_menu_jsx: (
          <nav className="main-nav" role="navigation">
            <input id="main-menu-state" type="checkbox"/>
            <label className="main-menu-btn" htmlFor="main-menu-state">
              <span className="main-menu-btn-icon"></span> Toggle main menu visibility
            </label>
            {this.Menu(data)}
          </nav>
        )
      });
    };
  */

  render () {
    if (this.state.main_menu_jsx) {
      return this.state.main_menu_jsx;
    } else {
      return <div></div>;
    }
  };
}

export default Knav;
