import React from 'react';
import {connect} from "react-redux";
import * as catalogAction from "../../actions/catalogAction";
//import * as chActions from '../../actions/chActions';
import ProgressBar from '../ProgressBar/ProgressBar';
//import ReactToPrint from 'react-to-print';
//import printJS from 'print-js'
import cloneDeep from 'lodash/cloneDeep';
class CataloguePage extends React.Component {
	constructor() {
		super();
		this.state =
		{
		  viewData:[],
		  rawData:[],
		  categories:
		  [
			  	{
				  id : "1",
				  name : "Poster",
				  isChecked : true,
				  value : "posters"
			  },
			  	{
			id : "2",
			name : "Language Instructional Resources",
			isChecked : false,
			value : "Language_Instructional_Resources"
		},
				{
					id : "3",
					name : "Resources",
					isChecked : false,
					value : "Resources"
				},
				{
					id : "4",
					name : "Bulletin Borders",
					isChecked : false,
					value : "bulletin_borders"
				},
				{
					id : "5",
					name : "Flashcards and Visual Aides",
					isChecked : false,
					value : "FlashCards_VisualAids"
				},
				{
					id : "6",
					name : "Games and Activities",
					isChecked : false,
					value : "Games_Activities"
				},
				{
					id : "7",
					name : "Song Books And USB",
					isChecked : false,
					value : "SongBooks_USB"
				},
				{
					id : "8",
					name : "Story Books and Legends",
					isChecked : false,
					value : "Storybooks_Legends"
				},
				{
					id : "9",
					name : "Language Cultural Instructional",
					isChecked : false,
					value : "Language_Cultural_Instructional"
				},
				{
				id : "10",
				name : "Visual Resources",
				isChecked : false,
				value : "Visual_Resources"
			}
			],
			selectedCat:{
			id : "1",
			name : "Poster",
			isChecked : true,
			value : "posters"
		},
			selectValue: 0,
		}
	 }
	 componentDidMount() {
		console.log("KJW JS 111")
		this.props.fetchData();
		console.log("KJW JS 222")
	}
	componentWillReceiveProps(nextProps){
		//debugger
		if(Object.keys(nextProps.data).length > 0){
			this.setState({
				rawData : nextProps.data,
				viewData : nextProps.data.posters
			})
		}
	}
	selectCat =(e,id) => {
		e.preventDefault();
		let s = e;
		this.setState({
			viewData : []
		},() => {
			let categories = cloneDeep(this.state.categories);
			
			let checkedIndx = categories.findIndex(item => item.isChecked == true);
			categories[checkedIndx].isChecked = false;
			let findIndx = categories.findIndex(item => item.id == id);
			categories[findIndx].isChecked = true;
			if(findIndx > -1){
				this.setState({
					viewData : this.state.rawData[categories[findIndx].value],
					selectedCat : categories[findIndx],
					categories : categories,
					selectValue: id
				})
			}
			
		})
		
	}

	handleInputChange(e) {
	//for category selecter
	// 	alert(Object.values(e.target.value));
		this.selectCat(e, e.target.value);
	}
/*
	print =(e) => {
		e.preventDefault();
	// 	var printContents = document.getElementById('catalog-content').innerHTML;
    //  var originalContents = document.body.innerHTML;
    //  document.body.innerHTML = printContents;
    //  window.print();
	//  document.body.innerHTML = originalContents;
	printJS('catalog-content', 'html')
	}
*/
render()
{
	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
		);  //check if mobile device
	let formDownload, categories, selectedOption;

	if(mobile)
	{
		formDownload = (
			<a href="/api/get.php?file=documents/catalogue.pdf&download=false" target="_blank" ><i className="fa fa-print"></i>Download</a>
		)
		categories = (
			<div>
				<div className="form-row">
					<div className="form-group col-md-6">
						<select className="form-control" name="category"
								onChange={(e) => this.handleInputChange(e)}
								value={this.state.selectValue}>
							<option selected>Select Category</option>
							{
								this.state.categories && this.state.categories.length > 0
									?
								this.state.categories.map((cat,idx)=> {
									return <option name={cat.value}
												   value={cat.id}
												   id={cat.id}>{cat.name}</option>
								})
									:
							""}
						</select>
					</div>
				</div>
				{/*<ul className="cate-list">
					{this.state.categories && this.state.categories.length > 0 ?
						this.state.categories.map((cat,idx)=> {
							console.log(cat)
							return <li key={idx}>
								<label className="checkbox-container" >
									{cat.name}
									<input type="checkbox" checked={cat.isChecked === true}
										   name={cat.value}
										   id={cat.id}
										   onChange={(e) => this.selectCat(e,cat.id)} />
									<span className={cat.isChecked ? "checkmark tick" : "checkmark"}></span>
								</label>
							</li>
						}) : ""}
				</ul>*/}
			</div>

		);
	}
	else
	{
		formDownload = (
			<a href="/api/get.php?file=documents/catalogue.pdf&download=false" target="_blank" ><i className="fa fa-print"></i>View/Print</a>
		)
		categories = (
			<ul className="cate-list">
				{this.state.categories && this.state.categories.length > 0 ?
					this.state.categories.map((cat,idx)=> {
						console.log(cat)
						return <li key={idx}>
							<label className="checkbox-container" >
								{cat.name}
								<input type="checkbox" checked={cat.isChecked === true}
									   name={cat.value}
									   id={cat.id}
									   onChange={(e) => this.selectCat(e,cat.id)} />
								<span className={cat.isChecked ? "checkmark tick" : "checkmark"}></span>
							</label>
						</li>
					}) : ""}
			</ul>
		);
	}

	console.log("STATE")
	console.log(this.state)

	return ( <div className="banner"><div className="container">
	{
		this.state.viewData && this.state.viewData.length > 0
		?
		<div className="row" style={{"flexDirection":"initial","height":"auto","marginTop":"5px"}}>
			<div className="col-md-4 col-lg-3">
				<div className="left-side w-100">
					<strong>Categories</strong>
					{categories}
				</div>
				{/* <div className="left-side">
					<div className="downlaod-pdf">
						<p>Catalog</p>
						<a href="#">Download PDF</a>
					</div>
				</div> */}
				<div className="left-side w-100" >
					<div className="downlaod-pdf">
						<p>Order form</p>
						{formDownload}
					</div>
				</div>
			</div>
			<div className="col-md-8 col-lg-9" id="catalog-content">
				<div className="row">
					{this.state.viewData && this.state.viewData.length > 0 ?
					this.state.viewData.map( (data,idx) => {
					return <div key={idx} className="col-sm-6 col-md-6 col-lg-4">
						<div className="product-item">
							<div className="img-holder">
								<img src={`/api/get.php?file=${data.image}`} alt="catalog" />
							</div>
							<strong>{data.name}</strong>
							<ul className="leght-sec">
								{/* <li><a href="#" className="color-dem">11" x 17"</a></li>
								<li><a href="#">11" x 17"</a></li> */}
							</ul>
							<div className="price-box row">

									<div className="col-6">
										<p>{data.amount}</p>
									</div>
									<div className="col-6">
										<div className="row">
										{data.titles && data.titles.length > 0 ? data.titles.map((title,idx) => {
											return <div  key={idx} className="btn col-10 offset-1 mt-1 btn-sm btn-buy">{title}</div>
										}) : ""}
										</div>
									</div>

							</div>
						</div>
					</div>
					} )
					: ""}
				</div>
			</div>
		</div>
	 	:
		<ProgressBar/>}
	</div>
		</div>
	);
}
};

const mapStateToProps = (state, ownProps) => {
	console.log("WHOLE STATE")
	console.log(state)
	return {
	  data: state.catalog
	};
  };
  
  const mapDispatchToProps = (dispatch, ownProps) => ({
	// fetchData () {dispatch(chActions.fetchCH("catalogue"));}, 
	fetchData() {
	  dispatch(catalogAction.fetchCatalog());
	}
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(CataloguePage);
