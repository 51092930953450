"use strict";

/* KW Note */
/* Change dispach to dispatch */

import Axios from "axios";
import {
  CHA_SCENE_SELECTED,
  FETCH_CHA_SCENES_REQUEST,
  FETCH_CHA_SCENES_SUCCESS,
  FETCH_CHA_SCENES_FAILURE,
  FETCH_CHA_SCENES_DATA_REQUEST,
  FETCH_CHA_SCENES_DATA_SUCCESS,
  FETCH_CHA_SCENES_DATA_FAILURE,
  FETCH_CHA_FILE_SUCCESS
} from "../actions/actionTypes";
import { countFiles } from "./chActions";
var CancelToken = Axios.CancelToken;
var cancel;

const createAction = (type, data) => ({ type, data });

export const selectScene = (activity, scene) => {
  // console.log("reached", activity, scene);
  return createAction(CHA_SCENE_SELECTED, { activity, data: scene });
};

//Async Action
export const fetchScenes = activity => {
  return dispatch => {
    dispatch(createAction(FETCH_CHA_SCENES_REQUEST));
    return Axios.get(`/api/${activity}/`)
      .then(response => {
        dispatch(createAction(FETCH_CHA_SCENES_SUCCESS, { activity, data: response.data }));
      })
      .catch(error => {
        dispatch(createAction(FETCH_CHA_SCENES_FAILURE));
        //throw(error);
      });
  };
};

export const fetchSceneData = (activity, scene) => {
  if (requestedScene.length !== 0) {
    console.log("here");
    cancel();
  }

  console.log("GGGGGGGGGGGGGGGGGGGGG")

  return dispatch => {
    dispatch(createAction(FETCH_CHA_SCENES_DATA_REQUEST));
    dispatch({
      type: FETCH_CHA_FILE_SUCCESS,
      loading: {
        file: 0,
        from: 10
      }
    });
    return Axios.get(`/api/${activity}/${scene}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    })
      .then(response => {
        let files = response.data.files;
        let images = response.data.images;

        let totalFiles = files.length + images.length;

        if (totalFiles > 0) {
          let fetchCHSuccessData = { activity, scene, data: response.data };

          let _images = [];
          let _files = [];
          files.map((item, i) => {
            let media = fetchMedia(item, dispatch, totalFiles, fetchCHSuccessData);
            _files.push(media);
          });
          images.map((item, i) => {
            let image = fetchImage(item, dispatch, totalFiles, fetchCHSuccessData);
            _images.push(image);
          });
          response.data["media"] = {
            images: _images,
            files: _files
          };
        } else {
          dispatch(
            createAction(FETCH_CHA_SCENES_DATA_SUCCESS, { activity, scene, data: response.data })
          );
        }
      })
      .catch(error => {
        dispatch(createAction(FETCH_CHA_SCENES_DATA_FAILURE));
        //throw(error);
      });
  };
};
var requestedScene = [];

export const fetchData = (activity, scene) => {
  if (requestedScene.length !== 0) {
    console.log("Fetch Data here");
    cancel();
  }

  requestedScene.push(scene);
  return dispatch => {
    dispatch(createAction(FETCH_CHA_SCENES_DATA_REQUEST));
    dispatch({
      type: FETCH_CHA_FILE_SUCCESS,
      loading: {
        file: 0,
        from: 10
      }
    });

	console.log("KW NOTE BBBBB")
	console.log(activity)
	console.log(scene)

    return Axios.get(`/api/${activity}/${scene}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    })
      .then(response => {
        console.log(response);
        let data_set = response.data.data_set ? response.data.data_set : [];
        let dataArray = [];
        data_set.map(item => {
          // console.log("ITEM", item);
          if (item.data) {
            dataArray = dataArray.concat(item.data);
          }
        });

        if (dataArray.length > 0) {
          let totalFiles = countFiles(dataArray);
          counter = 0;

          let fetchCHSuccessData = { activity, scene, data: response.data };

          let _images = [];
          let _files = [];

          dataArray.map((item, i) => {
            if (item.audio) {
              let media = fetchMedia(item.audio, dispatch, totalFiles, fetchCHSuccessData);
              _files.push(media);
            }

            if (item.image) {
              let image = fetchImage(item.image, dispatch, totalFiles, fetchCHSuccessData);
              _images.push(image);
            }
          });

          response.data["media"] = {
            images: _images,
            files: _files
          };
        } else {
          dispatch(
            createAction(FETCH_CHA_SCENES_DATA_SUCCESS, { activity, scene, data: response.data })
          );
        }
      })
      .catch(error => {
        dispatch(createAction(FETCH_CHA_SCENES_DATA_FAILURE));
        //throw(error);
      });
  };
};

let counter = 0;

// Below two sections removed by KJW and modified temporarily. We will remove permanently later.
/*
export const fetchImage = (path, dispatch, from, fetchCHSuccessData) => {
  const img = new Image();
  img.src = `/api/get.php?file=${path}`; // by setting an src, you trigger browser download
  // img.addEventListener('load', fileDone(dispatch, file,from), false);
  // img.addEventListener('complete', fileDone(dispatch, file,from), false);
  img.onload = function() {
    dispachFileSuccess(path, dispatch, from, fetchCHSuccessData);
  };
  img.onerror = function() {
    dispachFileSuccess(path, dispatch, from, fetchCHSuccessData);
  };
  return img;
};

export const fetchMedia = (path, dispatch, from, fetchCHSuccessData) => {
  let audio = new Audio(`/api/get.php?file=${path}`);
  audio.oncanplaythrough = function() {
    dispachFileSuccess(path, dispatch, from, fetchCHSuccessData);
  };
  audio.onerror = function() {
    dispachFileSuccess(path, dispatch, from, fetchCHSuccessData);
  };
  return audio;
};
*/

//Above Removed by KJW

export const fetchImage = (path, dispatch, from, fetchCHSuccessData) => {
  let img = `/api/get.php?file=${path}`;
  dispachFileSuccess(path, dispatch, from, fetchCHSuccessData);
  return img;
};

export const fetchMedia = (path, dispatch, from, fetchCHSuccessData) => {
  let audio = `/api/get.php?file=${path}`;
  dispachFileSuccess(path, dispatch, from, fetchCHSuccessData);

  return audio;
};


export const dispachFileSuccess = async (path, dispatch, from, fetchCHSuccessData) => {
  counter++;

  dispatch({
    type: FETCH_CHA_FILE_SUCCESS,
    loading: { file: counter, from }
  });

  if (counter === from || counter >= from) {
    counter = 0;
    await dispatch(createAction(FETCH_CHA_SCENES_DATA_SUCCESS, fetchCHSuccessData));
    dispatch({
      type: FETCH_CHA_FILE_SUCCESS,
      loading: {
        file: 0,
        from: 10
      }
    });
  }
};

export const getData = (array, dispatch, totalFiles, fetchCHSuccessData) => {
  let images = [];
  let audios = [];
  array.map((item, i) => {
    if (item.image) {
      let image = fetchImage(item.image, dispatch, totalFiles, fetchCHSuccessData);
      images.push(image);
    }
    if (item.audio) {
      let media = fetchMedia(item.audio, dispatch, totalFiles, fetchCHSuccessData);
      audios.push(media);
    }
  });
  return {
    images: images,
    files: audios
  };
};
