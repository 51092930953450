import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Tab, Tabs, Sonnet } from 'react-bootstrap'; //for lyrics tabs

const Song1 = () => {

	var mobile = (
		typeof window.orientation !== "undefined")
		|| (navigator.userAgent.indexOf('IEMobile') !== -1
	);  //check if mobile device

	if(mobile)
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>O Canada - kā-kānātahk by Laura Burnouf, Edie Venne and Allen Morrow</p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/01 ka-kanatahk.mp3"
					/>
				</div>

				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div>
						<Tabs defaultActiveKey="sro" id="uncontrolled-tab-example">
							<Tab eventKey="sro" title="SRO" default>
									<div className="card">
										<div className="card-body">
											<h4 className="audio-text-title card-title">kā-kanātahk</h4>
											<div className="audio-text card-text">
												<div>kā-kanātahk</div>
												<div>kīkinaw, kitaskīnaw</div>
												<div>sākihitowin</div>
												<div>kicawāsimisak</div>
												<div>kimamihcihāwak</div>
												<div>mamihcimowak</div>
												<div>kihci-kīwītinohk</div>
												<div>ōta ohci, nītī isko</div>
												<div>kasītoskātinān</div>
												<div>nohtāwīnān</div>
												<div>kanawīthihta</div>
												<div>kā-kanātahk nitaskīnān</div>
												<div>ōma</div>
												<div>kā-kanātahk</div>
												<div>nitaskīnān</div>
												<div>tāpwī</div>
											</div>
										</div>
								</div>
							</Tab>
							<Tab eventKey="syllabics" title="Syllabics">
									<div className="card">
										<div className="card-body">
											<h4 className="audio-text-title card-title">ᑳ ᑲᓈᑕᕽ</h4>
											<div className="audio-text card-text">
												<div>ᑳ ᑲᓈᑕᕽ</div>
												<div>ᑫᑭᓇᐤ, ᑭᑕᐢᑫᓇᐤ</div>
												<div>ᓵᑭᐦᐃᑐᐏᐣ</div>
												<div>ᑭᒐᐚᓯᒥᓴᐠ</div>
												<div>ᑭᒪᒥᐦᒋᐦᐋᐘᐠ</div>
												<div>ᒪᒥᐦᒋᒧᐘᐠ</div>
												<div>ᑭᐦᒋ ᑫᐍᑎᓄᕽ</div>
												<div>ᐆᑕ ᐅᐦᒋ, ᓀᑌ ᐃᐢᑯ</div>
												<div>ᑲᓭᑐᐢᑳᑎᓈᐣ</div>
												<div>ᓄᐦᑖᐍᓈᐣ</div>
												<div>ᑲᓇᐍᖨᐦᑕ</div>
												<div>ᑳ ᑲᓈᑕᕽ ᓂᑕᐢᑫᓈᐣ</div>
												<div>ᐆᒪ</div>
												<div>ᑳ ᑲᓈᑕᕽ</div>
												<div>ᓂᑕᐢᑫᓈᐣ</div>
												<div>ᑖᐻ</div>

											</div>
										</div>
									</div>
							</Tab>
							<Tab eventKey="english" title="English">
									<div className="card">
										<div className="card-body">
											<h4 className="audio-text-title card-title">O Canada</h4>
											<div className="audio-text card-text">
												<div>O Canada</div>
												<div>Our home, our land</div>
												<div>Love</div>
												<div>Your children</div>
												<div>You're proud of them</div>
												<div>They are proud</div>
												<div>The great north</div>
												<div>From here to there</div>
												<div>We will take care of you</div>
												<div>Our Father</div>
												<div>Keep</div>
												<div>Our sacred land</div>
												<div>This sacred land,</div>
												<div>Our land</div>
												<div>True</div>
											</div>
										</div>
									</div>
							</Tab>
						</Tabs>
					</div>
				</div>
			</div>
		);
	}
	else
	{
		return (
			<div>
				<div className="audio-attribution">
					<p>O Canada - kā-kānātahk by Laura Burnouf, Edie Venne and Allen Morrow</p>
				</div>
				<div>
					<AudioPlayer
						preload="none"
						src="/api/get.php?file=media/cree_audio/audio/01 ka-kanatahk.mp3"
					/>
				</div>

				<div className="container-fluid" style={{marginTop: "10px"}}>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">kā-kanātahk</h4>
									<div className="audio-text card-text">
										<div>kā-kanātahk</div>
										<div>kīkinaw, kitaskīnaw</div>
										<div>sākihitowin</div>
										<div>kicawāsimisak</div>
										<div>kimamihcihāwak</div>
										<div>mamihcimowak</div>
										<div>kihci-kīwītinohk</div>
										<div>ōta ohci, nītī isko</div>
										<div>kasītoskātinān</div>
										<div>nohtāwīnān</div>
										<div>kanawīthihta</div>
										<div>kā-kanātahk nitaskīnān</div>
										<div>ōma</div>
										<div>kā-kanātahk</div>
										<div>nitaskīnān</div>
										<div>tāpwī</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">ᑳ ᑲᓈᑕᕽ</h4>
									<div className="audio-text card-text">
										<div>ᑳ ᑲᓈᑕᕽ</div>
										<div>ᑫᑭᓇᐤ, ᑭᑕᐢᑫᓇᐤ</div>
										<div>ᓵᑭᐦᐃᑐᐏᐣ</div>
										<div>ᑭᒐᐚᓯᒥᓴᐠ</div>
										<div>ᑭᒪᒥᐦᒋᐦᐋᐘᐠ</div>
										<div>ᒪᒥᐦᒋᒧᐘᐠ</div>
										<div>ᑭᐦᒋ ᑫᐍᑎᓄᕽ</div>
										<div>ᐆᑕ ᐅᐦᒋ, ᓀᑌ ᐃᐢᑯ</div>
										<div>ᑲᓭᑐᐢᑳᑎᓈᐣ</div>
										<div>ᓄᐦᑖᐍᓈᐣ</div>
										<div>ᑲᓇᐍᖨᐦᑕ</div>
										<div>ᑳ ᑲᓈᑕᕽ ᓂᑕᐢᑫᓈᐣ</div>
										<div>ᐆᒪ</div>
										<div>ᑳ ᑲᓈᑕᕽ</div>
										<div>ᓂᑕᐢᑫᓈᐣ</div>
										<div>ᑖᐻ</div>

									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card">
								<div className="card-body">
									<h4 className="audio-text-title card-title">O Canada</h4>
									<div className="audio-text card-text">
										<div>O Canada</div>
										<div>Our home, our land</div>
										<div>Love</div>
										<div>Your children</div>
										<div>You're proud of them</div>
										<div>They are proud</div>
										<div>The great north</div>
										<div>From here to there</div>
										<div>We will take care of you</div>
										<div>Our Father</div>
										<div>Keep</div>
										<div>Our sacred land</div>
										<div>This sacred land,</div>
										<div>Our land</div>
										<div>True</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
};

export default Song1;
