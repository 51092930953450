import Axios from 'axios';

// API URL
//const apiUrl = 'http://57c62fdcc1fc8711008f2a7e.mockapi.io/api/';
const apiUrl = '/api/catalog/';
// Sync Action
export const fetchCatlogSuccess = (catalog) => {
  return {
    type: 'FETCH_CATALOG_SUCCESS',
    catalog
  }
};


//Async Action
export const fetchCatalog = () => {
	console.log("KJW 11111")
  // Returns a dispatcher function
  // that dispatches an action at a later time
  return (dispatch) => {
	console.log("KJW 2222")
    // Returns a promise
    return Axios.get(apiUrl)
      .then(response => {
        // Dispatch another action
        // to consume data
	console.log("KJW 33333")

        dispatch(fetchCatlogSuccess(response.data))
      })
      .catch(error => {
	console.log("ERROR")
	console.log(error)
        throw(error);
      });
  };
};


