import React from 'react';
import Axios from 'axios';
import '../main.css';
import './SixSeasons.css';
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
import ProgressBar from "../ProgressBar/ProgressBar";
import NewProgressBar from "../ProgressBar/newProgressBar";


//KWNOTE: We should get some better/widescreen pictures

class SixSeasons extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: "winter",
            translit: "pipon",
            translate: "ᐱᐳᐣ",
            data: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.data) {
            nextProps.fetchData();
        }

        if (nextProps.data !== this.props.data) {
            console.log('NOT EQUAL SO UPDATING');
        }
    }

    componentDidMount() {
        if (!this.props.data) {
            this.props.fetchData();
        }

    }

    changeSelected(seasonName, translit, translate) {
        this.setState({selected: seasonName});
        this.setState({translit: translit});
        this.setState({translate: translate});

        let el = document.getElementById("animate");
        el.classList.remove("animate");
        setTimeout(function () {
            el.classList.add("animate");
        }, 10);

        this.playAudio('/api/get.php?file=media/sixSeasons/audio/' + seasonName + '.mp3');
    }

    playAudio(path) {
        let audio = new Audio(path);
        audio.play();
    }

    render() {
        const data = this.props.data;
        if (data) {
            const pathToImg = "/api/get.php?file=media/sixSeasons/images/";
            let seasonList = "";
            let {selected, translate, translit} = this.state;

            if (Object.keys(data).length > 0) {
                seasonList = data.data_set[0].data.map(season => (
                    <li
                        key={season.seasonName}
                        className={selected === season.seasonName ? "selected" : ""}
                        onClick={this.changeSelected.bind(this, season.seasonName, season.translit, season.translate)}
                    >
                        {season.seasonName}
                    </li>
                ));
            }

            return (
                <div className='c-wrapper'>
                    <div className='c-container'>
                       
                        <div className='c-content'>
                            <div className="seasons">
                                <h3>Six Seasons</h3>
                                <div style={{
                                    maxWidth: '800px',
                                    margin: '0px auto',
                                    backgroundColor: '#000',
                                    border: '1px solid black'
                                }}>
                                    <div className="seasons-translates">
                                        <div>{translit}</div>
                                        <div>{translate}</div>
                                    </div>
                                    <div className="seasons-content">
                                        <div className="seasons-list-block">
                                            <ul>
                                                {seasonList}
                                            </ul>
                                        </div>
                                        <div className="seasons-main-content">
                                            <img id="animate"
                                                 src={this.state.selected ? pathToImg + this.state.selected + ".jpg" : pathToImg + "winter.gif"}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            );
        } else {
            return (
                <NewProgressBar/>
            )
        }
    }
}

const CATEGORY = "sixSeasons";

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.ch[CATEGORY]
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData() {
        dispatch(chActions.fetchCH(CATEGORY));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SixSeasons);
