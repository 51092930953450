import React from "react";
import { connect } from "react-redux";
import * as chActions from "../../../actions/chActions";
//import ProgressBar from "../../ProgressBar/ProgressBar";
//import NewProgressBar from "../../ProgressBar/newProgressBar";
import KWPreload from "../../../KWPreload/KWPreload";
import "./birdComponent.css";
import { Link } from "react-router-dom";

class ClickAndHear extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      current_category: 0,
      audio_icon_color: "#0000ff"
    };
    this.playAudio = this.playAudio.bind(this);
    this.doneAudio = this.doneAudio.bind(this);
  //  this.playNow = this.playNow.bind(this);

    this.audio2 = new Audio();

    this.changeCategory = this.changeCategory.bind(this);
  }

  changeSelected(selected) {
    // $("html, body").animate({ scrollTop: 0 }, "slow");
    this.setState({ selected: selected });
  }

/*
  playAudio(path) {
    let audio = new Audio(path);
    audio.play();
  }
*/

  doneAudio(){
        this.setState({audio_icon_color: "#0000ff"}, ()=> {
		console.log("DONE DONE DONE")
		console.log(this.state)
	})
	console.log("DONE AUDIO")
  }

/*
  playNow(){
	this.audio2.play()
  }
*/

  playAudio(path) {
    //let audio = new Audio(`${path}`);

    this.audio2.src = `${path}`
    //audio.play();
    this.setState({audio_icon_color: "#00dddd"})
    this.audio2.play()

    this.audio2.onended = this.doneAudio


  }

/*
  playAudio(path) {
    //let audio = new Audio(`${path}`);
    this.audio2.src = `${path}`
    this.audio2.load()

    //Can we move these canplaythrough and onended to constructor?
    // Fish ending in th do not have proper syllabics in iphone
    //Can we do a check not to replace / load if src is the same as src
    this.audio2.canplaythrough = this.playNow
    //audio.play();
    this.setState({audio_icon_color: "#00dddd"})
    //this.audio2.play()

    this.audio2.onended = this.doneAudio
  }
*/

  scrollToTop() {
    if (this.refs.scroller) {
      this.refs.scroller.scrollTop = 0;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.data) {
      nextProps.fetchData();
    }

    if (nextProps.data !== this.props.data) {
      this.scrollToTop();

      this.setState({
        current_category: 0,
        selected: 0
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !this.props.data ||
      !nextProps.data ||
      nextProps.data.title1 !== this.props.data.title1 ||
      nextState.current_category !== this.state.current_category ||
      nextState.selected !== this.state.selected ||
      nextState.audio_icon_color !== this.state.audio_icon_color
    );
  }

  componentDidMount() {
    this.scrollToTop();

    if (!this.props.data) {
      this.props.fetchData();
    }
  }

  changeCategory(category) {
    this.scrollToTop();
    this.setState({ current_category: category, selected: 0 });
  }

  render() {
    const data = this.props.data;

	console.log("STTTTTT")
	console.log(this.state)
	console.log(data)

    if (data) {
      const cc = this.state.current_category;
      const dataSet = data.data_set;
      const dataSetL = data.menu ? data.menu.length : dataSet.length;
      const selected = dataSet[cc].data[this.state.selected];
      const playAudio = (audio = selected.audio) =>
        this.playAudio.bind(null, `/api/get.php?file=${audio}`);
      let categories = "";

      if (!dataSetL) {
        return <div>No data...</div>;
      } else if (dataSetL > 1) {
        if (data.menu) {
          categories = data.menu.map((item, i) => {
            if (this.props.match.params.val === item.title1) {
              return (
                <div key={i} className="tab-slider--trigger active">
                  <Link style={{ color: "#fff", textDecoration: "none" }} to={"/ch/" + item.title1}>
                    {item.name}
                  </Link>
                </div>
              );
            } else {
              return (
                <div key={i} className="tab-slider--trigger">
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to={"/ch/" + item.title1}>
                    {item.name}
                  </Link>
                </div>
              );
            }
          });
        } else {
          categories = dataSet.map((item, i) => {
            if (cc === i) {
              return (
                <div
                  key={i}
                  onClick={() => this.changeCategory(i)}
                  className="tab-slider--trigger active">
                  {item.title1}
                </div>
              );
            } else {
              return (
                <div key={i} onClick={() => this.changeCategory(i)} className="tab-slider--trigger">
                  {item.title1}
                </div>
              );
            }
          });
        }
      }


	console.log("OOOOOGA")
	console.log(this.props)

      return (
        <div>
          <section className="birds-section">
            <div className="birds-box">
              <div className="container">
                <div className="page-title">
                  <h1>{data.title1}</h1>
                </div>
                <div className="tab-slider--nav">
                  {!categories.length == 0 && <ul className="tab-slider--tabs">{categories}</ul>}
                </div>

		<KWPreload data={this.props.data.data_set[0].data}>

			<div className="tab-slider--container">
			  <div id="tab1" className="tab-slider--body">
			    <div className="rows">
			      <div className="cols-40">
				<div className="birds-info">
				  <h4>{selected.title1}</h4>
				  <div className="line" />
				  <h3 onClick={playAudio(selected.audio)} className="pointer-on-hover">
				    <strong>{selected.title2}</strong>
				  </h3>
				  <p onClick={playAudio(selected.audio)} className="pointer-on-hover">
				    {selected.title3}
				  </p>
				  <i
				    className="fas fa-volume-up pointer-on-hover"
				    style={{color: this.state.audio_icon_color}}
				    onClick={playAudio(selected.audio)}
				  />
				</div>
			      </div>
			      <div className="cols-60">
				<div className="birds-img">
				  <img
				    src={`/api/get.php?file=${selected.image}`}
				    alt=""
				    className="img-fluid pointer-on-hover"
				    onClick={playAudio(selected.audio)}
				  />
				</div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="all-birds-img">
			  <div className="select-bird">
				{console.log("OOO")}
				{console.log(dataSet)}
			    {dataSet[cc].data.map((b, i) => (
			      <div key={i}>
				<div
				  className={` s-bird ${this.state.selected === i && "bird-active"} ${this
				    .props.match.params.val == "fish" && "img_fish"} `}
				  onClick={playAudio(b.audio)}
				  onClick={() => this.changeSelected(i)}>
				  <img onClick={playAudio(b.audio)} src={`/api/get.php?file=${b.image}`} style={{height: "auto", width: "auto", maxHeight: "55px", maxWidth: "110px"}}/>

				</div>
			      </div>
			    ))}
			  </div>
                	</div>
		  </KWPreload>
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      return <div>Loading</div>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
console.log("Map State")
console.log(state)
console.log(ownProps)
  return {
    data: state.ch[ownProps.match.params.val]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchData() {
    dispatch(chActions.fetchCH(ownProps.match.params.val));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClickAndHear);
