'use strict'

import {combineReducers} from 'redux';

import {
  CHA_SCENE_SELECTED,
  FETCH_CHA_SCENES_REQUEST, FETCH_CHA_SCENES_SUCCESS, FETCH_CHA_SCENES_FAILURE,
  FETCH_CHA_SCENES_DATA_REQUEST, FETCH_CHA_SCENES_DATA_SUCCESS,
  FETCH_CHA_SCENES_DATA_FAILURE, FETCH_CHA_FILE_SUCCESS
} from '../actions/actionTypes'

const selectedSceneReducer = (state = {}, action) => {
  switch (action.type) {
    case CHA_SCENE_SELECTED:
      return {...state, [action.data.activity]: action.data.data}
    default:
      return state;
  }
}

const scenesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CHA_SCENES_REQUEST:
      return {...state, isFetching: true}
    case FETCH_CHA_SCENES_SUCCESS:
      return {...state, isFetching: false, [action.data.activity]: action.data.data};
    case FETCH_CHA_SCENES_FAILURE:
      return {...state, isFetching: false}
    default:
      return state;
  }
}

const scenesDataReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CHA_SCENES_DATA_REQUEST:
      return {...state, isFetching: true}
    case FETCH_CHA_SCENES_DATA_SUCCESS:
      const currentActivityScenesData = state[action.data.activity] || {}
      return {...state, isFetching: false, [action.data.activity]: {...currentActivityScenesData, [action.data.scene]: action.data.data}};
    case FETCH_CHA_SCENES_DATA_FAILURE:
      return {...state, isFetching: false}
    case FETCH_CHA_FILE_SUCCESS:
      return {...state, isFetching: true, ['loading'] : action.loading}
    default:
      return state;
  }
};

export default combineReducers({
  selectedScene: selectedSceneReducer,
  scenes: scenesReducer,
  scenesData: scenesDataReducer,
})