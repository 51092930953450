import React from 'react';
import '../main.css';
import './FemaleKinshipTerms.css';
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
//import ProgressBar from "../ProgressBar/ProgressBar";
import NewProgressBar from "../ProgressBar/newProgressBar";

class FemaleKinshipTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nimama: 0,
            nipapa: 0,
            selected: 0
        };
        this.playAudio = this.playAudio.bind(this);
    }

    changeSelected(selected) {
        this.setState({selected: selected});
        if (selected === 12 || selected === 22) {
            this.setState({
                nimama: 1,
                nipapa: 0
            })
        } else if (selected === 13 || selected === 23) {
            this.setState({
                nipapa: 1,
                nimama: 0,
            })
        } else {
            this.setState({
                nimama: 0,
                nipapa: 0
            })
        }
    }

    playAudio(path) {
        let audio = new Audio(path);
        audio.play();
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.data) {
            nextProps.fetchData();
        }

        if (nextProps.data !== this.props.data) {
            console.log('NOT EQUAL SO UPDATING');

            this.setState({
                nimama: 0,
                nipapa: 0,
                selected: 0
            });
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        return (!this.props.data
            || !nextProps.data
            || nextProps.data.title1 !== this.props.data.title1
            || nextState.selected !== this.state.selected);
    }

    componentDidMount() {
        if (!this.props.data) {
            this.props.fetchData();
        }

    }

    render() {
        const data = this.props.data;
        if (data) {

            console.log("loading", this.state.loading);

            const dataSet = data.data_set;
            const dataSetL = dataSet.length;
            const femaleKinshiptTerms = dataSet[0].data;

            const selected = this.state.selected ? femaleKinshiptTerms[this.state.selected] : 0;
            const playAudio = (audio = selected.audio) => this.playAudio.bind(null, `/api/get.php?file=${audio}`);
            let categories = '';
            if (!dataSetL) {
                return <div>No data...</div>;
            }
            return (
                <div className='c-wrapper female-kinship-terms'>
                    <div className='c-container'>
                        <div className='inner-container'>
                            <div className='black-board'>
                                {femaleKinshiptTerms.filter((el, i) => i < 22).map((b, i) =>
                                    <div key={i} className='action' id={'elem' + (i)}
                                         onClick={() => this.changeSelected(i)}>
                                        <a onClick={playAudio(b.audio)}> {b.title1} </a>
                                    </div>
                                )}
                                {this.state.nimama &&
                                (<div className="nimama">
                                    {femaleKinshiptTerms.filter((el, i) => i === 22).map((b, i) =>

                                        <div key={22} className='action' id={'elem' + (22)}
                                             onClick={() => this.changeSelected(22)}>
                                            <a onClick={playAudio(b.audio)}> {b.title1} </a>
                                        </div>
                                    )}
                                    <div className='action' id='elem24'>Contemporary term</div>
                                    <div className='line line18'/>
                                    <div className='line line19'/>
                                    <div className='line line20'/>
                                </div>)
                                }
                                {this.state.nipapa &&
                                <div className="nipapa">
                                    {femaleKinshiptTerms.filter((el, i) => i === 23).map((b, i) =>

                                        <div key={23} className='action' id={'elem' + (23)}
                                             onClick={() => this.changeSelected(23)}>
                                            <a onClick={playAudio(b.audio)}> {b.title1} </a>
                                        </div>
                                    )}
                                    <div className='line line17'/>
                                    <div className='line line21'/>
                                    <div className='line line22'/>
                                    <div className='action' id='elem25'>Contemporary term</div>
                                </div>
                                }
                                <div className="">
                                    {!selected &&
                                    (<div className=" image-container initial-image-container">
                                        <div className="title1">Female Kinship Terms</div>
                                        <div className="title2">First person possessive</div>
                                    </div>)
                                    }
                                    {selected && (
                                        <div>
                                            <div className="image-container">
                                                <div className=" selected-image-container">
                                                    {selected.title2 &&
                                                    <span
                                                        className="selected-title2"> Also means "{selected.title2}"</span>
                                                    }
                                                    <img width="290"
                                                         src={`/api/get.php?file=${selected.image}`}
                                                    />
                                                </div>

                                            </div>
                                            <div className="selected-title">
                                                <p className="selected-title4">{selected.title4}</p>
                                                <p className="selected-title3">{selected.title3}</p>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>
                                <div className='moms-side'>Mom's Side</div>
                                <div className='dads-side'>Dad's Side</div>
                                <div className='line line1'/>
                                <div className='line line2'/>
                                <div className='line line3'/>
                                <div className='line line4'/>
                                <div className='line line5'/>
                                <div className='line line6'/>
                                <div className='line line7'/>
                                <div className='line line8'/>
                                <div className='line line9'/>
                                <div className='line line10'/>
                                <div className='line line11'/>
                                <div className='line line12'/>
                                <div className='line line13'/>
                                <div className='line line14'/>
                                <div className='line line15'/>
                                <div className='line line16'/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <NewProgressBar/>
            );
        }
    }
}

const CATEGORY = "female_kinship_terms";

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.ch[CATEGORY]
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData() {
        dispatch(chActions.fetchCH(CATEGORY));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FemaleKinshipTerms);
