// For handling array of wild_animals

import * as actionTypes from '../actions/actionTypes'

export const chReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_CH_SUCCESS:
	  // console.log('FETCH_CH_SUCCESS state', state)
	  // console.log('FETCH_CH_SUCCESS action.activity', action.activity)
	  // console.log('FETCH_CH_SUCCESS action.data', action.data)
    //   console.log('FETCH_CH_SUCCESS state.loading', state.loading);
          return { ...state, [action.data.activity]: action.data.data};
      case actionTypes.FETCH_FILE_SUCCESS:
      // console.log('FETCH_FILE_SUCCESS action.loading', action.loading);
          return { ...state, ['loading'] : action.loading};
    default:
          return state;
  }
};
