import React from 'react';
import BaseCmpWithHover from '../common/baseCmpWithHover/BaseCmpWithHover';
import './ClassroomObjects.css';
import * as chActions from "../../actions/chActions";
import {connect} from "react-redux";
import ProgressBar from "../ProgressBar/ProgressBar";
import NewProgressBar from "../ProgressBar/newProgressBar";

class ClassroomObjects extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      needToShowCabinetContent: false,
      needToShowTableContent: false,
    };

    this.showCabinetContent = this.showCabinetContent.bind(this);
    this.showTableContent = this.showTableContent.bind(this);
    this.hideCabinetContent = this.hideCabinetContent.bind(this);
    this.hideTableContent = this.hideTableContent.bind(this);
  }

  showCabinetContent (e) {
    this.setState({
      needToShowCabinetContent: true
    });

    const mainSceneEl = document.getElementById('c-main-scene');
    const cabinetSceneEl = document.getElementById('c-cabinet-scene');

    cabinetSceneEl.classList.remove('c-hidden');
    mainSceneEl.classList.add('c-hidden');
  }

  showTableContent (e) {
    this.setState({
      needToShowTableContent: true
    });

    const mainSceneEl = document.getElementById('c-main-scene');
    const desktopSceneEl = document.getElementById('c-desktop-scene');

    desktopSceneEl.classList.remove('c-hidden');
    mainSceneEl.classList.add('c-hidden');
  }

  hideCabinetContent (e) {
    this.setState({
      needToShowCabinetContent: false
    });

    const mainSceneEl = document.getElementById('c-main-scene');
    const cabinetSceneEl = document.getElementById('c-cabinet-scene');

    mainSceneEl.classList.remove('c-hidden');
    cabinetSceneEl.classList.add('c-hidden');
  }

  hideTableContent (e) {
    this.setState({
      needToShowCabinetContent: false
    });

    const mainSceneEl = document.getElementById('c-main-scene');
    const desktopSceneEl = document.getElementById('c-desktop-scene');

    mainSceneEl.classList.remove('c-hidden');
    desktopSceneEl.classList.add('c-hidden');
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.data) {
      nextProps.fetchData();
    }

    if (nextProps.data !== this.props.data) {
      console.log('NOT EQUAL SO UPDATING');


    }
  }

  componentDidMount() {
    if (!this.props.data) {
      this.props.fetchData();
    }


  }

  render () {
    const data = this.props.data;
    if (data) {
    return (
      <div>
        <div>

          <div className='main-outer-wrapper'>
            <div className='main-inner-wrapper'>
              <h3 className='main-title-container'>Classroom Objects</h3>

              <div className='с-classroom-wrapper'>

                {/* Main scene */}
                <div id='c-main-scene'>
                  <div className='c-outer-wrapper'>
                    <div className='c-translation'>
                      <div name='text'></div>
                      <div name='syllabic'></div>
                    </div>
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/classroom_objects/images/bg1.png' alt=''/>

                      <BaseCmpWithHover
                        identifier='c-cabinet'
                        hintText={['Click cabinet', 'to see contents']}
                        onClickAction={this.showCabinetContent}
                      />

                      <BaseCmpWithHover
                        identifier='c-door'
                        hintText='Door'
                        text='iskwāhtīm'
                        syllabic='ᐃᐢᒁᐦᑌᒼ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/door.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-mat'
                        hintText='Mat'
                        text='aspistīsimon'
                        syllabic='ᐊᐢᐱᐢᑌᓯᒧᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/mat.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-clock'
                        hintText='Clock'
                        text='pīsimohkān'
                        syllabic='ᐯᓯᒧᐦᑳᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/clock.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-chalkboard'
                        hintText='Chalkboard'
                        text='masinahikan-āpisk'
                        syllabic='ᒪᓯᓇᐦᐃᑲᐣ-ᐋᐱᐢᐠ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/chalkboard.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-chalkboard-eraser'
                        hintText='Chalkboard eraser'
                        text='kitistahikan'
                        syllabic='ᑭᑎᐢᑕᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/chalkboard-eraser.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-chalk'
                        hintText='Chalk'
                        text='wāpiskasinī-wācikos'
                        syllabic='ᐚᐱᐢᑲᓯᓀ-ᐚᒋᑯᐢ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/chalk.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-window'
                        hintText='Window'
                        text='wāsīnamān'
                        syllabic='ᐚᓭᓇᒫᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/window.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-teachers-desk'
                        hintText="Teacher's desk"
                        text='okiskinwahamākīw- omasinahikīwapiwin'
                        syllabic='ᐅᑭᐢᑭᓌᐦᐊᒫᑫᐤ- ᐅᒪᓯᓇᐦᐃᑫᐘᐱᐏᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/teachers-desk.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-book-shelf'
                        hintText='Book shelf'
                        text='akocikanis'
                        syllabic='ᐊᑯᒋᑲᓂᐢ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/book-shelf.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-book'
                        hintText='Book'
                        text='masinahikan'
                        syllabic='ᒪᓯᓇᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/book.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-desktop'
                        hintText={['Click table', 'to see contents']}
                        onClickAction={this.showTableContent}
                      />

                      <BaseCmpWithHover
                        identifier='c-chair'
                        hintText='Chair'
                        text='tihtapiwin'
                        syllabic='ᑎᐦᑕᐱᐏᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/chair.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-students-desk'
                        hintText="Student's desk"
                        text='kiskinwahamākan- omasinahikīwapiwin'
                        syllabic='ᑭᐢᑭᓌᐦᐊᒫᑲᐣ- ᐅᒪᓯᓇᐦᐃᑫᐘᐱᐏᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/students-desk.mp3'
                      />
                    </div>
                  </div>
                </div>

                {/* Scene with cabinet contents*/}
                <div id='c-cabinet-scene' className='c-hidden'>
                  <div className='c-outer-wrapper'>
                    <div className='c-translation'>
                      <div name='text'></div>
                      <div name='syllabic'></div>
                    </div>
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/classroom_objects/images/bg2.png' alt=''/>

                      <div id='c-empty-cabinet'/>

                      <div
                        id='c-back-to-classroom-objects'
                        onClick={this.hideCabinetContent}
                      />

                      <BaseCmpWithHover
                        identifier='c-record-player'
                        hintText='Record player'
                        text='kitohcikan'
                        syllabic='ᑭᑐᐦᒋᑲᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/record-player.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-paint'
                        hintText='Paint'
                        text='sisopīkahikan'
                        syllabic='ᓯᓱᐯᑲᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/paint.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-paint-brush'
                        hintText='Paint brush'
                        text='sisopīkahikanāhtik'
                        syllabic='ᓯᓱᐯᑲᐦᐃᑲᓈᐦᑎᐠ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/paint-brush.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-coloured-pencil'
                        hintText='Coloured pencil'
                        text='kā-pītosinākosit masinahikanāhtik'
                        syllabic='ᑳ-ᐯᑐᓯᓈᑯᓯᐟ ᒪᓯᓇᐦᐃᑲᓈᐦᑎᐠ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/coloured-pencil.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-glue'
                        hintText='Glue'
                        text='pasakwahikan'
                        syllabic='ᐸᓴᑿᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/glue.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-scissors'
                        hintText='Scissors'
                        text='paskwahamātowin'
                        syllabic='ᐸᐢᑿᐦᐊᒫᑐᐏᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/scissors.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-tape-recorder'
                        hintText='Tape recorder'
                        text='ayamiwin kōtinikātīk kitohcikanis'
                        syllabic='ᐊᔭᒥᐏᐣ ᑰᑎᓂᑳᑌᐠ ᑭᑐᐦᒋᑲᓂᐢ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/tape-recorder.mp3'
                      />

                    </div>
                  </div>
                </div>

                {/* Scene with desktop contents*/}
                <div id='c-desktop-scene' className='c-hidden'>
                  <div className='c-outer-wrapper'>
                    <div className='c-translation'>
                      <div name='text'></div>
                      <div name='syllabic'></div>
                    </div>
                    <div className='c-inner-wrapper'>
                      <img className='c-bg' src='api/get.php?file=media/other/classroom_objects/images/bg2.png' alt=''/>

                      <div
                        id='c-back-to-classroom-objects'
                        onClick={this.hideTableContent}
                      />

                      <BaseCmpWithHover
                        identifier='c-empty-desktop'
                        hintText='Table'
                        text='mīcisowināhtik'
                        syllabic='ᒣᒋᓱᐏᓈᐦᑎᐠ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/desktop.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-paper'
                        hintText='Paper'
                        text='masinahikanīkin'
                        syllabic='ᒪᓯᓇᐦᐃᑲᓀᑭᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/paper.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-eraser'
                        hintText='Eraser'
                        text='kitistahikan'
                        syllabic='ᑭᑎᐢᑕᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/eraser.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-pencil'
                        hintText='Pencil'
                        text='masinahikanāhtik'
                        syllabic='ᒪᓯᓇᐦᐃᑲᓈᐦᑎᐠ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/pencil.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-ruler'
                        hintText='Ruler'
                        text='cipahikanāhcikos'
                        syllabic='ᒋᐸᐦᐃᑲᓈᐦᒋᑯᐢ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/ruler.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-crayon'
                        hintText='Crayon'
                        text='masinipīhikan'
                        syllabic='ᒪᓯᓂᐯᐦᐃᑲᐣ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/crayon.mp3'
                      />

                      <BaseCmpWithHover
                        identifier='c-metre-stick'
                        hintText='Metre stick'
                        text='tipahikanāhtik'
                        syllabic='ᑎᐸᐦᐃᑲᓈᐦᑎᐠ'
                        audio='api/get.php?file=media/other/classroom_objects/audio/metre-stick.mp3'
                      />

                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    );} else {
      return (
          <NewProgressBar/>
      );
    }
  }
}

const CATEGORY = "classroom_objects";

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.ch[CATEGORY]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchData() {
    dispatch(chActions.fetchCH(CATEGORY));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomObjects);

