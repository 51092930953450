import React from "react";
import { connect } from "react-redux";
import * as chaActions from "../../../actions/chaActions";
import PropTypes from "prop-types";
import "../click_and_hear/birdComponent.css";
import selectn from "selectn";
//import ProgressBar from "../../ProgressBar/ProgressBar";
import KWPreload from "../../../KWPreload/KWPreload";

class NewPage extends React.Component {
  constructor(props) {
    super(props);
    this.refToItemsDiv = React.createRef();
    this.state = {
      selected: 0,
      currentWord: "",
      audio_icon_color: "#0000ff"
    };
    this.playAudio = this.playAudio.bind(this);
    this.doneAudio = this.doneAudio.bind(this);

    this.audio2 = new Audio();
  }

  changeSelected(selected) {
    // $("html, body").animate({ scrollTop: 0 }, "slow");
    this.setState({ selected: selected });
  }
  componentDidMount() {
    if (this.props.location.state !== undefined) {
      this.props.selectScene(this.props.location.state.id);
    }
    if (!this.props.scenes) {
      this.props.fetchScenes();
    } else if (!this.props.selectedScene) {
      this.selectFirstScene(this.props.scenes);
    } else if (!this.props.sceneData) {
      this.props.fetchData(this.props.selectedScene);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: 0
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.key !== prevProps.location.key) {
      this.props.fetchScenes();
    }
    if (this.props.scenes !== prevProps.scenes && this.props.scenes) {
      this.selectFirstScene(this.props.scenes);
    }
    if (this.props.selectedScene !== prevProps.selectedScene && !this.props.sceneData) {
      this.props.fetchData(this.props.selectedScene);
    }
  }

  selectFirstScene = scenes => {
    const scenesData = this.getScenesData(this.props.scenes);
    if (scenes && !Array.isArray(scenesData)) {
      console.error("scenesData must be an array");
      return;
    }
    const firstScene = scenesData[0];
    //const firstScene = scenesData[1]
    if (firstScene) {
      const tabToBeSelected =
        this.props.location.state !== undefined ? this.props.location.state.id : firstScene.id;

      this.props.selectScene(tabToBeSelected);
    } else {
      console.error("there is no scene for current activity");
    }
  };

  doneAudio(){
	this.setState({audio_icon_color: "#0000ff"})
  }

  playAudio(path) {
    //let audio = new Audio(`${path}`);
    this.audio2.src = `${path}`
    //audio.play();
    this.setState({audio_icon_color: "#00dddd"})
    this.audio2.play()

    this.audio2.onended = this.doneAudio


  }

  scrollToTop() {
    if (this.refs.scroller) {
      this.refs.scroller.scrollTop = 0;
    }
  }

  changeScenes = scene => {
    //this.scrollToTop()
    jQuery(".select-bird").scrollLeft(0);
    this.props.selectScene(scene);

    this.setState({ currentWord: "", selected: 0 });
  };

  onWordTitle = word => this.setState({ currentWord: word });

  onWordAudio = audioPath => {
    audioPath && this.playAudio(audioPath);
  };

  getScenesData = scenes => selectn("data_set", scenes);

  renderScenesSelector = scenesData => {
    if (!Array.isArray(scenesData) || scenesData.length < 2) {
      return null;
    }

    return scenesData.map(item => {
      //TODO consolidate styles
      if (this.props.selectedScene && this.props.selectedScene == item.id) {
        return (
          <div key={item.title} className="tab-slider--trigger active">
            {item.title}
          </div>
        );
      } else {
        return (
          <div
            key={item.title}
            onClick={() => this.changeScenes(item.id)}
            className="tab-slider--trigger">
            {item.title}
          </div>
        );
      }
    });
  };

//  renderLoader = () => <ProgressBar category="cha" propsFromParent={this.props} />;

  render() {
    const { currentWord } = this.state;
    const scenesData = this.getScenesData(this.props.scenes);


    console.log("KKKKKKKK222")
    console.log(this.props)
    console.log(scenesData)

    /*
    if (!scenesData) {
     // return this.renderLoader();
	return <div>KW Loading</div>
    }
    */
    if (this.props.sceneData){
	console.log("CCCCCCCCCCC")
	    let selectedSceneCommonData;
	    if (this.props.selectedScene && this.props.scenes) {
	      selectedSceneCommonData = scenesData.find(i => i.id === this.props.selectedScene);
	    }

	    /*const {
	      sceneTitleStyle, currentWordInvisible: currentWordInvisibleStyle, currentWord: currentWordStyle,
	      animalHomes: animalHomesStyle
	    } = styles
	*/

	    /*if(this.props.sceneData){*/
	    const playAudio = (audio = this.props.sceneData.data_set[0].data[this.state.selected].audio) =>
	      this.playAudio.bind(null, `/api/get.php?file=${audio}`);
	    return (
	      <div className="animalHomes">
		<h3>{scenesData.title}</h3>
		<div>
		  <section className="birds-section">
		    <div className="birds-box">
		      <div className="container">
			<div className="page-title">
			  <h1 className="uc-first">{this.props.sceneData && this.props.sceneData.title1}</h1>
			</div>
			<div>
			  <div className="tab-slider--nav">
			    <ul className="tab-slider--tabs">{this.renderScenesSelector(scenesData)}</ul>
			  </div>
			</div>
			<KWPreload data={this.props.sceneData.data_set[0].data}>
			  <div>
			    <div className="tab-slider--container">
			      <div id="tab1" className="tab-slider--body">
				<div className="rows">
				  <div className="cols-40">
				    <div className="birds-info">
				      <h4>
					{this.props.sceneData.data_set[0].data[this.state.selected].title1}
				      </h4>
				      <div className="line" />
				      <h3
					className="h3-font-size pointer-on-hover"
					onClick={playAudio(
					  this.props.sceneData.data_set[0].data[this.state.selected].audio
					)}>
					<strong>
					  {
					    this.props.sceneData.data_set[0].data[this.state.selected]
					      .title2
					  }
					</strong>
				      </h3>
				      <p
					className="pointer-on-hover"
					onClick={playAudio(
					  this.props.sceneData.data_set[0].data[this.state.selected].audio
					)}>
					{this.props.sceneData.data_set[0].data[this.state.selected].title3}
				      </p>
				      <i
					className="fas fa-volume-up pointer-on-hover"
					style={{color: this.state.audio_icon_color}}
					onClick={playAudio(
					  this.props.sceneData.data_set[0].data[this.state.selected].audio
					)}
				      />
				    </div>
				  </div>
				  <div className="cols-60">
				    <div className="birds-img">
				      <img
					src={`/api/get.php?file=${
					  this.props.sceneData.data_set[0].data[this.state.selected].image
					}`}
					alt=""
					className="img-fluid pointer-on-hover"
					onClick={playAudio(
					  this.props.sceneData.data_set[0].data[this.state.selected].audio
					)}
				      />
				    </div>
				  </div>
				</div>
			      </div>
			    </div>
			    <div className="all-birds-img">
			      <div className="select-bird">
				{this.props.sceneData.data_set[0].data.map((b, i) => (
				  <div key={i}>
				    <div
				      key={i}
				      className={` s-bird ${this.state.selected === i && "bird-active"} `}
				      onClick={playAudio(b.audio)}
				      onClick={() => this.changeSelected(i)}>
				      <img
					key={i}
					onClick={playAudio(b.audio)}
					src={`/api/get.php?file=${b.image}`}
				      />
				    </div>
				  </div>
				))}
			      </div>
			    </div>
			  </div>
			</KWPreload>
		      </div>
		    </div>
		  </section>
		</div>
	      </div>
	    );
	}
	else {
		return	<div>Loading...</div>
	}
    /*}else{
       return this.renderLoader(); 
  }*/
  }
}

const mapStateToProps = (state, ownProps) => {
  const activity = ownProps.match.params.val;
  const scenes = state.cha.scenes[activity];
  const selectedScene = state.cha.selectedScene[activity];
  const sceneData = selectedScene
    ? selectn(`${activity}.${selectedScene}`, state.cha.scenesData)
    : undefined;
  return {
    scenes,
    selectedScene,
    sceneData
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectScene(scene) {
    dispatch(chaActions.selectScene(ownProps.match.params.val, scene));
  },
  fetchScenes() {
    dispatch(chaActions.fetchScenes(ownProps.match.params.val));
  },
  fetchData(selectedScene) {
    dispatch(chaActions.fetchData(ownProps.match.params.val, selectedScene));
  }
});

NewPage.propTypes = {
  selectScene: PropTypes.func.isRequired,
  fetchScenes: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  scenes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    data_set: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        data_source: PropTypes.string.isRequired
      })
    )
  }),
  selectedScene: PropTypes.string,
  sceneData: PropTypes.object
};

// Use connect to put them together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPage);
